import { DeckCodeParam, getCard, getDeckCode } from '../../shared'
import {
  Card,
  cardEqual,
  CardRef,
  Suit,
  suitEqual,
} from '../../shared/deck/Deck'
import { OhHellPlayerState } from '../state/playerState'
import { Bid } from '../state/round'
import { getLedSuit, PlayedCard, Trick } from '../state/trick'

export interface ExpertPlayer {
  getBid: (state: OhHellPlayerState) => Bid
  getPlayerCard: (state: OhHellPlayerState) => PlayedCard
  computerPlayerId: ExpertIds
}

export enum ExpertIds {
  thirsty = 'thirsty',
  cautious = 'cautious',
  random = 'random',
}

export interface TrickState {
  trumpSuit: Suit | undefined
  trick: Trick
  handCards: Card[]
}

export function ensureIsPlayersTurn(state: OhHellPlayerState) {
  if (state.playerId !== state.currentRound?.turnPlayerId) {
    throw new Error('attempted to take action for non turn player')
  }
}

export function getSuitFollowingCards(
  param: DeckCodeParam,
  trickState: TrickState,
): Card[] | undefined {
  const deckCode = getDeckCode(param)
  const ledSuit = getLedSuit(deckCode, trickState.trick)
  const followingCards = trickState.handCards.filter((card) =>
    suitEqual(card.suit, ledSuit),
  )
  return followingCards.length ? followingCards : undefined
}

function getWinnerOfTwoCards(
  param: DeckCodeParam,
  cardRef1: CardRef,
  cardRef2: CardRef,
  trump: Suit | undefined,
  ledSuit: Suit,
): CardRef {
  const card1 = getCard(param, cardRef1)
  const card2 = getCard(param, cardRef2)
  if (suitEqual(card1.suit, card2.suit)) {
    return getCardOfHigherValue(param, cardRef1, cardRef2)
  }
  if (suitEqual(card1.suit, trump)) {
    return cardRef1
  }
  if (suitEqual(card2.suit, trump)) {
    return cardRef2
  }
  if (suitEqual(card1.suit, ledSuit)) {
    return cardRef1
  }
  if (suitEqual(card2.suit, ledSuit)) {
    return cardRef2
  }
  return cardRef1
}

function getCardOfHigherValue(
  param: DeckCodeParam,
  cardRef1: CardRef,
  cardRef2: CardRef,
): CardRef {
  const card1 = getCard(param, cardRef1)
  const card2 = getCard(param, cardRef2)
  if (!suitEqual(card1.suit, card2.suit)) {
    throw new Error('cards must be same suit')
  }
  return card1.cardValue > card2.cardValue ? cardRef1 : cardRef2
}

function getWinnerCardSoFar(
  param: DeckCodeParam,
  trickState: TrickState,
): PlayedCard | undefined {
  if (!trickState.trick.cards.length) {
    return undefined
  }
  const ledSuit = getLedSuit(param, trickState.trick)
  if (!ledSuit) {
    return undefined
  }
  return trickState.trick.cards.reduce(
    (winningCard: PlayedCard | undefined, nextCard: PlayedCard) => {
      if (!winningCard) {
        return nextCard
      }
      const winnerCardValue: CardRef = getWinnerOfTwoCards(
        param,
        winningCard.card,
        nextCard.card,
        trickState.trumpSuit,
        ledSuit,
      )
      return cardEqual(
        getCard(param, winnerCardValue),
        getCard(param, nextCard.card),
      )
        ? nextCard
        : winningCard
    },
    undefined,
  )
}

export function getBestLeadCard(trickState: TrickState): Card {
  throw new Error('not implemented')
}

export function getLowestLeadCard(trickState: TrickState): Card {
  throw new Error('not implemented')
}
// export function getWinningCards(trickState: TrickState): Card {
//   const winnerCard = getWinnerCardSoFar(trickState)
//   if (mustFollowSuit(trickState)) {
//     const winningCard = trickState.cards.filter(card => card.suit

//     )
//   }
// }

export function getLosingCards(trickState: TrickState): Card {
  throw new Error('not implemented')
}

export function getLeastWinningCard(trickState: TrickState): Card {
  throw new Error('not implemented')
}

export function getLeastLosingCard(trickState: TrickState): Card {
  throw new Error('not implemented')
}

export function getMaxWinningCard(trickState: TrickState): Card {
  throw new Error('not implemented')
}

export function getMaxLosingCard(trickState: TrickState): Card {
  throw new Error('not implemented')
}
