import {
  AnonGameCmd,
  Bid,
  OhHellCmdType,
  OhHellPlayerState,
} from '@ohell/games'
import { FC, MouseEventHandler } from 'react'
import { v4 } from 'uuid'
import {
  ConnectionContext,
  jwtSelector,
  userSelector,
} from '../../services/Connections/connectionState'
import HandCards from './HandCards'
import { getTurnInfo, OhHellGame } from './shared'
import { GameSvc } from '../../services/GameSvc'
import TrumpCard from './TrumpCard'

const PlayBidButtons: FC<{ game: OhHellGame }> = (props) => {
  const game = props.game
  const user = ConnectionContext.useSelector(userSelector)
  const jwt = ConnectionContext.useSelector(jwtSelector)

  const gameState = game.gameState as OhHellPlayerState

  const { isMyTurn, turnPlayerHandle } = getTurnInfo(gameState, user)

  const getPlayBidHandler =
    (bidValue: number): MouseEventHandler<HTMLButtonElement> =>
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()
      if (isNaN(bidValue)) {
        return
      }
      const cmdData: Bid = {
        playerId: user.userId,
        bidTricks: Number(bidValue),
      }
      const cmd: AnonGameCmd = {
        cmdId: v4(),
        gameId: game.gameId,
        gameRuleSetName: game.gameRuleSetName,
        cmdType: OhHellCmdType.PlaceBid,
        cmdData,
      }

      await GameSvc.sendCmd(cmd, { jwt })
    }

  // zero to cards in round
  const zeroToCardsInRound: number[] = Array.from(
    Array(gameState.currentRound.numberOfCards + 1).keys(),
  )

  // add bid to rosters
  // if not player's turn, waiting for xyz
  // say place bid in an amusing way
  // brief rule explainer
  // if placing bid, type number of your bid into the box, then click Place Bid button
  return (
    <div className="flex-col">
      <TrumpCard game={game} />
      {(!isMyTurn && (
        <div className="justify-center text-center font-bold">
          waiting for {turnPlayerHandle}'s bid
        </div>
      )) || (
        <div className="flex justify-center">
          <div className="text-center font-bold">What's your bid?</div>
          <div className="flex justify-center flex-wrap">
            {zeroToCardsInRound.map((bidValue) => (
              <div key={`playBidButton-${bidValue}`} className="flex">
                <button
                  className="p-3 m-3 text-larger border-black border-2 text-center"
                  type="submit"
                  onClick={getPlayBidHandler(bidValue)}
                >
                  <span className="text-larger font-bold">{bidValue}</span> -{' '}
                  {10 + bidValue * bidValue} points
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
      <div>your hand</div>
      <div className="flex-grow">
        <HandCards game={game}></HandCards>
      </div>
    </div>
  )
}

export default PlayBidButtons
