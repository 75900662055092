import { v4 } from 'uuid'
import {
  Game,
  GameCmd,
  GameCmdHandler,
  GameCmdResult,
  GameEvent,
} from '../base/Game'
import { GameStatus } from '../base/GameState'

export enum WinGameCmdTypes {
  Win = 'WIN',
}

export interface WinThisGameState {
  score: { playerId: string; score: number }[]
}

const winGame: GameCmdHandler = (game: Game, cmd: GameCmd) => {
  const gameId = cmd.gameId
  const winnerUserId = cmd.player.userId
  if (game.status !== GameStatus.InProgress) {
    throw new Error('Game can only be won if in progress.')
  }
  const gameState: WinThisGameState = {
    score: game.players.map((player) => ({
      playerId: player.userId,
      score: player.userId === winnerUserId ? 1 : 0,
    })),
  }
  const winEvent: GameEvent = {
    eventId: v4(),
    gameId,
    gameRuleSetName: game.gameRuleSetName,
    eventType: WinGameCmdTypes.Win,
    eventData: {},
  }
  const resultGame: Game = { ...game, gameState, status: GameStatus.Complete }
  const result: GameCmdResult = {
    game: resultGame,
    events: [winEvent],
  }
  return result
}
export const winGameHandlers = { winGame }
