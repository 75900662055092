import { ToastOptions, Zoom, toast } from 'react-toastify'

export enum LogLevel {
  DEBUG = 0,
  INFO = 1,
  WARN = 2,
  ERROR = 3,
}

export const getLogLevel = (level: string): LogLevel => {
  switch (level.toUpperCase()) {
    case 'DEBUG':
      return LogLevel.DEBUG
    case 'INFO':
      return LogLevel.INFO
    case 'WARN':
      return LogLevel.WARN
    case 'ERROR':
      return LogLevel.ERROR
    default:
      return LogLevel.INFO
  }
}

export class Logger {
  public static level: LogLevel = LogLevel.INFO

  static debug(message: string): void {
    Logger.level == LogLevel.DEBUG && console.debug(message)
  }

  static info(message: string): void {
    Logger.level <= LogLevel.INFO && console.info(message)
  }

  static warn(message: string): void {
    Logger.level <= LogLevel.ERROR && console.warn(message)
  }

  static error(message: string): void {
    console.error(message)
  }
}

export const errorToastConfig: ToastOptions = {
  toastId: 'error-toast',
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: 'colored',
  transition: Zoom,
}

export const writeError = (message: string) => {
  toast(message, errorToastConfig)
}
