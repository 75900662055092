import React, { FC } from 'react'
import { ConnectionContext } from '../services/Connections/connectionState'
import { NoProps } from '../shared/types'

const AuthSpinner: FC<NoProps> = () => {
  const cnState = ConnectionContext.useSelector((state) => state)

  return (
    <div className="font-mono text-xs m-2.5 px-5 w-200">
      booting up auth, state is {JSON.stringify(cnState.value)}
    </div>
  )
}

export default AuthSpinner
