import { Logger } from './Logger'

export function getRandomArrayElement<T>(items: T[]): T {
  return items[Math.floor(Math.random() * items.length)]
}

export function getRandomFourDigit() {
  return Math.floor(1000 + Math.random() * 9000)
}

export function printStorage(keys: string[], source?: string) {
  keys.forEach((key) => {
    Logger.debug(
      `storage at ${source || 'unknown'} for key ${key} has ${JSON.stringify(localStorage.getItem(key))}`,
    )
  })
}

export const delayMs = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
