import { Pronouns } from '@ohell/common'
import { Roles } from '../shared/roles'

export type UserRole = {
  roleName: Roles
  objectNames: string[]
}
export type User = {
  userId: string
  handle: string
  email: string | undefined
  pronouns: Pronouns
  roles: UserRole[]
}

export function getRoleNames(user: User): Roles[] {
  return user.roles.map((role) => role.roleName)
}
export function userHasRole(user: User, roleName: Roles): boolean {
  return user.roles.some((role) => role.roleName === roleName)
}
