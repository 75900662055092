import {
  AnonGameCmd,
  Card,
  CardRef,
  choosePlayableCards,
  CompleteRound,
  getCard,
  getCardRef,
  OhHellCmdType,
  PlayedCard,
  PlayerRound,
} from '@ohell/games'
import { FC } from 'react'
import { v4 } from 'uuid'
import {
  ConnectionContext,
  jwtSelector,
  userSelector,
} from '../../services/Connections/connectionState'
import { getDeckImage } from '../../services/DeckImages'
import { OhHellGame } from './shared'
import { GameSvc } from '../../services/GameSvc'

const getCardCaption = (
  round: PlayerRound | CompleteRound,
  cardRef: CardRef,
): string => {
  const card = getCard(round, cardRef)
  if (round.trumpSuit?.suitName === card.suit.suitName) {
    return `${card.suit.suitEmoji}${card.cardValue} - ${card.cardValueName} ${card.suit.suitEmoji}`
  }
  return `${card.suit.suitEmoji}${card.cardValueName || card.cardValue} of ${card.suit.suitName}${card.suit.suitEmoji}`
}

const HandCards: FC<{ game: OhHellGame }> = (props) => {
  const user = ConnectionContext.useSelector(userSelector)
  const jwt = ConnectionContext.useSelector(jwtSelector)
  const game = props.game
  const round = game.gameState.currentRound
  const currentUserId = user.userId
  const currentUserTurn = currentUserId === round.turnPlayerId
  const cards = round?.hand?.cards || []
  const playableCards =
    currentUserTurn && round?.hand && round.currentTrick
      ? choosePlayableCards(round, round.hand)
      : []

  const getPlayCardHandler = (card: Card) => async () => {
    const cmdData: PlayedCard = {
      playerId: currentUserId,
      card: getCardRef(round, card.cardValue, card.suit.suitName),
    }
    const cmd: AnonGameCmd = {
      cmdId: v4(),
      gameId: game.gameId,
      gameRuleSetName: game.gameRuleSetName,
      cmdType: OhHellCmdType.PlayCard,
      cmdData,
    }

    await GameSvc.sendCmd(cmd, { jwt })
  }

  return (
    <div className="flex items-center">
      <div className="flex flex-wrap justify-center items-center">
        {cards.map((cardRef, index) => {
          const card = getCard(round, cardRef)
          const isPlayable = playableCards.includes(cardRef)
          return (
            <div key={`handCard${index}`} className="flex-col mx-3">
              <div
                className="flex justify-center items-center"
                onClick={getPlayCardHandler(card)}
              >
                {getDeckImage(round, cardRef, 75)}
              </div>
              <div
                className={`text-sm ${isPlayable ? 'font-bold' : 'py-0.5'} `}
              >
                {getCardCaption(round, cardRef)}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default HandCards
