import { Game, BaseGameCmdTypes, GameRuleSets, GameSummary } from '@ohell/games'
import { Card } from '../shared/types'
import { v4 } from 'uuid'
import { ApiClient } from './Connections'
import { CreateGameCmd, GameCmd } from '../domain/GameCmd'
import { AppRequestOptions } from './Connections/httpsWrappers'

export type GameList = {
  games: Array<GameSummary>
  lastId: string
  pageSize: number
}

const gameServiceUrl =
  'https://med93mwkuc.execute-api.us-east-1.amazonaws.com/dev/apiv2'
const gameCommandPath = 'games/commands'
const gameCreatePath = 'games'
const gamesPath = 'games'
const randomCardPath = 'games/decks/sw/cards/random'

export const getGameNavigationPath = (game: Game | GameSummary): string => {
  const path = {
    [GameRuleSets.OhHell]: '/ohhell',
    [GameRuleSets.WinThisGame]: '/win',
    [GameRuleSets.Base]: '',
  }[game.gameRuleSetName]
  if (!path) {
    throw new Error('expected a path to be defined for all possible rulesets')
  }
  return path.concat(`?gameId=${game.gameId}`)
}

export const getActiveGames = async (
  options: AppRequestOptions,
): Promise<GameList> => {
  return ApiClient.get<GameList>(gameServiceUrl, `${gamesPath}/ready`, options)
}

export const getOwnGames = async (
  options: AppRequestOptions,
): Promise<GameList> => {
  return ApiClient.get<GameList>(gameServiceUrl, `${gamesPath}/mine`, options)
}

export const getGame = async (
  gameId: string,
  options: AppRequestOptions,
): Promise<Game> => {
  return ApiClient.get<Game>(gameServiceUrl, `${gamesPath}/${gameId}`, options)
}

export const deleteGame = async (
  gameId: string,
  options: AppRequestOptions,
): Promise<void> => {
  return ApiClient.deleteItem(gameServiceUrl, `${gamesPath}/${gameId}`, options)
}

export const getRandomCard = async (
  options: AppRequestOptions,
): Promise<Card> => {
  return ApiClient.get<Card>(gameServiceUrl, randomCardPath, options)
}

export const getCardImage = async (
  url: string,
  options: AppRequestOptions,
): Promise<Blob> => {
  return ApiClient.getImage(url, options)
}

export const sendCmd = async (
  cmd: GameCmd,
  options: AppRequestOptions,
): Promise<Game> => {
  const apiResponse = await ApiClient.post<Game>(
    gameServiceUrl,
    gameCommandPath,
    cmd,
    options,
  )
  if (!apiResponse) {
    throw new Error('could not send game command')
  }
  return apiResponse
}

export const host = async (
  gameOptions: { gameRuleSetName: GameRuleSets },
  options: AppRequestOptions,
): Promise<Game> => {
  const event: CreateGameCmd = {
    cmdId: v4(),
    gameRuleSetName: gameOptions.gameRuleSetName,
    cmdType: BaseGameCmdTypes.Create,
    cmdData: {},
  }
  const apiResponse = await ApiClient.post<Game>(
    gameServiceUrl,
    gameCreatePath,
    event,
    options,
  )
  if (!apiResponse) {
    throw new Error('could not host game')
  }
  return apiResponse
}

export const GameSvc = {
  getGameNavigationPath,
  getActiveGames,
  getOwnGames,
  getGame,
  deleteGame,
  getRandomCard,
  getCardImage,
  sendCmd,
  host,
}
