import { User } from '../../domain/User'

export const jwtExpiryMs = 5 * 60 * 1000

export const parseJwt = (
  jwt: string,
): { user: User; jwtExpirationDate: Date } => {
  const [, rawBody] = jwt.split('.')
  const body = JSON.parse(Buffer.from(rawBody, 'base64').toString())
  const user = body as User
  const { exp } = body as { exp: number }
  const jwtExpirationDate = new Date(exp * 1000)
  return { user, jwtExpirationDate }
}
