import { getNextPlayerId } from '../../base/GamePlayer'
import {
  shuffle,
  takeTopCardRef,
  getTrumpSuit,
  CmdResultTypes,
  getDeck,
  getCard,
} from '../../shared'
import { pickRandomElement } from '../../shared/util'
import { Deal, isReadyForCommand, Round, TrumpSelection } from '../state/round'
import {
  OhHellState,
  OhHellResult,
  isInProgress,
  OhHellCmdType,
  OhHellRulesMessage,
  dealtEvent,
  ohFail,
} from '../state/state'
import { Hand } from '../state/trick'

export function dealCards(state: OhHellState, deal: Deal): OhHellResult {
  if (
    !isInProgress(state) ||
    !isReadyForCommand(state.currentRound, OhHellCmdType.DealCards)
  ) {
    return ohFail(OhHellRulesMessage.NotDealPhase)
  }
  const round = {
    ...state.currentRound,
    ...preselectTrump(state.currentRound),
  }
  if (round.dealerPlayerId !== deal.playerId) {
    return ohFail(OhHellRulesMessage.NotPlayersTurn)
  }
  const dealerIndex = round.players.findIndex(
    (player) => player.userId === round.dealerPlayerId,
  )
  if (dealerIndex === -1) {
    throw new Error('Requested dealer is not a player.')
  }

  const deck = shuffle(round.deckCode)
  const hands: Hand[] = round.players.map((player) => ({
    playerId: player.userId,
    cards: [],
  }))

  const cardsToDeal = hands.length * round.numberOfCards
  if (getDeck(round).cards.length < cardsToDeal) {
    throw new Error('Do not have enough cards in deck to deal to game!')
  }
  for (let i = 0; i < cardsToDeal; i++) {
    const dealToHandIndex = (dealerIndex + i + 1) % hands.length
    const card = takeTopCardRef(deck) // mutate deck.cards
    hands[dealToHandIndex].cards.push(card)
  }

  const turnPlayerId = getNextPlayerId(round.dealerPlayerId, round.players)

  const postDealRound = postSelectTrump({
    ...round,
    deck,
    hands,
    turnPlayerId,
    readyForCommands: [OhHellCmdType.PlaceBid],
  })
  const postDealState = {
    ...state,
    currentRound: postDealRound,
  }
  return {
    result: CmdResultTypes.Success,
    state: postDealState,
    events: [dealtEvent(postDealState)],
  }
}

function preselectTrump(round: Round): TrumpSelection {
  const deck = getDeck(round)
  if (deck.trumpSuitName) {
    return {
      deckCode: round.deckCode,
      trumpSuit: getTrumpSuit(deck),
      trumpCard: undefined,
    }
  }
  if (round.numberOfCards * round.players.length >= deck.cards.length) {
    return {
      deckCode: round.deckCode,
      trumpSuit: pickRandomElement(deck.suits),
      trumpCard: undefined,
    }
  }
  return {
    deckCode: round.deckCode,
    trumpSuit: undefined,
    trumpCard: undefined,
  }
}

function postSelectTrump(round: Round): Round {
  if (round.trumpSuit) {
    return round
  }
  const deck = [...round.deck]
  if (round.deck.length === 0) {
    throw new Error('tried to get trump from empty deck')
  }
  const trumpCard = takeTopCardRef(deck) // mutate deck
  const trumpSuit = getCard(round, trumpCard).suit
  return {
    ...round,
    deck,
    trumpCard,
    trumpSuit,
  }
}
