import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { readAuthClient } from '../services/Connections/authStorage'
import { ConnectionContext } from '../services/Connections/connectionState'
import { getRandomArrayElement } from '../shared/util'
import { Logger } from '../shared/Logger'
import { NoProps } from '../shared/types'

const goodbyeText = getRandomArrayElement([
  'next time, friend',
  "it's been fun",
  'cheers',
  'goodbye, friend',
])

const loginMatcher = { auth: 'logging_in' }
const logoutMatcher = { auth: 'logging_out' }

const Logout: FC<NoProps> = () => {
  const navigate = useNavigate()
  const connectionState = ConnectionContext.useSelector((state) => state)
  const connection = ConnectionContext.useActorRef()
  useEffect(() => {
    const authClient = readAuthClient()
    setTimeout(() => {
      const isLoggingOut = connectionState.matches(logoutMatcher)
      const isLoggingIn = connectionState.matches(loginMatcher)

      if (!isLoggingOut && !isLoggingIn) {
        Logger.debug(
          `Logout: state is ${JSON.stringify(connectionState.value)} with authClient ${JSON.stringify(authClient)}`,
        )
        connection.send({ type: 'LOGOUT' })
      }
    }, 200)
    setTimeout(() => {
      navigate('/Login')
    }, 3000)
  })
  return (
    <div className="text-center">
      <h1>{goodbyeText}</h1>
    </div>
  )
}

export default Logout
