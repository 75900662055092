import {
  AnonGameCmd,
  CompletedTrick,
  CompleteRound,
  Game,
  GamePlayer,
  OhHellCmdType,
  OhHellPlayerState,
  PlayerRound,
  Trick,
} from '@ohell/games'
import { v4 } from 'uuid'
import { User } from '../../domain/User'

export interface OhHellGame extends Game {
  gameState: OhHellPlayerState
}

export type PlayerTurnInfo = {
  turnPlayerId: string
  isMyTurn: boolean
  turnPlayerHandle: string
}

// const  { turnPlayerId, isMyTurn, turnPlayerHandle } = getTurnInfo(gameState, user)
export const getTurnInfo = (
  gameState: OhHellPlayerState,
  user: User,
): PlayerTurnInfo => {
  const turnPlayerId = gameState.currentRound.turnPlayerId
  const isMyTurn = turnPlayerId === user.userId
  const turnPlayerHandle =
    gameState.players.find((player) => player.userId === turnPlayerId)
      ?.handle || ''
  return { turnPlayerId, isMyTurn, turnPlayerHandle }
}

export function getRandomArrayElement<T>(items: T[]): T {
  return items[Math.floor(Math.random() * items.length)]
}

export const getDefaultCmd = (
  game: OhHellGame,
  cmdType: string,
  cmdData: unknown,
): AnonGameCmd => ({
  cmdId: v4(),
  gameId: game.gameId,
  gameRuleSetName: game.gameRuleSetName,
  cmdType,
  cmdData,
})

export const getNextCommand = (
  currentRound: PlayerRound,
): OhHellCmdType | undefined =>
  [
    OhHellCmdType.DealCards,
    OhHellCmdType.PlaceBid,
    OhHellCmdType.PlayCard,
  ].find((item) => currentRound.readyForCommands.includes(item))

export const getComputerPlayerRequest = (
  game: OhHellGame,
): AnonGameCmd | undefined => {
  const currentRound = game?.gameState?.currentRound
  const turnPlayerId = currentRound?.turnPlayerId
  const computerTurnPlayer: GamePlayer | undefined = currentRound?.players.find(
    (player) => player.userId === turnPlayerId && player.computerPlayerId,
  )
  const cmdType = getNextCommand(currentRound)
  if (!computerTurnPlayer || !cmdType) {
    return undefined
  }
  const cmd: AnonGameCmd = getDefaultCmd(game, cmdType, undefined)
  cmd.onBehalfOfPlayerId = computerTurnPlayer.userId
  return cmd
}

export const getNumberOrDash = (value: number | undefined): string => {
  if (value === 0) {
    return String(value)
  }
  return value ? String(value) : '-'
}

export const getTrickWinnerHandle = (
  trick: Trick | CompletedTrick,
  round: PlayerRound | CompleteRound,
): string => {
  if (!(trick as CompletedTrick).winnerId) {
    return ''
  }
  const completed = trick as CompletedTrick
  return (
    round.players.find((player) => player.userId === completed.winnerId)
      ?.handle || ''
  )
}

export const getLastTrickWithPlayedCards = (
  round: PlayerRound | CompleteRound,
): { isTrickComplete: boolean; trick: CompletedTrick | Trick } => {
  const isTrickComplete: boolean =
    round.completedTricks?.length > 0 && !round.currentTrick?.cards.length
  const trick = isTrickComplete
    ? round.completedTricks[round.completedTricks.length - 1]
    : round.currentTrick || { cards: [] }
  return { isTrickComplete, trick }
}
