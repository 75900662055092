import React, { FC, FormEvent, useRef, useState } from 'react'
import {
  ConnectionContext,
  userSelector,
} from '../services/Connections/connectionState'
import { updateUserHandle } from '../services/Connections/userAuth'
import { Logger } from '../shared/Logger'
import { NoProps } from '../shared/types'

interface IProfileState {
  userId?: string
  handle?: string
  responseText: string
}

const Profile: FC<NoProps> = () => {
  Logger.debug('profile render')
  const user = ConnectionContext.useSelector(userSelector)

  const initialProfileState: IProfileState = {
    userId: user.userId,
    handle: user.handle,

    responseText: '',
  }
  const [profileState, setProfileState] = useState(initialProfileState)

  const HandleEntryField = useRef<HTMLInputElement>(null)
  const ResponseSpan = useRef<HTMLSpanElement>(null)

  function startResponseFadeout(delay = 2000) {
    if (!ResponseSpan || !ResponseSpan.current) {
      return
    }
    ResponseSpan.current.classList.remove('opacity-0')
    ResponseSpan.current.classList.add('opacity-100')
    setTimeout(() => {
      if (!ResponseSpan || !ResponseSpan.current) {
        return
      }
      ResponseSpan.current.classList.remove('opacity-100')
      ResponseSpan.current.classList.add('opacity-0')
    }, delay)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const userHandle = HandleEntryField?.current?.value

    if (!userHandle) {
      startResponseFadeout()
      setProfileState({
        ...profileState,
        responseText: 'gotta give us a name to work with, friend',
      })
      return
    }

    updateUserHandle(userHandle)
    startResponseFadeout()
    setProfileState({ ...profileState, responseText: 'saved' })
  }

  function getUpdater(stateFieldName: string) {
    return (e: React.ChangeEvent<HTMLInputElement>) =>
      setProfileState({ ...profileState, [stateFieldName]: e.target.value })
  }

  return (
    <div className="container mx-auto">
      <h1>tell me about yourself, {profileState.handle}</h1>
      <div className="mt-10 md:mt-20 md:flex mx-auto">
        <div className="mx-auto md:w-6/12 bg-classicgray-dark m-2 p-2 md:p-6 rounded-md">
          <form className="biz-form" onSubmit={handleSubmit}>
            <ul className="mb-10">
              <li className="px-1">
                <label
                  className="inline-block text-right mr-1 w-20"
                  htmlFor="handleTextField"
                >
                  Handle
                </label>
                <input
                  className="inline-block text-left w-1/5 pl-1"
                  type="text"
                  id="handleTextField"
                  ref={HandleEntryField}
                  value={profileState.handle}
                  onChange={getUpdater('handle')}
                />
              </li>
            </ul>
            <div className="flex-row text-right">
              <span
                ref={ResponseSpan}
                className="inline-block m-2 px-3 transition-all duration-1000 ease-in-out"
              >
                {profileState.responseText}
              </span>
              <button
                className="inline-block m-2 px-3 border-2 border-black"
                type="submit"
                value="save"
              >
                save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Profile
