import { User } from '../../domain/User'

export type MagicLinkRequest = {
  // includes all mandatory User fields to support user creation.
  userId: string
  email: string
  deviceCode: string
  // these next fields get overwritten from DB if user email already verified.
  handle: string
}

export type MagicLinkRedemptionRequest = {
  email: string
  authCode: string
  deviceCode: string
}

export type RawAuthorizationResponse = {
  refreshToken: string
  refreshTokenExpiryDate: string
  accessJwt: string
}

export enum AuthSource {
  guest = 'guest',
  magicLink = 'magicLink',
}

export type AuthorizationResponse = {
  refreshToken: string
  refreshTokenExpiryDate: Date
  accessJwt: string
}

export function buildAuthorizationResponse(
  raw: RawAuthorizationResponse,
): AuthorizationResponse {
  return {
    ...raw,
    refreshTokenExpiryDate: new Date(raw.refreshTokenExpiryDate),
  }
}
export interface AuthClient extends AuthorizationResponse {
  authSource: AuthSource
  deviceCode: string
  jwtExpirationDate: Date
}

export type RefreshTokenRequest = {
  refreshToken: string
  email: string
  deviceCode: string
}

export type GuestAuthRequest = {
  deviceCode: string
  handle: string
}

export type GuestRefreshTokenRequest = {
  refreshToken: string
  userId: string
  deviceCode: string
}

export type MagicLinkState = {
  authCode: string
  keyGroupStatuses: boolean[]
  isPostReady: boolean
  redemptionInFlight: boolean
}

export type VoidCallback = (authClient: AuthClient, user: User) => void
export type NoParamCallback = () => void

export const identityUrl =
  'https://med93mwkuc.execute-api.us-east-1.amazonaws.com/dev/apiv2'
export const guestRandomHandlePath = `identity/guest-auth/handle`
export const guestAuthRequestPath = `identity/guest-auth/request`
export const guestRefreshTokenPath = `identity/guest-auth/refresh`
export const refreshUserTokenPath = `identity/refresh`
export const magicLinkNewUserRequestPath = `identity/magic-link/new-user`
export const magicLinkLoginRequestPath = `identity/magic-link/request`
export const magicLinkRedeemPath = `identity/magic-link/redeem`
export const randomDeviceCodePath = `identity/device-code`
export const identityHealthCheckPath = `identity/healthcheck`
export const healthCheckPath = `healthcheck`
