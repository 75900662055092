import { GameRuleSets, WinGameCmdTypes } from '@ohell/games'
import { GameCmd } from '../../domain/GameCmd'
import { v4 } from 'uuid'

export const buildWinCmd = (options: { gameId: string }): GameCmd => {
  return {
    cmdId: v4(),
    gameId: options.gameId,
    gameRuleSetName: GameRuleSets.WinThisGame,
    cmdType: WinGameCmdTypes.Win,
    cmdData: {},
  }
}
