import {
  getCard,
  PlayerRound,
  CompleteRound,
  CardRef,
  getTrickWinnerId,
} from '@ohell/games'
import { FC } from 'react'
import { getDeckImage } from '../../services/DeckImages'
import { getLastTrickWithPlayedCards } from './shared'

const getCardCaption = (
  round: PlayerRound | CompleteRound,
  cardRef: CardRef,
): string => {
  const card = getCard(round, cardRef)
  if (round.trumpSuit?.suitName === card.suit.suitName) {
    return `${card.suit.suitEmoji}${card.cardValue} - ${card.cardValueName} ${card.suit.suitEmoji}`
  }
  return `${card.suit.suitEmoji}${card.cardValueName || card.cardValue} of ${card.suit.suitName}${card.suit.suitEmoji}`
}

const TrickCards: FC<{ round: PlayerRound | CompleteRound }> = (props) => {
  const round = props.round
  const { trick } = getLastTrickWithPlayedCards(round)
  const cards = trick.cards
  const winnerId = trick?.cards?.length
    ? getTrickWinnerId(round, trick, round.trumpSuit)
    : ''

  return (
    <div className="my-5 flex">
      {cards.map((playedCard, index) => {
        const cardRef = playedCard.card
        const playerHandle =
          round.players.find((player) => player.userId === playedCard.playerId)
            ?.handle || ''
        return (
          <div key={`handCard${index}`} className="flex-col mx-3">
            <div className="flex justify-center items-center">
              {getDeckImage(round, cardRef, 75)}
            </div>
            <div className="text-sm text-center">
              {getCardCaption(round, cardRef)}
            </div>
            <div
              className={`text-sm text-center ${playedCard?.playerId === winnerId ? 'font-bold' : 'py-0.5'} `}
            >
              {playerHandle}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default TrickCards
