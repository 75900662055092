export type Suit = {
  suitName: string
  suitEmoji: string | undefined
}

export function suitEqual(suit1?: Suit, suit2?: Suit): boolean {
  if (!suit1 || !suit2) {
    return false
  }
  return suit1.suitName == suit2.suitName
}

export type Card = {
  smallUrl: string // /rw/sm/UUID.png,
  largeUrl: string // /rw/lg/UUID.png,
  suit: Suit // 'arcana' | 'swords | cups | pentacles | wands
  cardValue: number
  cardValueName: string // {ace two three four ... king }
  cardEmoji: string | undefined
}

export function cardEqual(card1: Card, card2: Card): boolean {
  return (
    suitEqual(card1.suit, card2.suit) && card1.cardValue === card2.cardValue
  )
}

export type Deck = {
  entity: 'Deck'
  deckName: string
  suits: Suit[]
  trumpSuitName: string | undefined
  cards: Card[]
}

const MAX_CARD_REF = 500
export type CardRef = number
export const isCardRef = (value: unknown): value is CardRef => {
  return typeof value === 'number' && value >= 0 && value <= MAX_CARD_REF
}

export type DeckOrder = CardRef[]

// type NonNegativeInteger<T extends number> =
//     number extends T
//         ? never
//         : `${T}` extends `-${string}` | `${string}.${string}`
//             ? never
//             : T;

/**
 * [mutates deck by removing and returning top card]
 * @param  {Card[]} card [group of cards]
 */
export function takeTopCardRef(cards: CardRef[]): CardRef {
  const card = cards.shift()
  if (!isCardRef(card)) {
    throw new Error('tried to get card from empty deck')
  }
  return card
}

export function getTrumpSuit(deck: Deck): Suit | undefined {
  return deck.suits.find((suit) => suit.suitName === deck.trumpSuitName)
}
