import { Card, Deck, Suit } from './Deck'

const baseSuits: Suit[] = [
  { suitName: 'spades', suitEmoji: '♠️' },
  { suitName: 'diamonds', suitEmoji: '♦️' },
  { suitName: 'clubs', suitEmoji: '♣️' },
  { suitName: 'hearts', suitEmoji: '♥️' },
]

const baseCards = [
  {
    cardValue: 2,
    cardValueName: 'two',
  },
  {
    cardValue: 3,
    cardValueName: 'three',
  },
  {
    cardValue: 4,
    cardValueName: 'four',
  },
  {
    cardValue: 5,
    cardValueName: 'five',
  },
  {
    cardValue: 6,
    cardValueName: 'six',
  },
  {
    cardValue: 7,
    cardValueName: 'seven',
  },
  {
    cardValue: 8,
    cardValueName: 'eight',
  },
  {
    cardValue: 9,
    cardValueName: 'nine',
  },
  {
    cardValue: 10,
    cardValueName: 'ten',
  },
  {
    cardValue: 11,
    cardValueName: 'jack',
  },
  {
    cardValue: 12,
    cardValueName: 'queen',
  },
  {
    cardValue: 13,
    cardValueName: 'king',
  },
  {
    cardValue: 14,
    cardValueName: 'ace',
  },
]

const cards: Card[] = baseSuits.reduce((cards: Card[], suit: Suit) => {
  const suitCards: Card[] = baseCards.map((card) => ({
    ...card,
    suit,
    smallUrl: `/std/${suit.suitName}_${
      card.cardValue <= 10 ? card.cardValue.toString() : card.cardValueName
    }.svg`,
    largeUrl: '',
  })) as Card[]
  return cards.concat(suitCards)
}, [])

export const AcesHigh: Deck = {
  deckName: 'Standard Deck',
  entity: 'Deck',
  suits: baseSuits,
  trumpSuitName: undefined,
  cards,
}
