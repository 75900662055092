import React, { Component, ErrorInfo, ReactNode } from 'react'
import { Logger } from '../shared/Logger'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
  error: Error | undefined
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hasError: false,
      error: undefined,
    }
  }

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    Logger.error(
      `oh my.  getting derived state after: ${JSON.stringify(error)}`,
    )
    return { hasError: true, error }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Logger.error(
      `oh my.  We caught: ${JSON.stringify(error)}, ${JSON.stringify(errorInfo)}`,
    )
    this.setState({ hasError: true, error })
  }

  public render() {
    if (this.state.hasError) {
      return <h1>Error. ${this.state.error?.message}. Try a page refresh?</h1>
    }
    return this.props.children
  }
}

export default ErrorBoundary
