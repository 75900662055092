import React, { FC, useCallback, useEffect, useState } from 'react'
import { Card, NoProps } from '../shared/types'
import { GameSvc } from '../services/GameSvc'
import { noauthOption } from '../services/Connections/httpsWrappers'
import { Logger, writeError } from '../shared/Logger'

type NowhereState = {
  card: Card | undefined
  cardImage: Blob | undefined
  cardImageLocation: string | undefined
  lastCardRequest: Date | undefined
  error?: Error
}

const Nothing: FC<NoProps> = () => {
  const [state, setState] = useState<NowhereState>({} as NowhereState)
  console.info(`loading nowhere with state ${JSON.stringify(state)}`)
  const getCardState = useCallback(async () => {
    try {
      if (
        !state.lastCardRequest ||
        Date.now() - state.lastCardRequest.getTime() < 1000
      ) {
        const lastCardRequest: Date = new Date()
        const card = await GameSvc.getRandomCard(noauthOption)
        const cardImage = await GameSvc.getCardImage(
          card.smallUrl,
          noauthOption,
        )
        const cardImageLocation = URL.createObjectURL(cardImage)
        setState({ card, cardImage, cardImageLocation, lastCardRequest })
      }
    } catch (err) {
      Logger.debug(
        `got an error when i tried to get a card: ${JSON.stringify(err)}`,
      )
    }
  }, [state])
  useEffect(() => {
    getCardState()
  }, [])

  function getCardDescription(card: Card) {
    if (!card.suit.suitName) {
      return ''
    }
    return card.suit.suitName === 'trumps'
      ? card.cardValueName
      : `${card.cardValueName} of ${card.suit.suitName}`
  }

  if (state.error) {
    writeError(state.error.message)
    setState({ ...state, error: undefined })
  }

  return (
    <div className="text-center">
      <button
        onClick={() => setState({ ...state, error: new Error('nowhere') })}
      >
        <h1>nada</h1>
      </button>
      <p>this is the nothing component route. you are nowhere.</p>
      <div>
        {state.card && state.cardImageLocation ? (
          <img
            className="mx-auto"
            src={state.cardImageLocation}
            alt={getCardDescription(state.card)}
            crossOrigin="anonymous"
          />
        ) : null}
      </div>
      <div>
        {state.card ? <span>{getCardDescription(state.card)}</span> : null}
      </div>
    </div>
  )
}

export default Nothing
