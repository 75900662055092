import React, { FC } from 'react'
import {
  ConnectionContext,
  userSelector,
} from '../services/Connections/connectionState'
import ChatBox from '../widgets/ChatBox'
import GamesTracker from '../widgets/GamesTracker'
import { Logger } from '../shared/Logger'
import { NoProps } from '../shared/types'
import { globalChatRooms } from '../services/Chat'
// import GamesTracker from '../widgets/GamesTracker'

const Lobby: FC<NoProps> = () => {
  Logger.debug('lobby render')
  const user = ConnectionContext.useSelector(userSelector)

  return (
    <div className="container mx-auto">
      <h1>welcome to the lobby, {user.handle}</h1>
      <div className="md:flex">
        <ChatBox
          chatRoomId={globalChatRooms.front.chatRoomId}
          chatroomName={globalChatRooms.front.chatroomName}
        ></ChatBox>
        <GamesTracker />
      </div>
    </div>
  )
}

export default Lobby
