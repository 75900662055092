import React, {
  FC,
  useState,
  useEffect,
  MouseEventHandler,
  useCallback,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { GameSvc } from '../services/GameSvc'
import { GameStatus, GameSummary } from '@ohell/games'
import { getScrollBarWidthPadding } from '../styles/browserSelectors'
import { buildJoinCmd } from '../services/GameCmdFactory/BaseGameCmdFactory'
import {
  ConnectionContext,
  jwtSelector,
  userSelector,
} from '../services/Connections/connectionState'
import { Logger } from '../shared/Logger'
import { NoProps } from '../shared/types'
import { getTrackerRow } from './GamesTracker/getTrackerRow'
import { getInPlayRow } from './GamesTracker/getInPlayRow'

// component to host game, cancel, search, or join

type TrackerState = {
  myGames: Array<GameSummary>
  readyGames: Array<GameSummary>
  readyGamesLastId: string
  pageSize: number
  fetchState: 'INIT' | 'FETCHING' | 'FETCHED'
}

const defaultState: TrackerState = {
  myGames: [],
  readyGames: [],
  readyGamesLastId: '#',
  pageSize: 0,
  fetchState: 'INIT',
}

const GamesTracker: FC<NoProps> = () => {
  const user = ConnectionContext.useSelector(userSelector)
  const jwt = ConnectionContext.useSelector(jwtSelector)

  const navigate = useNavigate()

  const [state, setState] = useState<TrackerState>(defaultState)

  const refreshActiveGames = useCallback(() => {
    if (state.fetchState === 'FETCHING') {
      return
    }
    setState({ ...state, fetchState: 'FETCHING' })
    const allGames = Promise.all([
      GameSvc.getActiveGames({ jwt }),
      GameSvc.getOwnGames({ jwt }),
    ])
    allGames
      .then((result) => {
        const myGames = result[1]?.games || []
        const ownGameIdSet = new Set(myGames.map((game) => game.gameId))
        const readyGames = (result[0]?.games || []).filter(
          (game) => !ownGameIdSet.has(game.gameId),
        )
        const readyGamesLastId = result[0].lastId
        const pageSize = result[0].pageSize
        setState({
          myGames,
          readyGames,
          readyGamesLastId,
          pageSize,
          fetchState: 'FETCHED',
        })
      })
      .catch((reason) => {
        Logger.debug(
          `error on GamesTracker init: ${reason.message || JSON.stringify(reason)}`,
        )
        setState({ ...state, fetchState: 'FETCHED' })
      })
  }, [jwt])

  useEffect(() => {
    if (state.fetchState === 'INIT') {
      refreshActiveGames()
    }
  }, [state, setState, refreshActiveGames])

  const handleHostGame: MouseEventHandler<HTMLButtonElement> = async (
    e: React.MouseEvent<HTMLElement>,
  ) => {
    e.preventDefault()
    navigate(`/host/`)
  }

  const handleRefreshTracker: MouseEventHandler<HTMLButtonElement> = async (
    e: React.MouseEvent<HTMLElement>,
  ) => {
    e.preventDefault()
    refreshActiveGames()
  }

  const handleJoinGame = (game: GameSummary) => () => {
    if (!game) {
      console.error('expected a game in handleJoinGame')
      return
    }
    const path = GameSvc.getGameNavigationPath(game)
    if (game.status === GameStatus.InProgress) {
      navigate(path)
      return
    }
    const cmd = buildJoinCmd(game)
    GameSvc.sendCmd(cmd, { jwt }).then(() => {
      Logger.debug('joined')
      navigate(path)
    })
  }

  const handleDeleteGame = (game: GameSummary) => () => {
    const gameId = game?.gameId
    if (!gameId) {
      console.error('expected a gameId in handleDeleteGame')
      return
    }
    GameSvc.deleteGame(gameId, { jwt }).then(() => {
      setState({
        ...state,
        myGames: state.myGames.filter((game) => game.gameId !== gameId) || [],
        readyGames:
          state.readyGames.filter((game) => game.gameId !== gameId) || [],
      })
      Logger.debug('deleted')
    })
  }

  return (
    <div className="bg-classicgray-dark m-3 p-2 md:p-6 w-full md:w-6/12 rounded-md flex-col content-between">
      <section className="section">
        <h1>games tracker</h1>
        <button
          className="m-2 px-3 border border-black"
          type="submit"
          value="host a game"
          onClick={handleHostGame}
        >
          host game
        </button>
        <button
          className="m-2 px-3 border border-black"
          type="submit"
          value="refresh"
          onClick={handleRefreshTracker}
        >
          refresh
        </button>
      </section>
      <div className="flex flex-col h-90v md:h-60v overflow-y-scroll">
        {!!state.myGames.length && (
          <div
            className={`flex flex-col text-left flex-shrink-0 ${getScrollBarWidthPadding()}`}
          >
            <div className={`flex flex-col `}>
              <h2>in play</h2>
              <div className="flex flex-row w-full text-left flex-shrink-0">
                <div className="w-4/12">created</div>
                <div className="w-4/12">host</div>
                <div className="w-2/12">players/max</div>
                <div className="w-2/12"></div>
              </div>
            </div>
            <div
              className={`flex flex-col w-full text-left flex-shrink-1 scrollbar`}
            >
              {state?.myGames.map((game) =>
                getInPlayRow(
                  game,
                  user,
                  handleJoinGame(game),
                  handleDeleteGame(game),
                ),
              )}
            </div>
          </div>
        )}
        <div className="flex flex-col">
          <h2>ready to join</h2>
          <div
            className={`flex flex-col text-left flex-shrink-0 ${getScrollBarWidthPadding()}`}
          >
            <div className="flex flex-row w-full text-left flex-shrink-0">
              <div className="w-4/12">created</div>
              <div className="w-4/12">host</div>
              <div className="w-2/12">players/max</div>
              <div className="w-2/12"></div>
            </div>
          </div>
          <div
            className={`flex flex-col w-full text-left flex-shrink-1 scrollbar`}
          >
            {state?.readyGames.map((game) =>
              getTrackerRow(
                game,
                user,
                handleJoinGame(game),
                handleDeleteGame(game),
              ),
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GamesTracker
