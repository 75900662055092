export * from './deck/Deck'
export * from './deck/Decks'
export * from './deck/SmithWaite'
export * from './deck/Standard'

export enum GameRuleSets {
  Base = 'Base',
  WinThisGame = 'Win This Game',
  OhHell = 'Oh Hell',
}

export enum CmdResultTypes {
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}

export interface CmdResultEvent {
  eventName: string
  details: unknown
}

export type CmdSuccess = {
  result: CmdResultTypes.Success
  state: unknown
  events: CmdResultEvent[]
}

export type CmdFailure = {
  result: CmdResultTypes.Failure
  message: string
}

export type CmdResult = CmdSuccess | CmdFailure

export function isSuccess(result: CmdResult): result is CmdSuccess {
  return result.result === CmdResultTypes.Success
}
