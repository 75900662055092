import { getNextPlayerId, isActiveGamePlayer } from '../../base/GamePlayer'
import { CmdResultTypes } from '../../shared'
import {
  Bid,
  isReadyForCommand,
  isPlayersTurn,
  Round,
  RoundInProgress,
  getSumOfBids,
} from '../state/round'
import {
  OhHellState,
  OhHellResult,
  isInProgress,
  OhHellRulesMessage,
  OhHellCmdType,
  OhHellEvent,
  bidPlacedEvent,
  biddingCompleteEvent,
  ohSucceed,
  OhHellInProgress,
  OhHellFailure,
  ohFail,
} from '../state/state'
import { initTrick } from '../state/trick'

export function placeBid(state: OhHellState, bid: Bid): OhHellResult {
  if (!isInProgress(state)) {
    return ohFail(OhHellRulesMessage.NotBidPhase)
  }
  const bidFailure: false | OhHellFailure = checkForFailure(state, bid)
  if (bidFailure) {
    return bidFailure
  }

  const currentRound = state.currentRound
  const placedBid = { ...bid, wonTricks: 0 }
  const round: Round = {
    ...currentRound,
    turnPlayerId: getNextPlayerId(bid.playerId, state.currentRound.players),
    bids: [...currentRound.bids, placedBid],
  }
  const bidState: OhHellInProgress = { ...state, currentRound: round }
  const events: OhHellEvent[] = [bidPlacedEvent(bidState)]

  if (!isBiddingComplete(round)) {
    return ohSucceed(bidState, events)
  }

  const bidCompleteState: OhHellInProgress = {
    ...bidState,
    currentRound: {
      ...bidState.currentRound,
      readyForCommands: [OhHellCmdType.PlayCard],
      currentTrick: initTrick(),
    },
  }
  events.push(biddingCompleteEvent({ ...state, currentRound: round }))

  return ohSucceed(bidCompleteState, events)
}

function checkForFailure(
  state: OhHellInProgress,
  bid: Bid,
): false | OhHellFailure {
  const currentRound = state.currentRound
  return (
    ensureIsReadyForCommand(currentRound) ||
    ensureIsPlayersTurn(currentRound, bid) ||
    ensureDealerGetsScrewed(currentRound, bid) ||
    false
  )
}

function ensureDealerGetsScrewed(
  round: Round,
  bid: Bid,
): false | OhHellFailure {
  if (bid.playerId === round.dealerPlayerId) {
    const sumOfBids = getSumOfBids([...round.bids, bid])
    if (sumOfBids === round.numberOfCards) {
      return {
        result: CmdResultTypes.Failure,
        message: OhHellRulesMessage.DealerGetsScrewed,
      }
    }
  }
  return false
}

function ensureIsReadyForCommand(round: Round): false | OhHellFailure {
  return (
    !isReadyForCommand(round, OhHellCmdType.PlaceBid) &&
    ohFail(OhHellRulesMessage.NotBidPhase)
  )
}

function ensureIsPlayersTurn(round: Round, bid: Bid): false | OhHellFailure {
  return (
    !isPlayersTurn(round, bid.playerId) &&
    ohFail(OhHellRulesMessage.NotPlayersTurn)
  )
}

function isBiddingComplete(round: Round): boolean {
  const activePlayers = round.players.filter(isActiveGamePlayer)
  return !!(round.bids.length === activePlayers.length)
}
