import { ApiClient } from '.'
import {
  AuthClient,
  AuthSource,
  buildAuthorizationResponse,
  identityUrl,
  magicLinkLoginRequestPath,
  magicLinkNewUserRequestPath,
  magicLinkRedeemPath,
  MagicLinkState,
  RawAuthorizationResponse,
} from './IdentityTypes'
import { readDeviceCode } from './authStorage'
import { parseJwt } from './jwt'
import { post } from './httpsWrappers'
import { TokenRefreshResult } from './userAuth'
import { Logger } from '../../shared/Logger'

const validChars: string[] = [
  ...Array.from(new Array(26), (x, i) =>
    String.fromCharCode('A'.charCodeAt(0) + (i % 26)),
  ),
  ...Array.from(new Array(26), (x, i) =>
    String.fromCharCode('a'.charCodeAt(0) + (i % 26)),
  ),
  ...Array.from(new Array(10), (x, i) =>
    String.fromCharCode('0'.charCodeAt(0) + (i % 10)),
  ),
].filter((c) => !['o', 'O'].includes(c))

export const getMagicLinkState = (magicLinkEntry: string): MagicLinkState => {
  const rawKeyGroupLength = 5
  const keyGroupLength = rawKeyGroupLength - 1
  const numKeyGroups = 4

  const keyValuesOnly = magicLinkEntry.replace(/[^0-9a-np-zA-OP-Z]/gi, '')
  const rawKeyGroups: string[] = []
  for (
    let i = 0;
    i < rawKeyGroupLength * numKeyGroups;
    i += rawKeyGroupLength
  ) {
    rawKeyGroups.push(keyValuesOnly.slice(i, i + rawKeyGroupLength))
  }
  const keyOnly = rawKeyGroups
    .map((rawKeyGroup) => rawKeyGroup.slice(0, keyGroupLength))
    .join('')
  const keyGroupStatuses = rawKeyGroups.map(
    (rawKeyGroup) =>
      getModulo60CheckCharacter(
        rawKeyGroup.substring(0, rawKeyGroup.length - 1),
      ) === rawKeyGroup.slice(-1),
  )
  Logger.debug(`keyOnly is ${keyOnly}`)
  return {
    authCode: keyOnly,
    keyGroupStatuses,
    isPostReady:
      keyOnly.length === numKeyGroups * keyGroupLength &&
      keyGroupStatuses.every((keyGroupStatus) => !!keyGroupStatus),
    redemptionInFlight: false,
  }
}

export const requestLoginMagicLinkEmail = async (
  email: string | null,
  deviceCode: string | null,
): Promise<void> => {
  if (!deviceCode) {
    throw new Error('can not request auth without deviceCode')
  }
  if (!email) {
    throw new Error('can not request auth without email')
  }
  const request = { email, deviceCode }
  await post(identityUrl, magicLinkLoginRequestPath, request, {
    noAuthorization: true,
  })
}

export const requestNewUserMagicLinkEmail = async (
  email: string | null,
  deviceCode: string | null,
  handle: string | null,
): Promise<void> => {
  if (!deviceCode) {
    throw new Error('can not request auth without deviceCode')
  }
  if (!email) {
    throw new Error('can not request auth without email')
  }
  if (!handle) {
    throw new Error('can not request auth without handle')
  }
  const request = { handle, email, deviceCode }
  await ApiClient.post(identityUrl, magicLinkNewUserRequestPath, request, {
    noAuthorization: true,
  })
}

export const redeemMagicLink = async (
  email: string,
  authCode: string,
): Promise<TokenRefreshResult> => {
  const deviceCode = readDeviceCode()
  if (!deviceCode) {
    throw new Error('can not request auth without deviceCode')
  }
  if (!email) {
    throw new Error('can not request auth without email')
  }
  if (!authCode) {
    throw new Error('can not request auth without authCode')
  }
  const request = {
    email,
    authCode,
    deviceCode,
  }
  const rawResponse = await ApiClient.post<RawAuthorizationResponse>(
    identityUrl,
    magicLinkRedeemPath,
    request,
    { noAuthorization: true },
  )
  const authResponse = buildAuthorizationResponse(rawResponse)
  const { user, jwtExpirationDate } = parseJwt(authResponse.accessJwt)
  const authClient: AuthClient = {
    ...authResponse,
    authSource: AuthSource.magicLink,
    deviceCode,
    jwtExpirationDate,
  }
  return { authClient, user }
  // this.OnLogin(this.authClient, this.user)
}

function getCodeGroupValue(codeGroup: string): number {
  return Array.from(codeGroup)
    .map((char) => validChars.indexOf(char))
    .reduce((sum, next) => {
      return sum + next
    }, 0)
}

function getModulo60CheckCharacter(codeGroup: string): string {
  const moduloValue = getCodeGroupValue(codeGroup) % validChars.length
  return validChars[(validChars.length - moduloValue) % validChars.length]
}
