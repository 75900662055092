import {
  CardRef,
  CompleteRound,
  Deck,
  decks,
  getCard,
  PlayerRound,
} from '@ohell/games'
import { FC } from 'react'
import { getDeckImage } from '../../services/DeckImages'
import { OhHellGame } from './shared'

const getCardCaption = (
  round: PlayerRound | CompleteRound,
  cardRef: CardRef | undefined,
): string => {
  if (!cardRef) {
    const suit = round.trumpSuit
    return suit
      ? `${suit.suitEmoji}${suit.suitName}${suit.suitEmoji}`
      : 'no trump'
  }
  const card = getCard(round, cardRef)
  return `${card.suit.suitEmoji}${card.cardValueName || card.cardValue} of ${card.suit.suitName}${card.suit.suitEmoji}`
}

const deckHasNoDefaultTrump = (deck: Deck): boolean => {
  return !deck.trumpSuitName
}

const TrumpCard: FC<{ game: OhHellGame }> = (props) => {
  const game = props.game
  const round = game.gameState.currentRound
  const trumpRef: number | undefined = round.trumpCard
  const hasTrumpCard = typeof trumpRef === 'number'
  const noDefaultTrump = deckHasNoDefaultTrump(decks[round.deckCode])

  return (
    <div>
      {((hasTrumpCard || noDefaultTrump) && (
        <div className="flex items-center">
          <div className="flex-col mx-3">
            <div className={`font-bold}`}>trump</div>
            {hasTrumpCard && (
              <div className="flex justify-center items-center">
                {getDeckImage(round, trumpRef, 75)}
              </div>
            )}
            <div className={`text-sm font-bold}`}>
              {getCardCaption(round, trumpRef)}
            </div>
          </div>
        </div>
      )) || <div></div>}
    </div>
  )
}

export default TrumpCard
