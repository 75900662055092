import { FC, MouseEventHandler } from 'react'

const Help: FC<{ doToggleHelp: MouseEventHandler }> = ({ doToggleHelp }) => {
  return (
    <div className="bg-classicgray-dark p-2 pb-1 m-1 md:p-6 w-full md:w-10/12 rounded-md flex flex-col justify-center items-center">
      <div className="w-6/12 h-70v md:h-70v p-2 m-1 text-left overflow-y-auto md:overflow-y-scroll scrollbar">
        <h3>The game is Oh Hell.</h3>
        <p>
          If this looks too complicated, just dive in. There's on-screen hints,
          and you can get back here by clicking HELP. Cards you can play are{' '}
          <strong>bolded.</strong>
        </p>
        <p>
          Oh Hell is a trick taking game, about halfway between bridge and
          spades. A trick is where each player plays one card on their turn and
          the winner takes the trick.
        </p>
        <h3>The game is played in a series of rounds.</h3>
        <p>
          The dealer rotates with each round. They deal out the cards at the
          start of each Round, in order counter clockwise. Round one, everyone
          has one card and there's one trick. Round two, everyone has two cards
          and there's two tricks. You keep playing until you can't deal out
          another card or everyone wanders off.
        </p>
        <h3>Get points by taking exactly the number of tricks you bid</h3>
        <p>
          The goal is to get the most points by bidding on how many tricks you
          think you can win at the start of the round, and winning exactly that
          number. If you go over or under, you lose points. The rules guarantee
          that someone loses points every round. You can definitely end up with
          less than zero points, it happens all the time. Not typically to the
          winner.
        </p>
        <p>
          Each trick starts with someone leading a card. For the first trick of
          a round, this is the person after the dealer. The winner of a trick
          leads the next trick, and everyone has to follow them. Winning a trick
          means you set the pace for the next one.
        </p>
        <h3>You have to follow suit.</h3>
        <p>
          In a trick, you must follow suit if you are able! You must play a card
          that matches the suit that was led. If you don't have any of that suit
          (if you "void" in that suit) you can play whatever you want.
        </p>
        <p>
          The highest card of the led suit wins the trick, aces high. Playing
          off suit means you are intentionally getting rid of a card you don't
          want to win with. UNLESS someone plays a trump. If someone plays a
          trump during a trick, highest trump wins. Some decks (Tarot) have a
          designated trump suit. With others, you cut to determine trump before
          bidding.
        </p>
        <p>
          You can't lead with a trump until someone else is void in the led suit
          and "breaks trump" by playing a trump card.
        </p>
        <h3>Bidding happens at start of every round</h3>
        <p>
          Bidding follows the play order; player counter clockwise of the dealer
          bids first. Dealer bids last. The sum of the bids can not be the same
          as the number of cards in the round. This may prevent the dealer from
          bidding what they want, and ensures someone always loses points every
          round.
        </p>
        <h3>Bid more score more, unless you miss</h3>
        <p>
          The score is updated at the end of each round. If you get exactly what
          you bid you get points. If you're off by some cards, you lose points.
          If you are over or under your bid, you lose 5 points if off by one, 15
          points if off by two, 30 points if off by three etc. If you make your
          bid, you get ten points plus the number bid squared: 10 for a bid of
          zero, 11 for a bid of one, 14 for a bid of two, 19 for a bid of three,
          26 for a bid of four etc. The scoring is complicated because... we
          were high and mathematics is the language of the universe.
        </p>
      </div>
      <div className="w-6/12 p-2 flex justify-center">
        <button className="p-1 m-1 text-xs" onClick={doToggleHelp}>
          exit
        </button>
      </div>
    </div>
  )
}

export default Help
