import { v4 } from 'uuid'
import { Game, GameCmd } from './base/Game'
import { GamePlayer } from './base/GamePlayer'
import { bidAveragePlayAggro } from './ohHell/expert/bidAggroPlayAggro'
import { bidAveragePlayLeastWinningCard } from './ohHell/expert/bidAggroPlayLeastWinningCard'
import { bidAveragePlayRandom } from './ohHell/expert/bidAveragePlayRandom'
import { ExpertIds, ExpertPlayer } from './ohHell/expert/shared'
import { OhHellCmdType } from './ohHell/OhHell'
import { OhHellPlayerState } from './ohHell/state/playerState'
import { Deal } from './ohHell/state/round'
import { GameRuleSets } from './shared'

const getOhHellDealCards = (state: OhHellPlayerState): Deal => ({
  playerId: state.currentRound.dealerPlayerId,
})

type CommandString = string
type ExpertPlayerId = string
type UnknownStateHandler = (gameState: unknown) => unknown
type CommandRequestRouter = Record<
  CommandString,
  UnknownStateHandler | undefined
>
type ExpertRouter = Record<ExpertPlayerId, CommandRequestRouter>

const gameExpertCommandRequestRouter: Record<
  GameRuleSets,
  ExpertRouter | undefined
> = {
  [GameRuleSets.Base]: undefined,
  [GameRuleSets.WinThisGame]: undefined,
  [GameRuleSets.OhHell]: {
    [ExpertIds.thirsty]: {
      [OhHellCmdType.DealCards]: getOhHellDealCards as UnknownStateHandler,
      [OhHellCmdType.PlaceBid]:
        bidAveragePlayRandom.getBid as UnknownStateHandler,
      [OhHellCmdType.PlayCard]:
        bidAveragePlayRandom.getPlayerCard as UnknownStateHandler,
      [OhHellCmdType.Start]: undefined,
      [OhHellCmdType.GetPlayerState]: undefined,
    },
    [ExpertIds.cautious]: {
      [OhHellCmdType.DealCards]: getOhHellDealCards as UnknownStateHandler,
      [OhHellCmdType.PlaceBid]:
        bidAveragePlayLeastWinningCard.getBid as UnknownStateHandler,
      [OhHellCmdType.PlayCard]:
        bidAveragePlayLeastWinningCard.getPlayerCard as UnknownStateHandler,
      [OhHellCmdType.Start]: undefined,
      [OhHellCmdType.GetPlayerState]: undefined,
    },
    [ExpertIds.random]: {
      [OhHellCmdType.DealCards]: getOhHellDealCards as UnknownStateHandler,
      [OhHellCmdType.PlaceBid]:
        bidAveragePlayAggro.getBid as UnknownStateHandler,
      [OhHellCmdType.PlayCard]:
        bidAveragePlayAggro.getPlayerCard as UnknownStateHandler,
      [OhHellCmdType.Start]: undefined,
      [OhHellCmdType.GetPlayerState]: undefined,
    },
  },
}

export interface CmdRequest {
  computerPlayerId: ExpertPlayerId
  commandName: CommandString
  player: GamePlayer
}

export const requestExpertCommand = (
  game: Game,
  commandRequest: CmdRequest,
): GameCmd | undefined => {
  const gameRuleSetName: GameRuleSets = game.gameRuleSetName
  const expertPlayerId: ExpertPlayerId = commandRequest.computerPlayerId
  const { commandName } = commandRequest

  const expertRouter = gameExpertCommandRequestRouter[gameRuleSetName]
  const expertRequestRouter = expertRouter && expertRouter[expertPlayerId]
  const requestHandler = expertRequestRouter && expertRequestRouter[commandName]
  if (!requestHandler) {
    return undefined
  }
  const cmdData = requestHandler(game.gameState)
  return {
    player: commandRequest.player,
    cmdId: v4(),
    gameId: game.gameId,
    gameRuleSetName,
    cmdType: commandName,
    cmdData,
  }
}
