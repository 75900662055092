export enum Pronouns {
  They = 'They',
  He = 'He',
  She = 'She',
}

export type PronounText = {
  s: string // 'she', // subject
  o: string // 'her', // object
  p: string // 'her', // possessive
  pp: string // 'hers', // possessive pronoun
  r: string // 'herself' //
}

export const PronounTexts: Record<Pronouns, PronounText> = {
  They: {
    s: 'they',
    o: 'them',
    p: 'their',
    pp: 'theirs',
    r: 'themself',
  },
  He: {
    s: 'he',
    o: 'him',
    p: 'his',
    pp: 'his',
    r: 'himself',
  },
  She: {
    s: 'she',
    o: 'her',
    p: 'her',
    pp: 'hers',
    r: 'herself',
  },
}
