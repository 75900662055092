import { getJwt } from './connectionState'
import {
  AppRequestOptions,
  deleteItem,
  get,
  getImage,
  post,
} from './httpsWrappers'
export interface IApiClient {
  post: <T>(
    domain: string,
    path: string,
    body: object,
    options: AppRequestOptions,
  ) => Promise<T>
  get: <T>(
    domain: string,
    path: string,
    options: AppRequestOptions,
  ) => Promise<T>
  getImage: (url: string, options: AppRequestOptions) => Promise<Blob>
  deleteItem: (
    domain: string,
    path: string,
    options: AppRequestOptions,
  ) => Promise<void>
}

const getJwtOptions = async (
  options?: AppRequestOptions,
): Promise<AppRequestOptions> => {
  if (options?.noAuthorization) {
    return options
  }
  const providedJwt = (options as { jwt: string })?.jwt
  return { ...(options || {}), jwt: providedJwt || (await getJwt()) }
}

export const ApiClient: IApiClient = {
  post: async <T>(
    domain: string,
    path: string,
    body: object,
    options: AppRequestOptions,
  ): Promise<T> => {
    return post(domain, path, body, await getJwtOptions(options))
  },
  get: async <T>(
    domain: string,
    path: string,
    options: AppRequestOptions,
  ): Promise<T> => {
    return get(domain, path, await getJwtOptions(options))
  },
  getImage: async (url: string, options: AppRequestOptions): Promise<Blob> => {
    return getImage(url, await getJwtOptions(options))
  },
  deleteItem: async (
    domain: string,
    path: string,
    options: AppRequestOptions,
  ): Promise<void> => {
    return deleteItem(domain, path, await getJwtOptions(options))
  },
}

// export function useApi(): IApiClient {
//   const jwt = ConnectionContext.useSelector(jwtSelector)

//   return {
//     post: async <T>(domain: string, path: string, body: any, options?: AppRequestOptions): Promise<T> => {
//       return post(domain, path, body, getJwtOptionsFromProvidedJwt(options, jwt))
//     },
//     get: async <T>(domain: string, path: string, options?: AppRequestOptions): Promise<T> => {
//       return get(domain, path, getJwtOptionsFromProvidedJwt(options, jwt))
//     },
//     getImage: async (url: string, options?: AppRequestOptions): Promise<Blob> => {
//       return getImage(url, getJwtOptionsFromProvidedJwt(options, jwt))
//     },
//     deleteItem: async (domain: string, path: string, options?: AppRequestOptions): Promise<void> => {
//       return deleteItem(domain, path, getJwtOptionsFromProvidedJwt(options, jwt))
//     }
//   }
// }
