import {
  AnonGameCmd,
  CompleteRound,
  Game,
  OhHellCmdType,
  OhHellPlayerState,
} from '@ohell/games'
import { FC, MouseEventHandler } from 'react'
import { v4 } from 'uuid'
import {
  ConnectionContext,
  jwtSelector,
  userSelector,
} from '../../services/Connections/connectionState'
import ScoreWidget from './ScoreWidget'
import { getTurnInfo } from './shared'
import TrickCards from './TrickCards'
import { GameSvc } from '../../services/GameSvc'

const PlayDeal: FC<{ game: Game }> = (props) => {
  const game = props.game
  const user = ConnectionContext.useSelector(userSelector)
  const jwt = ConnectionContext.useSelector(jwtSelector)

  const gameState = game.gameState as OhHellPlayerState

  const handleDealCards: MouseEventHandler<HTMLButtonElement> = async (
    e: React.MouseEvent<HTMLElement>,
  ) => {
    e.preventDefault()
    const cmdData = {
      playerId: user.userId,
    }
    const cmd: AnonGameCmd = {
      cmdData,
      cmdId: v4(),
      gameId: game.gameId,
      gameRuleSetName: game.gameRuleSetName,
      cmdType: OhHellCmdType.DealCards,
    }
    await GameSvc.sendCmd(cmd, { jwt })
  }

  const { isMyTurn, turnPlayerHandle } = getTurnInfo(gameState, user)

  const getLastCompletedRound = (): CompleteRound => {
    return gameState.completedRounds[gameState.completedRounds.length - 1]
  }

  return (
    <div className="flex-col">
      {!isMyTurn && (
        <div className="justify-center text-center font-bold">
          waiting for {turnPlayerHandle} to deal
        </div>
      )}
      {gameState.completedRounds.length ? (
        <div className="flex items-center justify-center">
          <TrickCards round={getLastCompletedRound()}></TrickCards>
        </div>
      ) : (
        ''
      )}
      <ScoreWidget
        lastCompleteRound={getLastCompletedRound()}
        players={game.players}
      ></ScoreWidget>
      <div className="flex justify-center mt-20">
        {isMyTurn && (
          <button
            className="p-3 m-3 text-larger border-black border-2 text-center"
            type="submit"
            onClick={handleDealCards}
          >
            Deal Round {gameState.currentRound.roundNumber}
          </button>
        )}
      </div>
    </div>
  )
}

export default PlayDeal
