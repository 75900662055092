import {
  DeckCode,
  ExpertIds,
  JoinComputerPlayerCmdData,
  decks,
} from '@ohell/games'
import { FC, MouseEventHandler, useState } from 'react'
import {
  ConnectionContext,
  jwtSelector,
  userSelector,
} from '../../services/Connections/connectionState'
import { buildJoinCmd } from '../../services/GameCmdFactory/BaseGameCmdFactory'
import { getRandomArrayElement, OhHellGame } from './shared'
import { GameSvc } from '../../services/GameSvc'
import { Logger } from '../../shared/Logger'
import { buildOhHellStartCmd } from '../../services/GameCmdFactory/OhHellGameCmdFactory'

type State = {
  selectedExpert?: string
  computerPlayerName?: string
  selectedDeck: DeckCode
  roundsToPlay: string
}

const getRandomOpponentName = (): string => {
  const descriptors = [
    'worthless',
    'awful',
    'miserable',
    'wontlick',
    'driftless',
    'stupid',
    'pointless',
    'shifty',
    'hapless',
    'wreck-ed',
    'inconsolable',
    'dirty',
    'foul',
    'disgusting',
    'despicable',
    'mirthless',
    'riotous',
    'crumbsheet',
    'putrid',
    'crusty',
    'fragrent',
    'gaseous',
    'gropeless',
    'thankless',
    'dreary',
    'sketchy',
    'vacant',
  ]
  const names = [
    'garvin',
    'timblit',
    'wilmaw',
    'stusan',
    'sneet',
    'pamma',
    'blaiden',
    'gerald',
    'stebbin',
    'merrelle',
    'sacklet',
    'steve',
    'pudley',
    'mickwin',
    'boocha',
    'treverly',
    'charlkin',
    'gustaf',
    'kentry',
    'lenkel',
    'deen',
    'dwendel',
    'noncey',
    'ruebert',
  ]
  return `${getRandomArrayElement(descriptors)} ${getRandomArrayElement(names)}`
}

const getMaxRounds = (cards: number, players: number) =>
  Math.floor(cards / players)

const getRoundOptions = (deckCode: DeckCode, game: OhHellGame): number[] => {
  decks[deckCode].cards.length
  const maxRounds = getMaxRounds(
    decks[deckCode].cards.length,
    game.players.length,
  )
  if (!maxRounds || maxRounds < 1) {
    Logger.warn(`maxRounds was ${JSON.stringify(maxRounds)}`)
    return [1]
  }
  const rounds = Math.min(maxRounds, 20)
  return Array.from(Array(rounds || 12).keys()).map((val) => val + 1)
}

const Ready: FC<{ game: OhHellGame }> = (props) => {
  const game = props.game
  const user = ConnectionContext.useSelector(userSelector)
  const jwt = ConnectionContext.useSelector(jwtSelector)
  const [state, setState] = useState<State>({
    computerPlayerName: getRandomOpponentName(),
    selectedExpert: ExpertIds.random,
    selectedDeck: DeckCode.TarotSW,
    roundsToPlay: '12',
  })
  const gameState = game.gameState

  const setSelectedExpert = (expert: string) => {
    setState({
      ...state,
      selectedExpert: expert,
    })
  }

  const setSelectedDeck = (selectedDeckCode?: DeckCode) => {
    if (selectedDeckCode) {
      setState({
        ...state,
        selectedDeck: selectedDeckCode,
      })
    }
  }

  const setRoundsToPlay = (roundsToPlay: string) => {
    setState({ ...state, roundsToPlay })
  }

  const getNewRandomName: MouseEventHandler<HTMLButtonElement> = async () => {
    setState({
      ...state,
      computerPlayerName: getRandomOpponentName(),
    })
  }

  const joinComputerPlayer: MouseEventHandler<HTMLButtonElement> = async () => {
    const cmd = buildJoinCmd(game)
    if (!state.computerPlayerName) {
      Logger.debug('no computer name specified')
      return
    }
    if (!state.selectedExpert) {
      Logger.debug('no expert specified')
      return
    }
    const cmdData: JoinComputerPlayerCmdData = {
      handle: state.computerPlayerName,
      computerPlayerId: state.selectedExpert,
    }
    cmd.cmdData = cmdData
    GameSvc.sendCmd(cmd, { jwt }).then(() => {
      Logger.debug(`joined ${state.computerPlayerName}`)
      setState({
        ...state,
        computerPlayerName: getRandomOpponentName(),
      })
    })
  }

  const handleStartGame: MouseEventHandler<HTMLButtonElement> = async (
    e: React.MouseEvent<HTMLElement>,
  ) => {
    e.preventDefault()
    const deckCode = state.selectedDeck
    const roundsToPlay = parseInt(state.roundsToPlay) || null
    const cmd = buildOhHellStartCmd({ ...props.game, deckCode, roundsToPlay })
    await GameSvc.sendCmd(cmd, { jwt })
  }

  const getLabel = (value: ExpertIds): string => {
    return {
      [ExpertIds.cautious]: 'cautious',
      [ExpertIds.random]: 'random',
      [ExpertIds.thirsty]: 'thirsty',
    }[value]
  }
  /*
            <option value={ExpertIds.cautious}>{getLabel(ExpertIdsca.}</option>
            <option value={ExpertIds.random}>random</option>
            <option value={ExpertIds.thirsty}>thirsty</option>
            */
  return (
    <div className="bg-classicgray-dark p-2 pb-1 m-2 md:p-6 w-full md:w-10/12 max-w-lg rounded-md flex-col">
      {user.userId === game.hostUserId && (
        <div className="my-5 text-xs">
          <h3>ready to start</h3>
          <div className="my-5 mx-5 flex justify-left">
            <label>
              Choose a deck:<span> </span>
              <select
                value={state.selectedDeck}
                name="selectedDeck"
                onChange={(e) =>
                  setSelectedDeck(e.target.value as DeckCode | undefined)
                }
              >
                <option value={DeckCode.TarotSW}>
                  Pamela Smith Tarot Deck
                </option>
                <option value={DeckCode.AcesHigh}>
                  Dan Tek Eye Standard Deck
                </option>
              </select>
            </label>
          </div>
          <div className="my-5 mx-5 flex justify-left">
            <label>
              How many rounds?<span> </span>
              <select
                value={state.roundsToPlay}
                onChange={(e) => setRoundsToPlay(e.target.value)}
                className="w-10 text-center"
              >
                {getRoundOptions(state.selectedDeck, game).map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
            </label>
          </div>
          <div className="mt-10 mb-1 mx-5 flex justify-left">
            <span>Add a computer player?</span>
          </div>
          <div className="my-5 mx-5 flex justify-left">
            <span>How about {state.computerPlayerName}, a </span>
            <select
              className="ml-1"
              defaultValue={state.selectedExpert}
              name="selectedGame"
              onChange={(e) => setSelectedExpert(e.target.value)}
            >
              {Object.keys(ExpertIds).map((id) => (
                <option key={`expertOption${id}`} value={id}>
                  {getLabel(id as ExpertIds)}
                </option>
              ))}
            </select>
            <span>opponent?</span>
          </div>
          <div className="my-2 flex justify-center">
            <div>
              <button
                className="mx-1 px-1 border border-black"
                onClick={getNewRandomName}
              >
                different name
              </button>
            </div>
            <div>
              <button
                className="mx-1 px-1 border border-black"
                onClick={joinComputerPlayer}
              >
                add
              </button>
            </div>
          </div>
          <div className="flex justify-center text-xl m-5">
            <button
              className="p-3 m-3 text-larger border-black border-2 text-center"
              type="submit"
              onClick={handleStartGame}
            >
              start the game
            </button>
          </div>
        </div>
      )}
      {user.userId !== game.hostUserId && (
        <span>
          waiting for host {game.hostHandle} {gameState}
        </span>
      )}
    </div>
  )
}

export default Ready
