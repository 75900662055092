import {
  CardRef,
  DeckCode,
  DeckCodeParam,
  getCard,
  getDeckCode,
  Suit,
} from '../../shared'

export type Hand = {
  playerId: string
  cards: CardRef[]
}

export type PlayedCard = {
  playerId: string
  card: CardRef
}

export interface Trick {
  cards: PlayedCard[]
}

export interface CompletedTrick extends Trick {
  winnerId: string
}

export function initTrick(): Trick {
  return { cards: [] }
}

export function getPlayerHand(hands: Hand[], playerId: string): Hand {
  const hand = hands.find((hand) => hand.playerId === playerId)
  if (!hand) {
    throw new Error('could not find players hand')
  }
  return hand
}

export function getLedSuit(
  param: DeckCodeParam,
  trick?: Trick,
): Suit | undefined {
  const deckCode = getDeckCode(param)
  if (!trick?.cards.length) {
    return undefined
  }
  const card = getCard(deckCode, trick.cards[0].card)
  return card.suit
}
