import { CompleteRound, GamePlayer, PlayerScore } from '@ohell/games'
import { FC } from 'react'
import { getNumberOrDash } from './shared'

const ScoreWidget: FC<{
  lastCompleteRound: CompleteRound | undefined
  players: GamePlayer[]
}> = (props) => {
  if (!props.lastCompleteRound) {
    return <div></div>
  }

  const round = props.lastCompleteRound
  const players = props.players.reduce(
    (playerMap, player) => {
      playerMap[player.userId] = player
      return playerMap
    },
    {} as Record<string, GamePlayer>,
  )

  const getRow = (score: PlayerScore) => {
    const playerHandle = players[score.playerId].handle
    const bidWon = `${getNumberOrDash(score.bidTricks)} / ${getNumberOrDash(score.wonTricks)}`
    const roundScore = score.roundScore
    const totalScore = score.netScore
    const rowKey = `swhandle${score.playerId}`
    return [
      <div key={rowKey}>{playerHandle}</div>,
      <div key={`${rowKey}-won`}>{bidWon}</div>,
      <div key={`${rowKey}-score`}>{roundScore}</div>,
      <div key={`${rowKey}-total`}>{totalScore}</div>,
    ]
  }

  const getTable = (scores: PlayerScore[]) => {
    const rowArray: JSX.Element[][] = scores.map((score) => getRow(score))
    const header: JSX.Element[] = [
      <div key="swheaderName" className="font-bold">
        player
      </div>,
      <div key="swheaderBidWon" className="font-bold">
        bid / won
      </div>,
      <div key="swheaderRoundScore" className="font-bold">
        score
      </div>,
      <div key="swheaderTotal" className="font-bold">
        total
      </div>,
    ]
    return header.concat(...rowArray)
  }

  return (
    <div className="grid grid-cols-4 gap-4 gap-y-2">{getTable(round.bids)}</div>
  )
}

export default ScoreWidget
