import React from 'react'

export type AppContextType = {
  setTargetPath: (targetPath: string | null) => void
  getTargetPath: () => string
  doShowDevOutput: () => boolean // could go in state machine?
}

enum localKeys {
  targetPath = 'targetPath',
}

function getTargetPath(): string {
  return localStorage.getItem(localKeys.targetPath) || '/'
}
function setTargetPath(targetPath: string | null) {
  localStorage.setItem(localKeys.targetPath, targetPath || '')
}

let showDevOutput: boolean = false

const doShowDevOutput: () => boolean = (): boolean => {
  return showDevOutput
}
declare global {
  interface Window {
    toggleDevOutput: () => void
  }
}

window.toggleDevOutput = () => {
  showDevOutput = !showDevOutput
}

export const initialAppContextValue: AppContextType = {
  getTargetPath,
  setTargetPath,
  doShowDevOutput,
}

export const AppContext = React.createContext(initialAppContextValue)
