import { CompleteRound } from '@ohell/games'
import { FC } from 'react'

import ScoreWidget from './ScoreWidget'
import { OhHellGame } from './shared'
import TrickCards from './TrickCards'

const Complete: FC<{ game: OhHellGame }> = (props) => {
  const game = props.game
  const gameState = game.gameState

  const getLastCompletedRound = (): CompleteRound => {
    return gameState.completedRounds[gameState.completedRounds.length - 1]
  }

  return (
    <div className="bg-classicgray-dark p-2 pb-1 m-1 md:p-6 w-full md:w-10/12 rounded-md flex-col">
      {
        <div className="my-5 text-xs">
          <h3>complete!</h3>
        </div>
      }
      {gameState.completedRounds.length && (
        <TrickCards round={getLastCompletedRound()}></TrickCards>
      )}
      <ScoreWidget
        lastCompleteRound={getLastCompletedRound()}
        players={game.players}
      ></ScoreWidget>
    </div>
  )
}
export default Complete
