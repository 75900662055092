import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react'
import { AppContext } from '../AppContext'
import { useNavigate, useLocation } from 'react-router'
import { Pronouns } from '@ohell/common'
import {
  requestGuestAuthToken,
  requestRandomHandle,
  TokenRefreshResult,
} from '../services/Connections/userAuth'
import {
  ConnectionContext,
  deviceCodeSelector,
  isAuthorizedSelector,
} from '../services/Connections/connectionState'
import {
  getMagicLinkState,
  redeemMagicLink,
  requestLoginMagicLinkEmail,
  requestNewUserMagicLinkEmail,
} from '../services/Connections/magicLink'
import { MagicLinkState } from '../services/Connections/IdentityTypes'
import { storeAuthClient, storeUser } from '../services/Connections/authStorage'
import { shallowEqual } from '@xstate/react'
import { Logger } from '../shared/Logger'
import { NoProps } from '../shared/types'
import axios, { AxiosError } from 'axios'

enum PageModes {
  initial,
  continueAsGuest,
  continueNewUser,
  continueViaEmail,
  emailHasBeenSent,
  enterMagicLinkCode,
  inboundMagicLink,
}

type UserFields = {
  handle: string
  email: string
}

type State = {
  urlParsed: boolean
  user: UserFields
  errorMessage: string
  pageMode: PageModes
  magicLinkEntry: string
  magicLinkState: MagicLinkState
}

enum ReducerOptions {
  error = 'error',
  parseUrlComplete = 'parseUrlComplete',
  initGuest = 'initGuest',
  initNewUser = 'initNewUser',
  initEmailLogin = 'initEmailLogin',
  emailSent = 'emailSent',
  setUser = 'setUser',
  setHandle = 'setHandle',
  setEmail = 'setEmail',
  setMagicLinkCode = 'setMagicLinkCode',
  promptForMagicLinkCode = 'promptForMagicLinkCode',
  inboundMagicLink = 'inboundMagicLink',
  redeemingMagicLink = 'redeemingMagicLink',
}

type Action = {
  type: ReducerOptions
  data: unknown
}

const initialState: State = {
  urlParsed: false,
  user: { email: '', handle: '' },
  errorMessage: '',
  pageMode: PageModes.initial,
  magicLinkEntry: '',
  magicLinkState: {
    authCode: '',
    keyGroupStatuses: [],
    isPostReady: false,
    redemptionInFlight: false,
  },
}

const loginStateReducer = (state: State, action: Action): State => {
  Logger.debug(
    `loginState reducer executing with state ${JSON.stringify(state)} action ${JSON.stringify(action)}`,
  )
  switch (action.type) {
    case ReducerOptions.error: {
      const data = action.data as { errorMessage?: string; message?: string }
      Logger.error(
        `Login error: ${data.errorMessage || 'unknown error in reducer'}`,
      )
      return {
        ...state,
        errorMessage: data.errorMessage || 'unknown error in reducer',
      }
    }
    case ReducerOptions.parseUrlComplete: {
      return { ...state, urlParsed: true }
    }
    case ReducerOptions.initGuest: {
      const data = action.data as { user: UserFields }
      return {
        ...state,
        user: { ...state.user, ...(data.user || {}) },
        pageMode: PageModes.continueAsGuest,
        errorMessage: '',
      }
    }
    case ReducerOptions.initNewUser: {
      const data = action.data as { user: UserFields }
      return {
        ...state,
        user: data.user,
        pageMode: PageModes.continueNewUser,
        errorMessage: '',
      }
    }
    case ReducerOptions.initEmailLogin: {
      const data = action.data as { user: UserFields }
      return {
        ...state,
        user: data.user,
        pageMode: PageModes.continueViaEmail,
        errorMessage: '',
      }
    }
    case ReducerOptions.emailSent: {
      return {
        ...state,
        pageMode: PageModes.emailHasBeenSent,
        errorMessage: '',
      }
    }
    case ReducerOptions.promptForMagicLinkCode: {
      return {
        ...state,
        pageMode: PageModes.enterMagicLinkCode,
        errorMessage: '',
      }
    }
    case ReducerOptions.setUser: {
      const data = action.data as { user: UserFields }
      if (!data.user) {
        Logger.error('did not specify user when setting user')
        return state
      }
      return { ...state, user: data.user, errorMessage: '' }
    }
    case ReducerOptions.setHandle: {
      const data = action.data as { handle: string }
      if (!data.handle || typeof data.handle !== 'string') {
        Logger.error(
          `did not specify handle when setting handle ${JSON.stringify(action)} ${typeof data.handle} `,
        )
        return state
      }
      return {
        ...state,
        user: { ...state.user, handle: data.handle },
        errorMessage: '',
      }
    }
    case ReducerOptions.setEmail: {
      const data = action.data as { email: string; handle: string }
      if (
        (!data.email && data.email !== '') ||
        typeof data.email !== 'string'
      ) {
        Logger.error(
          `did not specify email when setting email ${JSON.stringify(action)} ${typeof data.handle} `,
        )
        return state
      }
      return {
        ...state,
        user: {
          ...(state.user || {
            userId: '',
            handle: '',
            pronouns: Pronouns.They,
          }),
          email: data.email,
        },
        errorMessage: '',
      }
    }
    case ReducerOptions.setMagicLinkCode: {
      const data = action.data as {
        magicLinkState: MagicLinkState
        magicLinkEntry: string
      }
      if (!data.magicLinkState) {
        Logger.error(`did not specify magicLinkState`)
        return state
      }
      const magicLinkEntry = data.magicLinkEntry as unknown as string
      const magicLinkState = data.magicLinkState as unknown as MagicLinkState
      return { ...state, magicLinkEntry, magicLinkState, errorMessage: '' }
    }
    case ReducerOptions.inboundMagicLink: {
      const data = action.data as { code: string; email: string }
      if (!data.code || !data.email) {
        Logger.error('malformed magic link')
        return state
      }
      return {
        ...state,
        urlParsed: true,
        pageMode: PageModes.inboundMagicLink,
        user: { ...state.user, email: data.email },
        magicLinkState: {
          authCode: data.code,
          keyGroupStatuses: [],
          isPostReady: true,
          redemptionInFlight: false,
        },
      }
    }
    case ReducerOptions.redeemingMagicLink: {
      return {
        ...state,
        magicLinkState: { ...state.magicLinkState, redemptionInFlight: true },
      }
    }
  }
  return state
}

const Login: FC<NoProps> = () => {
  Logger.debug('login function entry')
  const [state, dispatch] = useReducer(loginStateReducer, initialState)

  const appContext = useContext(AppContext)
  const connection = ConnectionContext.useActorRef()
  const isAuthorized = ConnectionContext.useSelector(
    isAuthorizedSelector,
    shallowEqual,
  )
  const deviceCode = ConnectionContext.useSelector(deviceCodeSelector)
  const navigate = useNavigate()
  const location = useLocation()
  Logger.debug(`login function executing with state ${JSON.stringify(state)} }`)

  const setValidConnection = useCallback(
    (tokenRefreshResult: TokenRefreshResult): void => {
      Logger.debug('in setValidConnection setting connection to valid')
      connection.send({
        type: 'AUTH_RESPONSE_RECEIVED',
        value: tokenRefreshResult,
      })
      Logger.debug('in setValidConnection raised AUTH_RESPONSE_RECEIVED')
    },
    [connection, storeAuthClient, storeUser],
  )

  const continueToDestination = useCallback(() => {
    Logger.debug('navigating...')
    const destination = appContext.getTargetPath()
    appContext.setTargetPath(null)
    Logger.debug(`moving to ${destination}`)
    navigate(destination)
  }, [appContext, navigate])

  const continueFromMagicLink = useCallback(() => {
    Logger.debug(`redeeming magic link....`)
    dispatch({ type: ReducerOptions.redeemingMagicLink, data: {} })
    redeemMagicLink(state.user?.email, state.magicLinkState.authCode)
      .then((result: TokenRefreshResult) => {
        setValidConnection(result)
        // continueToDestination()
      })
      .catch((ex) => {
        dispatch({
          type: ReducerOptions.error,
          data: {
            errorMessage:
              ex.message ||
              'Unknown error while redeeming magic link.  Please try again.',
          },
        })
      })
  }, [state, setValidConnection, redeemMagicLink, dispatch])

  const ResponseSpan = useRef<HTMLSpanElement>(null)

  function startResponseFadeout(delay = 2000) {
    if (!ResponseSpan || !ResponseSpan.current) {
      return
    }
    ResponseSpan.current.classList.remove('opacity-0')
    ResponseSpan.current.classList.add('opacity-100')
    setTimeout(() => {
      if (!ResponseSpan || !ResponseSpan.current) {
        return
      }
      ResponseSpan.current.classList.remove('opacity-100')
      ResponseSpan.current.classList.add('opacity-0')
      setTimeout(() => {})
    }, delay)
  }

  useEffect(() => {
    if (state.errorMessage) {
      startResponseFadeout()
    }
  }, [state.errorMessage])

  useEffect(() => {
    if (isAuthorized) {
      Logger.debug('useEffect - continuing to destination')
      continueToDestination()
    }
  }, [isAuthorized, continueToDestination])

  useEffect(() => {
    if (
      state.magicLinkState.isPostReady &&
      state.user?.email &&
      !state.magicLinkState?.redemptionInFlight
    ) {
      continueFromMagicLink()
    }
  }, [
    state.magicLinkState.isPostReady,
    state.user?.email,
    state.magicLinkState?.redemptionInFlight,
    continueFromMagicLink,
  ])

  useEffect(() => {
    if (!state.urlParsed) {
      const query = new URLSearchParams(location.search)
      const email = query.get('email')
      const code = query.get('code')

      if (!email && !code) {
        dispatch({ type: ReducerOptions.parseUrlComplete, data: {} })
      }
      if ((email && !code) || (code && !email)) {
        dispatch({
          type: ReducerOptions.error,
          data: {
            errorMessage:
              'Magic link was corrupted; needs to provide both email and auth code',
          },
        })
      }
      if (email && code) {
        dispatch({
          type: ReducerOptions.inboundMagicLink,
          data: { email, code },
        })
      }
    }
  }, [state.urlParsed, dispatch])

  function beginGuestLogin() {
    if (!state.user.handle) {
      requestRandomHandle()
        .then((handle: string) =>
          dispatch({
            type: ReducerOptions.initGuest,
            data: { user: { handle } },
          }),
        )
        .catch((ex: Error) =>
          dispatch({
            type: ReducerOptions.error,
            data: { errorMessage: ex.message },
          }),
        )
      return
    } else {
      dispatch({ type: ReducerOptions.initGuest, data: {} })
    }
  }

  function continueAsGuest() {
    if (!state.user?.handle) {
      return dispatch({
        type: ReducerOptions.error,
        data: { errorMessage: 'can not continue as guest without handle' },
      })
    }
    requestGuestAuthToken(deviceCode, state.user?.handle)
      .then((result: TokenRefreshResult) => {
        setValidConnection(result)
        // continueToDestination()
      })
      .catch((ex: Error) => {
        return dispatch({
          type: ReducerOptions.error,
          data: { errorMessage: ex.message },
        })
      })
  }

  function beginCreateAccount() {
    dispatch({ type: ReducerOptions.initNewUser, data: {} })
  }

  function beginMagicLinkLogin() {
    dispatch({ type: ReducerOptions.initEmailLogin, data: {} })
  }

  async function getDifferentRandomName() {
    return requestRandomHandle()
      .then((handle: string) => {
        dispatch({ type: ReducerOptions.setHandle, data: { handle } })
      })
      .catch((ex) => {
        dispatch({
          type: ReducerOptions.error,
          data: { errorMessage: ex.message || 'unknown error occurred' },
        })
      })
  }

  function updateHandle(e: React.ChangeEvent<HTMLInputElement>) {
    const handleValue = e.target.value || ''
    dispatch({ type: ReducerOptions.setHandle, data: { handle: handleValue } })
  }

  function updateEmail(e: React.ChangeEvent<HTMLInputElement>) {
    const emailValue = e.target.value || ''
    dispatch({ type: ReducerOptions.setEmail, data: { email: emailValue } })
  }

  function updateMagicLinkEntry(e: React.ChangeEvent<HTMLInputElement>) {
    const magicLinkEntry = e.target.value || ''
    const magicLinkState: MagicLinkState = getMagicLinkState(magicLinkEntry)
    Logger.debug(JSON.stringify(magicLinkState))
    dispatch({
      type: ReducerOptions.setMagicLinkCode,
      data: { magicLinkEntry, magicLinkState },
    })
  }

  function requestMagicLinkNewUser() {
    requestNewUserMagicLinkEmail(
      state.user.email,
      deviceCode,
      state.user.handle,
    )
      .then(() => {
        dispatch({ type: ReducerOptions.emailSent, data: {} })
      })
      .catch((ex: Error) => {
        dispatch({
          type: ReducerOptions.error,
          data: { errorMessage: ex.message },
        })
      })
  }

  function requestMagicLinkEmail() {
    if (!state.user) {
      Logger.debug(`WIP: can not request magic link if user is not defined`)
      return
    }
    requestLoginMagicLinkEmail(state.user.email, deviceCode)
      .then(() => dispatch({ type: ReducerOptions.emailSent, data: {} }))
      .catch((ex: Error | AxiosError) => {
        if (axios.isAxiosError(ex)) {
          const serverMessage = ex.response?.data // error: "Bad Request" message:  "user for email address not found" statusCode : 400
          dispatch({
            type: ReducerOptions.error,
            data: { errorMessage: serverMessage.message },
          })
          return
        }
        dispatch({
          type: ReducerOptions.error,
          data: { errorMessage: ex.message },
        })
      })
  }

  function enterMagicLinkCode() {
    dispatch({ type: ReducerOptions.promptForMagicLinkCode, data: {} })
  }

  return (
    <div className="text-center flex flex-col items-center mb-10">
      <h1>hello stranger</h1>
      <div className="flex flex-col items-center">
        <p className="my-2">haven't seen you here before</p>
      </div>
      {state.pageMode === PageModes.initial && (
        <div>
          <div className="bg-classicgray-dark my-6 p-2 md:p-6 w-full rounded-md flex flex-col items-center">
            <button
              className="my-2 mx-4 px-2 border border-black"
              onClick={beginGuestLogin}
            >
              continue as guest
            </button>
            <button
              className="my-2 mx-4 px-2 border border-black"
              onClick={beginCreateAccount}
            >
              create an account
            </button>
            <button
              className="my-2 mx-4 px-2 border border-black"
              onClick={beginMagicLinkLogin}
            >
              login via magic link email
            </button>
          </div>
        </div>
      )}
      {state.pageMode === PageModes.continueAsGuest && (
        <div>
          <div className="bg-classicgray-dark my-6 p-2 md:p-6 w-full rounded-md flex flex-col items-center">
            <p>{`how about we call you... ${(state.user && state.user.handle) || `... guess I'm at a loss`}`}</p>
            <button
              className="my-2 px-2 border border-black"
              onClick={getDifferentRandomName}
            >
              no, different random name, please
            </button>
            <button
              className="my-2 px-2 border border-black"
              onClick={continueAsGuest}
            >
              yep, that'll do
            </button>
          </div>
        </div>
      )}
      {state.pageMode === PageModes.continueNewUser && (
        <div>
          <div className="bg-classicgray-dark my-6 p-2 md:p-6 w-full rounded-md flex flex-col items-center">
            <p>{`enter a handle`}</p>
            <input
              className="text-left w-48 pl-1"
              type="text"
              id="handleTextField"
              value={(state.user && state.user.handle) || ''}
              onChange={updateHandle}
            />
            <p>{`enter your email address`}</p>
            <input
              className="text-left w-48 pl-1"
              type="text"
              id="handleTextField"
              value={(state.user && state.user.email) || ''}
              onChange={updateEmail}
            />
            <button
              className="my-2 px-2 border border-black"
              onClick={requestMagicLinkNewUser}
            >
              send me an email please
            </button>
          </div>
        </div>
      )}
      {state.pageMode === PageModes.continueViaEmail && (
        <div>
          <div className="bg-classicgray-dark my-6 p-2 md:p-6 w-full rounded-md flex flex-col items-center">
            <p>{`great, let's send a magic link to your email...`}</p>
            <p>{`remind me where you get your mail again?`}</p>
            <input
              className="text-left w-48 pl-1"
              type="text"
              id="handleTextField"
              value={(state.user && state.user.email) || ''}
              onChange={updateEmail}
            />
            <button
              className="my-2 px-2 border border-black"
              onClick={requestMagicLinkEmail}
            >
              send me an email please
            </button>
            <button
              className="my-2 mx-4 px-2 border border-black"
              onClick={enterMagicLinkCode}
            >
              enter link code from email
            </button>
          </div>
        </div>
      )}
      {state.pageMode === PageModes.emailHasBeenSent && (
        <div>
          <div className="bg-classicgray-dark my-6 p-2 md:p-6 w-full rounded-md flex flex-col items-center">
            <p>{`email with further instructions will be at your mailbox within some seconds`}</p>
            <p>{`like, within ten seconds or our integration tests are fucked again`}</p>
            <p>{`note that you need to click that link from within the browser you want to log in to...`}</p>
            <button
              className="my-2 px-2 border border-black"
              onClick={enterMagicLinkCode}
            >
              or else, click here to manually enter your code
            </button>
          </div>
        </div>
      )}
      {state.pageMode === PageModes.enterMagicLinkCode && (
        <div>
          <div className="bg-classicgray-dark my-6 p-2 md:p-6 w-full rounded-md flex flex-col items-center">
            <p>{`enter your email address`}</p>
            <input
              className="text-left w-48 pl-1"
              type="text"
              id="handleTextField"
              value={(state.user && state.user.email) || ''}
              onChange={updateEmail}
            />
            <p>{`enter the code from your email`}</p>
            <input
              className="text-left w-48 pl-1"
              type="text"
              id="handleTextField"
              value={state.magicLinkEntry || ''}
              onChange={updateMagicLinkEntry}
            />
            {state.magicLinkState.isPostReady ? (
              <p>code looks valid, let's assess the grade of yer hash....</p>
            ) : (
              <button
                className="my-2 px-2 border border-black"
                onClick={requestMagicLinkEmail}
              >
                click here to try sending another email
              </button>
            )}
          </div>
        </div>
      )}
      {state.pageMode === PageModes.inboundMagicLink && (
        <div>
          <div className="bg-classicgray-dark my-6 p-2 md:p-6 w-full rounded-md flex flex-col items-center">
            <p>{`I see you've clicked a magic link... logging you in...`}</p>
          </div>
        </div>
      )}
      <span
        ref={ResponseSpan}
        className="inline-block m-2 px-3 transition-all duration-1000 ease-in-out"
      >
        {state.errorMessage}
      </span>
    </div>
  )
}

export default Login
