import { ChatData, ChatRoom } from '../shared/types'
import { Pronouns } from '@ohell/common'
import { ApiClient } from './Connections'
import { AppRequestOptions } from './Connections/httpsWrappers'
import {
  InboundMessage,
  OutboundMessage,
  actions,
  defaultChannels,
} from './Connections/socket'
import { Logger } from '../shared/Logger'

export const globalChatRooms = {
  front: { chatRoomId: 'chrm-front', chatroomName: 'chrm-front' },
  echo: { chatRoomId: 'chrm-echo', chatroomName: 'chrm-echo' },
}

export type ChatMessage = {
  text: string
  userId: string
  displayName: string
  timeReceived: Date
}

// TODO prod environment
const chatServiceUrl =
  'https://med93mwkuc.execute-api.us-east-1.amazonaws.com/dev/apiv2'
const chatRoomPath = 'chatroom'

// TODO wrap connection.send in hooks for API and socket

export const buildOutboundChatMessage = (
  message: string,
  chatRoomId: string,
  fromUid: string,
  fromHandle: string,
  jwt: string,
): OutboundMessage => {
  const chatData: ChatData = {
    text: message,
    fromMember: {
      uid: fromUid,
      handle: fromHandle,
      pronouns: Pronouns.They,
    },
    toChatRoomId: chatRoomId,
    dateOrigin: new Date(),
  }
  const outboundMessage: OutboundMessage = {
    action: actions.sendChat,
    message: chatData,
    channel: defaultChannels.chat,
    jwt,
  }
  return outboundMessage
}

export const convertInboundMessageToChatMessage = (
  message: InboundMessage,
): ChatMessage | null => {
  if (message.channel !== defaultChannels.chat) {
    return null
  }
  const data = message.data as ChatData
  return {
    text: data.text,
    userId: data.fromMember.uid,
    displayName: data.fromMember.handle,
    timeReceived: new Date(),
  } as ChatMessage
}

const getChatRoom = async (
  options: AppRequestOptions,
  chatRoomId: string = globalChatRooms.front.chatRoomId,
): Promise<ChatRoom | null> => {
  const chatRoom = await ApiClient.get<ChatRoom>(
    chatServiceUrl,
    `${chatRoomPath}/${encodeURIComponent(chatRoomId)}`,
    options,
  )
  if (!chatRoom) {
    Logger.error(`chatroom was missing / empty: ${JSON.stringify(chatRoom)}`)
    return null
  }
  const chatLog = chatRoom.chatLog
    ? chatRoom.chatLog.map((chatData: ChatData) => ({
        ...chatData,
        dateOrigin: new Date(chatData.dateOrigin),
        dateSent: chatData.dateSent && new Date(chatData.dateSent),
      }))
    : []
  return {
    ...chatRoom,
    chatLog,
  }
}

export const getChatRoomMessages = async (
  options: AppRequestOptions,
  chatRoomId: string,
): Promise<ChatMessage[]> => {
  const chatRoom = await getChatRoom(options, chatRoomId)
  if (!chatRoom || !chatRoom.chatLog) {
    return []
  }
  return chatRoom.chatLog.map(
    (chatData: ChatData): ChatMessage => ({
      text: chatData.text,
      userId: chatData.fromMember.uid,
      displayName: chatData.fromMember.handle,
      timeReceived: chatData.dateOrigin, // TODO because we're pulling from server, don't really have this...
    }),
  )
}

export const ChatFunctions = {
  getChatRoomMessages,
  buildOutboundChatMessage,
  convertInboundMessageToChatMessage,
}
