import { default as axios, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Logger } from '../../shared/Logger'

export type AppRequestOptions =
  | {
      noAuthorization: true
    }
  | {
      noAuthorization?: boolean
      jwt: string
    }

export const noauthOption: AppRequestOptions = { noAuthorization: true }

export const post = async <T>(
  domain: string,
  path: string,
  body: object,
  options: AppRequestOptions,
): Promise<T> => {
  Logger.debug(`posting ${domain}/${path} with ${JSON.stringify(body)}`)
  const config: AxiosRequestConfig = buildConfig(options)
  const response = await axios.post<T, AxiosResponse<T>>(
    `${domain}/${path}`,
    body,
    config,
  )
  Logger.debug(`post response was ${JSON.stringify(response)}`)
  return response.data
}

export const get = async <T>(
  domain: string,
  path: string,
  options: AppRequestOptions,
): Promise<T> => {
  Logger.debug(`getting  ${domain}/${path}`)
  const config: AxiosRequestConfig = buildConfig(options)
  const response = (await axios.get(
    `${domain}/${path}`,
    config,
  )) as AxiosResponse<T>
  return response.data
}

export const getImage = async (
  url: string,
  options: AppRequestOptions,
): Promise<Blob> => {
  Logger.debug(`getting ${url}`)
  const config: AxiosRequestConfig = {
    ...buildConfig(options),
    responseType: 'blob',
  }
  const response = (await axios.get(`${url}`, config)) as AxiosResponse<Blob>
  return response.data
}

export const deleteItem = async (
  domain: string,
  path: string,
  options: AppRequestOptions,
): Promise<void> => {
  Logger.debug(`deleting  ${domain}/${path}`)
  const config: AxiosRequestConfig = buildConfig(options)
  ;(await axios.delete(`${domain}/${path}`, config)) as AxiosResponse<unknown>
}

function buildConfig(options: AppRequestOptions): AxiosRequestConfig {
  if (options.noAuthorization) {
    return getEmptyConfig()
  }
  if (!options.jwt) {
    throw new Error('jwt not provided in buildConfig')
  }
  return getAuthorizedConfig(getEmptyConfig(), options.jwt || '')
}

function getEmptyConfig(): AxiosRequestConfig {
  return { headers: {} }
}

function getAuthorizedConfig(
  config: AxiosRequestConfig,
  jwt: string,
): AxiosRequestConfig {
  return {
    ...config,
    headers: { ...(config.headers || {}), Authorization: `Bearer ${jwt}` },
  }
}
