import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { AppContext } from '../../AppContext'
import {
  ConnectionContext,
  isAuthorizedSelector,
} from '../../services/Connections/connectionState'
import { Logger } from '../../shared/Logger'

function buildNonLoginPath(pathname: string, search: string): string {
  if (pathname.toUpperCase() === '/LOGIN') {
    return '/'
  }
  return pathname.concat(search)
}

function RequireAuth({ children }: { children: JSX.Element }): JSX.Element {
  const isAuthorized = ConnectionContext.useSelector(isAuthorizedSelector)
  const connection = ConnectionContext.useSelector((state) => state)
  const context = useContext(AppContext)
  const { pathname, search } = useLocation()
  const url = buildNonLoginPath(pathname, search)
  context.setTargetPath(url)
  Logger.debug(`caching path ${context.getTargetPath()}`)
  if (!isAuthorized) {
    Logger.debug(
      `RequireAuth says not authorized: ${JSON.stringify(connection.value)}, redirecting to login`,
    )
    return <Navigate to="/login" />
  }
  return children
}

export default RequireAuth
