import {
  CardRef,
  DeckCode,
  DeckCodeParam,
  getCard,
  getDeckCode,
  SmithWaite,
  AcesHigh,
  Deck,
} from '@ohell/games'
import { Logger } from '../shared/Logger'

const ohHellSmImages: Record<DeckCode, Record<CardRef, HTMLImageElement>> = {
  [DeckCode.TarotSW]: {},
  [DeckCode.AcesHigh]: {},
}

const imageDomain: string = `https://ohellimages.my-lr.net`

const requestImage = (url: string): HTMLImageElement => {
  const image = new Image()
  image.src = url
  return image
}

function getCardDescription(param: DeckCodeParam, cardRef: CardRef) {
  const card = getCard(getDeckCode(param), cardRef)
  if (!card.suit.suitName) {
    return ''
  }
  return card.suit.suitName === 'trumps'
    ? card.cardValueName
    : `${card.cardValueName} of ${card.suit.suitName}`
}

export const preWarmSWDeck = () => {
  if (Object.keys(ohHellSmImages[DeckCode.TarotSW]).length > 0) {
    return
  }
  Logger.debug('prewarming smith')
  for (let i = 0; i < SmithWaite.cards.length; i++) {
    if (!ohHellSmImages[DeckCode.TarotSW][i]) {
      const imageUrl = imageDomain.concat(SmithWaite.cards[i].smallUrl)
      const image = requestImage(imageUrl)
      ohHellSmImages[DeckCode.TarotSW][i] = image
    }
  }
}

export const preWarmSTDDeck = () => {
  if (Object.keys(ohHellSmImages[DeckCode.AcesHigh]).length > 0) {
    return
  }
  Logger.debug('prewarming aces high')
  for (let i = 0; i < AcesHigh.cards.length; i++) {
    if (!ohHellSmImages[DeckCode.AcesHigh][i]) {
      const imageUrl = imageDomain.concat(AcesHigh.cards[i].smallUrl)
      const image = requestImage(imageUrl)
      ohHellSmImages[DeckCode.AcesHigh][i] = image
    }
  }
}

export const getDeck = (deckCode?: DeckCode): Deck => {
  switch (deckCode) {
    case DeckCode.AcesHigh:
      return AcesHigh
    case DeckCode.TarotSW:
      return SmithWaite
  }
  throw new Error(`Could not find deck for deck code ${getDeck}`)
}

export const getDeckImage = (
  param: DeckCodeParam,
  cardRef: CardRef,
  width?: number,
): JSX.Element => {
  const deckCode = getDeckCode(param)
  const deck: Deck = getDeck(deckCode)
  const imageUrl = imageDomain.concat(deck.cards[cardRef].smallUrl)
  if (!ohHellSmImages[deckCode][cardRef]) {
    const image = requestImage(imageUrl)
    ohHellSmImages[deckCode][cardRef] = image
  }
  return (
    <img
      src={imageUrl}
      alt={getCardDescription(param, cardRef)}
      width={width}
    />
  )
}
