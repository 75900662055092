import React, { FC, MouseEventHandler, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GameRuleSets } from '@ohell/games'
import { GameSvc } from '../services/GameSvc'
import {
  ConnectionContext,
  jwtSelector,
} from '../services/Connections/connectionState'
import { Logger } from '../shared/Logger'
import { NoProps } from '../shared/types'

type stateType = {
  selectedGame: GameRuleSets | undefined
}

const HostGame: FC<NoProps> = () => {
  const navigate = useNavigate()
  const jwt = ConnectionContext.useSelector(jwtSelector)

  const defaultState = {
    selectedGame: GameRuleSets.OhHell,
  }

  const [state, setState] = useState<stateType>(defaultState)

  const setSelectedGame = (value: string): void => {
    if (Object.values(GameRuleSets).includes(value as GameRuleSets)) {
      setState({ ...state, selectedGame: value as GameRuleSets })
    }
  }

  const createGame: MouseEventHandler<HTMLButtonElement> = async (
    e: React.MouseEvent<HTMLElement>,
  ) => {
    e.preventDefault()
    if (!state.selectedGame) {
      Logger.error(`error: could not create game - no ruleset specified`)
    }
    const game = await GameSvc.host(
      { gameRuleSetName: state.selectedGame as GameRuleSets },
      { jwt },
    )
    if (!game) {
      Logger.error(`error: could not create game`)
      return
    }
    const path = GameSvc.getGameNavigationPath(game)
    navigate(path)
  }

  return (
    <div className="">
      <h1>Choose game options.</h1>
      <div className="container mx-auto max-w-md py-10 bg-classicgray-dark m-3 p-2 md:p-6 w-full md:w-6/12 rounded-md flex-col content-between">
        <div>
          <label>
            Let's play:<span> </span>
            <select
              value={state.selectedGame}
              name="selectedGame"
              onChange={(e) => setSelectedGame(e.target.value)}
            >
              <option value={GameRuleSets.OhHell}>Oh Hell</option>
            </select>
          </label>
        </div>
        <div className="py-10">
          <button
            disabled={!state.selectedGame}
            className="my-2 mx-4 px-2 border border-black float-right"
            onClick={createGame}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  )
}

export default HostGame
