import { ApiClient } from '.'
import { identityUrl, randomDeviceCodePath } from './IdentityTypes'
import { readDeviceCode, storeDeviceCode } from './authStorage'

export const initDevice = async (): Promise<string> => {
  const previous = readDeviceCode()
  if (previous) {
    return previous
  }
  const { deviceCode } = await ApiClient.get<{ deviceCode: string }>(
    identityUrl,
    randomDeviceCodePath,
    { noAuthorization: true },
  )
  storeDeviceCode(deviceCode)
  return deviceCode
}
