import { OhHellPlayerState } from '@ohell/games'
import { FC } from 'react'
import {
  ConnectionContext,
  userSelector,
} from '../../services/Connections/connectionState'
import HandCards from './HandCards'
import {
  getTurnInfo,
  OhHellGame,
  getTrickWinnerHandle,
  getLastTrickWithPlayedCards,
} from './shared'
import TrickCards from './TrickCards'
import TrumpCard from './TrumpCard'

const PlayCard: FC<{ game: OhHellGame }> = (props) => {
  const game = props.game
  const user = ConnectionContext.useSelector(userSelector)
  const gameState = game.gameState as OhHellPlayerState
  const round = gameState.currentRound

  const { isMyTurn, turnPlayerHandle } = getTurnInfo(gameState, user)

  const { isTrickComplete, trick } = getLastTrickWithPlayedCards(
    gameState.currentRound,
  )

  return (
    <div className="flex-col h-full">
      {!isMyTurn && (
        <div className="justify-center text-center font-bold">
          waiting for {turnPlayerHandle}'s play
        </div>
      )}
      {isMyTurn && (
        <div className="justify-center text-center font-bold">your turn</div>
      )}
      <div className="flex-col justify-center items-center">
        {isTrickComplete ? (
          <div>{`${getTrickWinnerHandle(trick, round)} gets the trick!`}</div>
        ) : (
          <div>the trick</div>
        )}
        <TrumpCard game={game} />
        <div className="flex items-center justify-center">
          <TrickCards round={gameState.currentRound}></TrickCards>
        </div>
      </div>
      <div>your hand</div>
      <div className="flex">
        <HandCards game={game}></HandCards>
      </div>
    </div>
  )
}

export default PlayCard
