import { GameSummary } from '@ohell/games'
import { User, userHasRole } from '../../domain/User'
import { Roles } from '../../shared/roles'

export type GameSummaryHandler = () => void

export const getTrackerRow = (
  game: GameSummary,
  user: User,
  handleJoinGame: GameSummaryHandler,
  handleDeleteGame: GameSummaryHandler,
): React.ReactFragment => {
  const rowKey = `game-row-${game.gameId}`
  return (
    <div
      key={rowKey}
      className="flex flex-row w-full text-left py-1 flex-shrink-0 flex-grow-0"
    >
      <div className="w-4/12">
        {Math.round((Date.now() - new Date(game.createDate).valueOf()) / 60000)}{' '}
        min ago
      </div>
      <div className="w-4/12">{game.hostName}</div>
      <div className="w-2/12">
        {game.playerCount}/{game.maxPlayers}
      </div>
      <div className="w-2/12 flex justify-center flex-shrink-0">
        <button
          className="m-0 px-1 border border-black"
          type="submit"
          value={game.gameId}
          onClick={handleJoinGame}
        >
          join
        </button>
        {userHasRole(user, Roles.admin) ? (
          <button
            className="mx-0 px-1 border border-black"
            type="submit"
            value={game.gameId}
            onClick={handleDeleteGame}
          >
            del
          </button>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
