import { assign, createMachine, StateFrom, raise, fromPromise } from 'xstate'
import { createActorContext } from '@xstate/react'
import { User } from '../../domain/User'
import { AuthClient } from './IdentityTypes'
import {
  readAuthClient,
  readDeviceCode,
  readUser,
  storeAuthClient,
  storeUser,
} from './authStorage'
import { initDevice } from './device'
import {
  getIdentityHealthCheck,
  IdentityHealthCheckResponse,
  isJwtExpired,
  isRefreshTokenExpired,
  refreshAuthTokens,
  TokenRefreshResult,
} from './userAuth'
import { delayMs } from '../../shared/util'
import {
  InboundMessage,
  OutboundMessage,
  sendAuthTicket,
  socketUrl,
  defaultChannels,
  MessageBusClient,
  sendMessage,
  sendHeartbeat,
  messageBusChannelsInclude,
} from './socket'
import { IMessageEvent, w3cwebsocket } from 'websocket'
import { jwtExpiryMs } from './jwt'
import { Logger, writeError } from '../../shared/Logger'

export const connection = 'connection'

export type ConnectionContext = {
  deviceCode: string | null
  user: User | null
  authClient: AuthClient | null
  auth_error: string
  inboundMessages: InboundMessage[]
  outboundMessages: OutboundMessage[]
  messageClients: Record<string, MessageBusClient>
  socket: {
    client: w3cwebsocket | null
    retries: number
    retryInterval: number
    retryTick: number
    heartbeatTimer: number
    heartbeatInterval: number
    timeUntilNextRetry: number
  }
}

const getInitialSocket = () => ({
  client: null as w3cwebsocket | null,
  retries: 0,
  retryInterval: 1000,
  retryTick: 1,
  heartbeatTimer: 0,
  heartbeatInterval: 10000,
  timeUntilNextRetry: 1000,
})

const getInitialContext = (): ConnectionContext => ({
  deviceCode: readDeviceCode(),
  user: readUser(),
  authClient: readAuthClient(),
  auth_error: '',
  inboundMessages: [] as InboundMessage[],
  outboundMessages: [] as OutboundMessage[],
  messageClients: {} as Record<string, MessageBusClient>,
  socket: getInitialSocket(),
})

export type ConnectionEvent =
  | { type: 'AUTH_RECEIVED_REFRESH_TOKEN'; value: string }
  | { type: 'AUTH_REFRESH_TOKEN_ERROR' }
  | { type: 'AUTH_RESPONSE_RECEIVED'; value: TokenRefreshResult } // RawAuthorizationResponse
  | { type: 'AUTH_USE_ERROR' }
  | { type: 'AUTH_IS_VALID' }
  | { type: 'SEND'; value: OutboundMessage }
  | { type: 'SOCKET_SUBSCRIBE'; value: MessageBusClient }
  | { type: 'SOCKET_UNSUBSCRIBE'; value: string }
  | { type: 'SOCKET_MESSAGE'; value: InboundMessage }
  | { type: 'SOCKET_SEND_MESSAGE_ENQUEUED' }
  | { type: 'SOCKET_GET_MESSAGE_ENQUEUED' }
  | { type: 'SOCKET_AUTH_RESPONSE' }
  | { type: 'SOCKET_CLOSED' }
  | { type: 'SOCKET_SEND_ERROR' }
  | { type: 'SOCKET_RECEIVED_HEARTBEAT' }
  | { type: 'LOGOUT' }

// would be great to not dump page view state when rechecking auth
// currently page state strictly tied to logging in / connected etc.

// TODO check flows that cause double auth request / refresh token invalidation

// TODO create a subscribe / send / unsubscribe hook

// TODO implement socket send error

// TODO check if heartbeat never answered?

export const connectionMachine = createMachine(
  {
    // predictableActionArguments: true,
    // preserveActionOrder: true,
    id: connection,
    types: {} as {
      context: ConnectionContext
      events: ConnectionEvent
    },
    type: 'parallel',
    context: getInitialContext(),
    on: {
      LOGOUT: {
        target: '.auth.logging_out',
      },
      '*': {
        actions: ['logUnhandledEvent'],
      },
    },
    states: {
      auth: {
        initial: 'disconnected', // on app boot before checking storage
        states: {
          disconnected: {
            // authentication unknown or missing
            always: [
              { target: 'awaiting_device_code', guard: 'isDeviceCodeUnset' },
              {
                target: 'awaiting_identity_validity_check',
                guard: 'isAuthAppearValid',
              },
              {
                target: 'awaiting_jwt_refresh',
                guard: 'isRefreshTokenReadyJwtExpired',
              },
              {
                target: 'logging_in',
                guard: 'isRefreshTokenExpired',
                actions: [
                  'logout',
                  assign({
                    user: () => null,
                    authClient: () => null,
                  }),
                ],
              },
              { target: 'logging_in' },
            ],
            entry: [],
            exit: [],
          },
          awaiting_device_code: {
            invoke: {
              id: 'await_device_code',
              src: fromPromise(() => initDevice()),
              onDone: {
                target: 'disconnected',
                actions: [
                  'logEvent',
                  assign({
                    deviceCode: ({ event }) => event.output,
                    user: () => null,
                    authClient: () => null,
                  }),
                ],
              },
              onError: {
                target: 'api_down',
              },
            },
          },
          api_down: {
            entry: ['logEvent'],
            after: {
              3000: { target: 'disconnected' },
            },
          },
          awaiting_identity_validity_check: {
            invoke: {
              id: 'try_validity_check',
              src: fromPromise(async ({ input }) =>
                getIdentityHealthCheck(input.user, input.authClient?.accessJwt),
              ),
              input: ({ context }) => context,
              onDone: [
                { target: 'valid', guard: 'wasIdentityHealthCheckSuccessful' },
                { target: 'logging_out' },
              ],
              onError: {
                actions: assign({
                  auth_error: ({ event }) => (event.error as Error).message,
                }),
                target: 'api_down',
              },
            },
          },
          logging_out: {
            always: [
              {
                actions: [
                  'logEvent',
                  'logout',
                  'closeSocket',
                  assign({
                    user: () => null,
                    authClient: () => null,
                    socket: () => getInitialSocket(),
                  }),
                ],
                target: 'logging_in',
              },
            ],
          },
          awaiting_jwt_refresh: {
            // user waiting for auth token to refresh expired jwt
            invoke: {
              id: 'refresh_jwt',
              src: fromPromise(({ input }) => {
                return refreshAuthTokens(input.user, input.authClient)
              }),
              input: ({ context }) => context,
              onDone: {
                target: 'valid',
                actions: [
                  assign({
                    user: ({ event }) =>
                      (event.output as TokenRefreshResult).user,
                    authClient: ({ event }) =>
                      (event.output as TokenRefreshResult).authClient,
                  }),
                ],
              },
              onError: {
                target: 'logging_out',
              },
            },
          },
          logging_in: {
            // substate controlled by login page
            on: {
              AUTH_RESPONSE_RECEIVED: {
                target: 'valid',
                actions: [
                  'logEvent',
                  assign({
                    user: ({ event }) => event.value.user,
                    authClient: ({ event }) => event.value.authClient,
                  }),
                  'storeAuthInfo',
                ],
              },
            },
          },
          valid: {
            on: {
              AUTH_USE_ERROR: {
                target: 'corrupt',
              },
              AUTH_REFRESH_TOKEN_ERROR: {
                target: 'corrupt',
              },
            },
            initial: 'ready',
            states: {
              ready: {
                entry: [raise({ type: 'AUTH_IS_VALID' }), 'logEvent'],
                after: {
                  30000: { target: 'checkStale' },
                },
              },
              checkStale: {
                always: [
                  { target: 'stale', guard: 'isJwtStale' },
                  { target: 'expired', guard: 'isRefreshTokenExpired' },
                  { target: 'ready' },
                ],
              },
              stale: {
                invoke: {
                  id: 'refresh_jwt',
                  src: fromPromise(({ input }) => {
                    return refreshAuthTokens(input.user, input.authClient)
                  }),
                  input: ({ context }) => context,
                  onDone: {
                    target: 'ready',
                    actions: [
                      assign({
                        user: ({ event }) =>
                          (event.output as TokenRefreshResult).user,
                        authClient: ({ event }) =>
                          (event.output as TokenRefreshResult).authClient,
                      }),
                      'storeAuthInfo',
                    ],
                  },
                  onError: {
                    target: 'ready',
                  },
                },
              },
              expired: {
                entry: [
                  ({ context }) => {
                    writeError(
                      `Auth expired! Context was ${JSON.stringify(context)}`,
                    )
                  },
                  raise({ type: 'AUTH_REFRESH_TOKEN_ERROR' }),
                ],
              },
            },
          },
          corrupt: {
            entry: ['logEvent'],
          },
        },
      },
      socket: {
        initial: 'disconnected',
        on: {
          SEND: {
            actions: [
              'logEvent',
              assign({
                outboundMessages: ({ context, event }) => [
                  event.value,
                  ...context.outboundMessages,
                ],
              }),
              raise({ type: 'SOCKET_SEND_MESSAGE_ENQUEUED' }),
            ],
          },
          SOCKET_MESSAGE: {
            actions: [
              'logEvent',
              assign({
                inboundMessages: ({ context, event }) => {
                  return [event.value, ...context.inboundMessages]
                },
              }),
            ],
            target: '.connected.getMessage',
          },
          SOCKET_CLOSED: [
            {
              actions: ['logEvent'],
              target: '.disconnected.connecting',
              guard: 'isAuthAppearValid',
            },
            { actions: ['logEvent'], target: '.disconnected.noauth' },
          ],
          SOCKET_SUBSCRIBE: {
            actions: [
              'logEvent',
              assign({
                messageClients: ({ context, event }) => {
                  const client: MessageBusClient = event.value
                  return {
                    ...context.messageClients,
                    [client.clientId]: client,
                  }
                },
              }),
            ],
          },
          SOCKET_UNSUBSCRIBE: {
            actions: [
              'logEvent',
              assign({
                messageClients: ({ context, event }) => {
                  const clientId: string = event.value
                  if (context.messageClients[clientId]) {
                    const filteredClients: Record<string, MessageBusClient> = {}
                    for (const key in context.messageClients) {
                      if (key !== clientId) {
                        filteredClients[key] = context.messageClients[key]
                      }
                    }
                    return filteredClients
                  }
                  return context.messageClients
                },
              }),
            ],
          },
        },
        states: {
          disconnected: {
            initial: 'noauth',
            on: {
              SOCKET_AUTH_RESPONSE: { target: 'connected' },
              AUTH_IS_VALID: {
                actions: ['logEvent'],
                target: '.connecting',
              },
            },
            states: {
              noauth: {
                on: {
                  SOCKET_SEND_MESSAGE_ENQUEUED: {
                    actions: ['logEvent'],
                    target: 'connecting',
                  },
                  AUTH_IS_VALID: {
                    actions: ['logEvent'],
                    target: 'connecting',
                  },
                },
              },
              awaitRetry: {
                after: {
                  1000: { actions: ['logEvent'], target: 'connecting' },
                },
              },
              connecting: {
                invoke: {
                  id: 'connectingWebSocket',
                  src: fromPromise(async ({ input }): Promise<w3cwebsocket> => {
                    const parent = input.parent
                    Logger.debug('connectingWebSocket')
                    const socketClient = new w3cwebsocket(socketUrl)
                    socketClient.onopen = () => {
                      if (input.authClient?.accessJwt) {
                        sendAuthTicket(
                          socketClient,
                          input.authClient?.accessJwt,
                        )
                      }
                    }
                    socketClient.onmessage = (received: IMessageEvent) => {
                      Logger.debug(
                        `Message received in low level handler: ${JSON.stringify(received)}  ... data is ${JSON.stringify(received?.data?.toString())}`,
                      )
                      const messageText: string | null =
                        received?.data?.toString() || null
                      if (!messageText) {
                        Logger.debug('empty socket message?')
                        return
                      }
                      const message: InboundMessage = JSON.parse(messageText)
                      if (message.channel === defaultChannels.heartbeat) {
                        Logger.debug('got heartbeat')
                        parent.send({ type: 'SOCKET_RECEIVED_HEARTBEAT' })
                        return
                      }
                      if (message.channel === defaultChannels.auth) {
                        Logger.debug(
                          'sending SOCKET_AUTH_RESPONSE from connecting',
                        )
                        parent.send({ type: 'SOCKET_AUTH_RESPONSE' })
                        return
                      }
                      parent.send({ type: 'SOCKET_MESSAGE', value: message })
                      return
                    }
                    socketClient.onclose = () =>
                      parent.send({ type: 'SOCKET_CLOSED' })
                    socketClient.onerror = () =>
                      parent.send({ type: 'SOCKET_CLOSED' })
                    return socketClient
                  }),
                  input: ({ context, self }) => ({ ...context, parent: self }),
                  onDone: {
                    target: 'waitingAuthResponse',
                    actions: [
                      'logEvent',
                      assign({
                        socket: ({ context, event }) => {
                          return { ...context.socket, client: event.output }
                        },
                      }),
                    ],
                  },
                  onError: {
                    actions: ['logEvent'],
                    target: 'noauth',
                  },
                },
              },
              waitingAuthResponse: {
                after: {
                  10000: {
                    target: 'awaitRetry',
                  },
                },
              },
            },
          },
          connected: {
            on: {
              SOCKET_SEND_ERROR: {
                actions: ['logEvent'],
                target: 'disconnected.connecting',
              },
              SOCKET_AUTH_RESPONSE: { actions: ['logEvent'] },
            },
            initial: 'ready',
            states: {
              ready: {
                on: {
                  SOCKET_SEND_MESSAGE_ENQUEUED: {
                    actions: ['logEvent'],
                    target: 'sendMessage',
                  },
                },
                after: {
                  60000: {
                    target: 'sendHeartbeat',
                  },
                },
              },
              sendMessage: {
                always: {
                  target: 'ready',
                  actions: [
                    assign({
                      outboundMessages: ({ context }) => {
                        if (!context?.socket?.client) {
                          const message =
                            'Tried to process messages when socket unintialized'
                          Logger.debug(message)
                          throw new Error(message)
                        }
                        if (!context?.authClient?.accessJwt) {
                          const message =
                            'Tried to process messages when jwt unready'
                          Logger.debug(message)
                          throw new Error(message)
                        }
                        if (
                          context?.socket?.client?.readyState !==
                          context?.socket?.client?.OPEN
                        ) {
                          const message =
                            'Tried to process messages when socket not open'
                          Logger.debug(message)
                          throw new Error(message)
                        }
                        Logger.debug(
                          `connected and sending ${context.outboundMessages.length}`,
                        )
                        const outboundQueue: OutboundMessage[] = [
                          ...context.outboundMessages,
                        ]
                        const outboundErrored: OutboundMessage[] = []
                        while (outboundQueue.length > 0) {
                          const message = outboundQueue.shift()
                          if (!message) {
                            Logger.debug('skipping blank message')
                            continue
                          }
                          try {
                            sendMessage(
                              context.socket.client,
                              message.action,
                              message.message,
                              context.authClient?.accessJwt,
                              message.channel,
                            )
                          } catch (ex) {
                            Logger.error(
                              `error sending sending message ${(ex as Error).message}`,
                            )
                            outboundErrored.unshift(message)
                          }
                        }
                        return outboundErrored
                      },
                    }),
                  ],
                },
              },
              getMessage: {
                always: {
                  target: 'ready',
                  actions: [
                    assign({
                      inboundMessages: ({ context }) => {
                        if (!context.inboundMessages.length) {
                          Logger.debug(
                            'connectedProcessMessages but nothing to process',
                          )
                          return []
                        }
                        Logger.debug(
                          `connected and processing ${context.inboundMessages.length} inbound`,
                        )
                        const inboundMessages: InboundMessage[] = [
                          ...context.inboundMessages,
                        ]

                        while (inboundMessages.length) {
                          const message = inboundMessages.shift()
                          if (!message) {
                            Logger.debug(
                              'connectedProcessMessages: encountered a null message',
                            )
                            continue
                          }
                          if (message.channel === defaultChannels.auth) {
                            Logger.debug(
                              'got auth message in connectedProcessMessages',
                            )
                            continue
                          }
                          if (message.channel === defaultChannels.heartbeat) {
                            Logger.debug(
                              'got heartbeat message in connectedProcessMessages',
                            )
                            continue
                          }
                          try {
                            const clientArray = Object.values(
                              context.messageClients,
                            ) as MessageBusClient[]
                            let callBackFound = false
                            clientArray.forEach((client) => {
                              console.log(
                                `inspecting message with ${message.channel}, ${message.topic}`,
                              )
                              if (
                                client.callback &&
                                messageBusChannelsInclude(client.channels, {
                                  channel: message.channel,
                                  topic: message.topic,
                                })
                              ) {
                                callBackFound = true
                                client.callback(message)
                              }
                            })
                            if (!callBackFound) {
                              throw new Error(
                                `could not find client for message on channel ${message.channel} topic ${message.topic}`,
                              )
                            }
                          } catch (ex) {
                            Logger.error(
                              `error receiving message ${(ex as Error).message}`,
                            )
                          }
                        }
                        return [] as InboundMessage[]
                      },
                    }),
                  ],
                },
              },
              sendHeartbeat: {
                always: {
                  actions: [
                    'logEvent',
                    ({ context }) => {
                      if (!context.socket.client) {
                        throw new Error(
                          'attempted to send heartbeat when socket.client was null',
                        )
                      }
                      if (!context?.authClient?.accessJwt) {
                        throw new Error(
                          'attempted to send heartbeat when accessJwt was null',
                        )
                      }
                      sendHeartbeat(context.socket.client)
                    },
                  ],
                  target: 'ready',
                },
              },
            },
          },
          corrupt: {
            // socket open, but auth is bad
            on: {
              SOCKET_CLOSED: {
                target: 'disconnected.noauth',
              },
            },
          },
        },
      },
    },
  },
  {
    guards: {
      isDeviceCodeUnset: ({ context }) => {
        return !context.deviceCode
      },
      isAuthAppearValid: ({ context }) => {
        if (!context.authClient) {
          Logger.info('auth invalid: no authclient')
          return false
        }
        if (isRefreshTokenExpired(context.authClient)) {
          writeError('auth invalid: refresh token expired')
          return false
        }
        if (isJwtExpired(context.authClient)) {
          Logger.info('auth invalid: jwt expired')
          return false
        }
        if (
          context.authClient?.deviceCode &&
          context.deviceCode !== context.authClient.deviceCode
        ) {
          writeError(
            'authclient device code does not match context device code',
          )
          return false
        }
        return true
      },
      isRefreshTokenReadyJwtExpired: ({ context }) => {
        return (
          !!context.authClient &&
          !isRefreshTokenExpired(context.authClient) &&
          isJwtExpired(context.authClient)
        )
      },
      isRefreshTokenExpired: ({ context }) => {
        return !!context.authClient && isRefreshTokenExpired(context.authClient)
      },
      wasIdentityHealthCheckSuccessful: ({ event }) => {
        const response = (
          event as unknown as { output: IdentityHealthCheckResponse }
        )?.output
        return !!response?.info?.userId
      },
      isGetUserRejected: ({ context, event }) => {
        Logger.debug(
          `isGetUserRejected: auth_error: ${JSON.stringify(context.auth_error)}, event: ${JSON.stringify(event)}`,
        )
        return false
      },
      isJwtStale: ({ context }) => {
        if (!context.authClient?.jwtExpirationDate) {
          Logger.debug('no expiration date found returning is stale')
          return true
        }
        const jwtExpirationDate: Date = context.authClient.jwtExpirationDate
        const now = Date.now()
        const isStale = !!(jwtExpirationDate.getTime() - now < jwtExpiryMs)
        Logger.debug(
          `jwtExpirationDate is ${jwtExpirationDate}, now is ${now} diff is ${jwtExpirationDate.getTime() - Date.now()}} jwtExpiryMs is ${jwtExpiryMs} returning isStale: ${isStale}`,
        )
        return isStale
      },
    },
    actions: {
      logEvent: ({ event }) => {
        Logger.debug(
          `connectionState logEvent sees event: ${JSON.stringify(event)}`,
        )
      },
      logUnhandledEvent: ({ event }) => {
        Logger.debug(`unhandled event: ${JSON.stringify(event)}`)
      },
      closeSocket: ({ context }) => {
        context.socket.client && context.socket.client.close()
      },
      logout: () => {
        storeAuthClient(null)
        storeUser(null)
      },
      storeAuthInfo: ({ context, event }) => {
        const typedEvent = event as {
          type: 'AUTH_RESPONSE_RECEIVED'
          value: TokenRefreshResult
        }
        if (typedEvent.value?.user) {
          storeAuthClient(typedEvent.value.authClient)
          storeUser(typedEvent.value.user)
        } else {
          storeAuthClient(context.authClient)
          storeUser(context.user)
        }
      },
    },
  },
)

export const ConnectionContext = createActorContext(connectionMachine)

export const jwtSelector = (
  state: StateFrom<typeof connectionMachine>,
): string => {
  return state.context.authClient?.accessJwt || ''
}

export const messageSubscriptionsSelector = (
  state: StateFrom<typeof connectionMachine>,
) => {
  Logger.debug('rebuilding set of message subscriptions')
  return new Set(Object.keys(state.context.messageClients))
}

export const getJwt = async (): Promise<string> => {
  let jwt = ConnectionContext.useSelector(
    (state) => state.context.authClient?.accessJwt,
  )
  let delay = 50
  while (!jwt && delay < 1000) {
    Logger.debug(
      `getJwt - could not find jwt on context, retrying after ${delay}`,
    )
    await delayMs(delay)
    delay = delay * 2
    jwt = ConnectionContext.useSelector(
      (state) => state.context.authClient?.accessJwt,
    )
  }
  if (!jwt) {
    throw new Error(
      `no jwt to get: ${JSON.stringify(ConnectionContext.useSelector((state) => state.context))}`,
    )
  }
  return jwt
}

export const isAuthorizedSelector = (
  state: StateFrom<typeof connectionMachine>,
): boolean => {
  return (
    state.matches({ auth: 'valid' }) ||
    state.matches({ auth: 'awaiting_identity_validity_check' }) ||
    state.matches({ auth: 'awaiting_jwt_refresh' })
  )
}

export const deviceCodeSelector = (
  state: StateFrom<typeof connectionMachine>,
): string | null => {
  return state.context.deviceCode
}

export const userSelector = (
  state: StateFrom<typeof connectionMachine>,
): User => {
  const user = state.context.user
  if (!user) {
    throw new Error('attempted to get user when not logged in')
  }
  return user
}
