import { AxiosError } from 'axios'
import { ApiClient } from '.'
import { User } from '../../domain/User'
import { get, post } from './httpsWrappers'
import {
  AuthClient,
  AuthSource,
  buildAuthorizationResponse,
  GuestAuthRequest,
  guestAuthRequestPath,
  guestRandomHandlePath,
  guestRefreshTokenPath,
  healthCheckPath,
  identityHealthCheckPath,
  identityUrl,
  RawAuthorizationResponse,
  refreshUserTokenPath,
} from './IdentityTypes'
import { parseJwt } from './jwt'
import { Logger, writeError } from '../../shared/Logger'

const checkRefreshTokenArgs = (
  userParam: User,
  authClient: AuthClient,
): void => {
  if (!userParam) {
    throw new Error('can not refresh if user is missing')
  }
  if (!authClient?.refreshToken) {
    throw new Error('can not refresh if refresh token is missing')
  }
  if (!authClient.deviceCode) {
    throw new Error('can not refresh if device code is missing')
  }
}

export type TokenRefreshResult = { authClient: AuthClient; user: User }

export const isReadyForJwtRefresh = (authClient: AuthClient): boolean => {
  if (
    !authClient ||
    !authClient.refreshToken ||
    !authClient.refreshTokenExpiryDate
  ) {
    return false
  }
  const refreshAfterDate = new Date(
    authClient.jwtExpirationDate.valueOf() - 5 * 60 * 1000,
  )
  return new Date() > refreshAfterDate
}

export const isRefreshTokenExpired = (authClient: AuthClient): boolean => {
  if (
    !authClient ||
    !authClient.refreshToken ||
    !authClient.refreshTokenExpiryDate
  ) {
    return true
  }
  return !!(authClient.refreshTokenExpiryDate < new Date())
}

export const isJwtExpired = (authClient: AuthClient): boolean => {
  if (!authClient || !authClient.accessJwt || !authClient.jwtExpirationDate) {
    return true
  }
  return !!(authClient.jwtExpirationDate < new Date())
}

export const refreshUserAuthTokens = async (
  userParam: User,
  authClient: AuthClient,
): Promise<TokenRefreshResult> => {
  checkRefreshTokenArgs(userParam, authClient)

  const request = {
    refreshToken: authClient.refreshToken,
    email: userParam.email,
    deviceCode: authClient.deviceCode,
  }
  let rawResponse: RawAuthorizationResponse
  try {
    rawResponse = await ApiClient.post<RawAuthorizationResponse>(
      identityUrl,
      refreshUserTokenPath,
      request,
      { noAuthorization: true },
    )
  } catch (ex) {
    writeError(
      `something went wrong attempting to refresh user auth tokens: ${(ex as Error).message}`,
    )
    throw ex
  }
  Logger.debug(`got user token refresh response...`)
  const response = buildAuthorizationResponse(rawResponse)
  const { user, jwtExpirationDate } = parseJwt(response.accessJwt)
  const newAuthClient: AuthClient = {
    ...authClient,
    ...response,
    jwtExpirationDate,
  }
  return { authClient: newAuthClient, user }
  // this.OnLogin(authClient, userParam)
}

export const requestRandomHandle = async (): Promise<string> => {
  const response = await get<{ handle: string }>(
    identityUrl,
    guestRandomHandlePath,
    { noAuthorization: true },
  )
  return response.handle
}

export const requestGuestAuthToken = async (
  deviceCode: string | null,
  handle: string,
): Promise<TokenRefreshResult> => {
  if (!deviceCode) {
    throw new Error('can not request auth without deviceCode')
  }
  const request: GuestAuthRequest = {
    deviceCode,
    handle,
  }
  const response = await ApiClient.post<RawAuthorizationResponse>(
    identityUrl,
    guestAuthRequestPath,
    request,
    { noAuthorization: true },
  )
  const { user, jwtExpirationDate } = parseJwt(response.accessJwt)
  const authClient = {
    ...buildAuthorizationResponse(response),
    authSource: AuthSource.guest,
    deviceCode: deviceCode,
    jwtExpirationDate,
  }
  // this.OnLogin(this.authClient, this.user)
  return { user, authClient }
}

export const refreshGuestAuthToken = async (
  userParam: User,
  authClient: AuthClient,
): Promise<TokenRefreshResult> => {
  checkRefreshTokenArgs(userParam, authClient)
  const request = {
    userId: userParam.userId,
    deviceCode: authClient.deviceCode,
    refreshToken: authClient.refreshToken,
  }
  const rawResponse = await post<RawAuthorizationResponse>(
    identityUrl,
    guestRefreshTokenPath,
    request,
    { noAuthorization: true },
  )
  Logger.debug(`got guest token refresh response...`)
  const response = buildAuthorizationResponse(rawResponse)
  const { user, jwtExpirationDate } = parseJwt(response.accessJwt)
  const newAuthClient = { ...authClient, ...response, jwtExpirationDate }
  return { user, authClient: newAuthClient }
  // this.OnLogin(authClient, userParam)
}

export const refreshAuthTokens = async (
  userParam: User | null,
  authClient: AuthClient | null,
): Promise<TokenRefreshResult> => {
  if (!userParam) {
    throw new Error('attempted to refresh without user')
  }
  if (!authClient) {
    throw new Error('attempted to refresh without authClient')
  }
  if (authClient.authSource === AuthSource.guest) {
    return refreshGuestAuthToken(userParam, authClient)
  }
  return refreshUserAuthTokens(userParam, authClient)
}

export const updateUserHandle = async (handle: string): Promise<void> => {
  Logger.warn(`update handle not implemented - handle was ${handle}`)
  return
}

export type IdentityHealthCheckResponse = { health: 'healthy'; info: User }
export type HealthCheckResponse = {
  health: string
  current_timestamp: Date
  environment: string
}

export const getIdentityHealthCheck = async (
  user: User | null,
  jwt: string | null | undefined,
): Promise<IdentityHealthCheckResponse | HealthCheckResponse> => {
  if (!user) {
    Logger.debug(`identity healthcheck failure - without comparison user`)
    throw new Error(`identity healthcheck failure - without comparison user`)
  }
  if (!jwt) {
    Logger.debug(`identity healthcheck failure - without jwt`)
    throw new Error(`identity healthcheck failure - without jwt`)
  }
  try {
    const result = await ApiClient.get<IdentityHealthCheckResponse>(
      identityUrl,
      identityHealthCheckPath,
      { jwt },
    )
    if (result?.info?.userId !== user.userId) {
      Logger.debug(
        `expected user ${user.handle} - ${user.userId} but got ${result?.info?.handle} - ${result?.info?.userId}`,
      )
      throw new Error(
        `identity healthcheck failure - did not find expected user`,
      )
    }
    return result
  } catch (ex) {
    const result: HealthCheckResponse = await getHealthCheck() // throw this if neither succeeds, ie API is down
    return result
    throw new Error(
      `identity healthcheck failure - ${(ex as AxiosError).message}`,
    )
  }
}

export const getHealthCheck = async (): Promise<HealthCheckResponse> => {
  try {
    const healthResponse = await ApiClient.get<HealthCheckResponse>(
      identityUrl,
      healthCheckPath,
      { noAuthorization: true },
    )
    if (!healthResponse?.health) {
      throw new Error('healthcheck failure')
    }
    return healthResponse
  } catch (ex) {
    throw new Error(`healthcheck failure`)
  }
}
