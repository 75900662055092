import { Deck } from './Deck'

export const SmithWaite: Deck = {
  deckName: 'Smith Waite Tarot Deck',
  entity: 'Deck',
  suits: [
    { suitName: 'trumps', suitEmoji: '✨' },
    { suitName: 'pentacles', suitEmoji: '⛥' },
    { suitName: 'cups', suitEmoji: '🍷' },
    { suitName: 'swords', suitEmoji: '🗡️' },
    { suitName: 'wands', suitEmoji: '🪄' },
  ],
  trumpSuitName: 'trumps',
  cards: [
    {
      smallUrl: '/sw/sm/i4Vii8I0LRvs.jpg',
      largeUrl: '',
      cardValue: 0,
      cardValueName: 'The Fool',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '🃏',
    },
    {
      smallUrl: '/sw/sm/0xJV8YjoOdjn.jpg',
      largeUrl: '',
      cardValue: 1,
      cardValueName: 'The Magician',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '🧙',
    },
    {
      smallUrl: '/sw/sm/tPq3F82WgXnT.jpg',
      largeUrl: '',
      cardValue: 2,
      cardValueName: 'The High Priestess',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '👩‍⚖️',
    },
    {
      smallUrl: '/sw/sm/v4Muu7hKU1au.jpg',
      largeUrl: '',
      cardValue: 3,
      cardValueName: 'The Empress',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '👰‍',
    },
    {
      smallUrl: '/sw/sm/20Uni5EEnSxY.jpg',
      largeUrl: '',
      cardValue: 4,
      cardValueName: 'The Emperor',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '👑',
    },
    {
      smallUrl: '/sw/sm/V4Ts9SJNQCzQ.jpg',
      largeUrl: '',
      cardValue: 5,
      cardValueName: 'The Hierophant',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '👨‍🏫',
    },
    {
      smallUrl: '/sw/sm/w6q5nBY9X235.jpg',
      largeUrl: '',
      cardValue: 6,
      cardValueName: 'The Lovers',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '👯‍♀️',
    },
    {
      smallUrl: '/sw/sm/3Os5XobEyqv7.jpg',
      largeUrl: '',
      cardValue: 7,
      cardValueName: 'The Chariot',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '🏇',
    },
    {
      smallUrl: '/sw/sm/5DZ3dqji8yRI.jpg',
      largeUrl: '',
      cardValue: 8,
      cardValueName: 'Strength',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '🦁',
    },
    {
      smallUrl: '/sw/sm/Ua96qzOVKbly.jpg',
      largeUrl: '',
      cardValue: 9,
      cardValueName: 'The Hermit',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '🧓',
    },
    {
      smallUrl: '/sw/sm/Rptj4l7xrk1Q.jpg',
      largeUrl: '',
      cardValue: 10,
      cardValueName: 'The Wheel of Fortune',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '🎰',
    },
    {
      smallUrl: '/sw/sm/5Bw58NbK2vFz.jpg',
      largeUrl: '',
      cardValue: 11,
      cardValueName: 'Justice',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '⚖️',
    },
    {
      smallUrl: '/sw/sm/yC5y7fhdEByW.jpg',
      largeUrl: '',
      cardValue: 12,
      cardValueName: 'The Hanged Man',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '🤸',
    },
    {
      smallUrl: '/sw/sm/97HWjScnPNKj.jpg',
      largeUrl: '',
      cardValue: 13,
      cardValueName: 'Death',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '💀',
    },
    {
      smallUrl: '/sw/sm/d81Gzc1zRu2z.jpg',
      largeUrl: '',
      cardValue: 14,
      cardValueName: 'Temperance',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '🌊',
    },
    {
      smallUrl: '/sw/sm/7G5VgqEzQ9aM.jpg',
      largeUrl: '',
      cardValue: 15,
      cardValueName: 'The Devil',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '😈',
    },
    {
      smallUrl: '/sw/sm/s00VCEIsyGnE.jpg',
      largeUrl: '',
      cardValue: 16,
      cardValueName: 'The Tower',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '🏢',
    },
    {
      smallUrl: '/sw/sm/l59QrCsXdCGa.jpg',
      largeUrl: '',
      cardValue: 17,
      cardValueName: 'The Star',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '🌟',
    },
    {
      smallUrl: '/sw/sm/7x7A8sREKkab.jpg',
      largeUrl: '',
      cardValue: 18,
      cardValueName: 'The Moon',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '🌒',
    },
    {
      smallUrl: '/sw/sm/Wo435nRGOs6v.jpg',
      largeUrl: '',
      cardValue: 19,
      cardValueName: 'The Sun',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '☀️',
    },
    {
      smallUrl: '/sw/sm/SgU3D87kOK04.jpg',
      largeUrl: '',
      cardValue: 20,
      cardValueName: 'Judgement',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '📯',
    },
    {
      smallUrl: '/sw/sm/qG06f7Z7Sjin.jpg',
      largeUrl: '',
      cardValue: 21,
      cardValueName: 'The World',
      suit: { suitName: 'trumps', suitEmoji: '✨' },
      cardEmoji: '🌍',
    },
    {
      smallUrl: '/sw/sm/Cq20XQ1LujmV.jpg',
      largeUrl: '',
      cardValue: 2,
      cardValueName: 'two',
      suit: { suitName: 'swords', suitEmoji: '🗡️' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/0Pa46indePXW.jpg',
      largeUrl: '',
      cardValue: 3,
      cardValueName: 'three',
      suit: { suitName: 'swords', suitEmoji: '🗡️' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/Ob82Y6VTazEe.jpg',
      largeUrl: '',
      cardValue: 4,
      cardValueName: 'four',
      suit: { suitName: 'swords', suitEmoji: '🗡️' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/jO9Vs9LmZD2x.jpg',
      largeUrl: '',
      cardValue: 5,
      cardValueName: 'five',
      suit: { suitName: 'swords', suitEmoji: '🗡️' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/F48jWJw6EoBi.jpg',
      largeUrl: '',
      cardValue: 6,
      cardValueName: 'six',
      suit: { suitName: 'swords', suitEmoji: '🗡️' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/Ups93ZCKARJD.jpg',
      largeUrl: '',
      cardValue: 7,
      cardValueName: 'seven',
      suit: { suitName: 'swords', suitEmoji: '🗡️' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/Vh78iNpCiJO7.jpg',
      largeUrl: '',
      cardValue: 8,
      cardValueName: 'eight',
      suit: { suitName: 'swords', suitEmoji: '🗡️' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/4M5tY3uEsTeU.jpg',
      largeUrl: '',
      cardValue: 9,
      cardValueName: 'nine',
      suit: { suitName: 'swords', suitEmoji: '🗡️' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/l6FY6ISPUcTG.jpg',
      largeUrl: '',
      cardValue: 10,
      cardValueName: 'ten',
      suit: { suitName: 'swords', suitEmoji: '🗡️' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/5idMzSOy89rz.jpg',
      largeUrl: '',
      cardValue: 11,
      cardValueName: 'page',
      suit: { suitName: 'swords', suitEmoji: '🗡️' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/lA7Gl7V3GNZr.jpg',
      largeUrl: '',
      cardValue: 12,
      cardValueName: 'knight',
      suit: { suitName: 'swords', suitEmoji: '🗡️' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/5iMiL0ys3Z2D.jpg',
      largeUrl: '',
      cardValue: 13,
      cardValueName: 'queen',
      suit: { suitName: 'swords', suitEmoji: '🗡️' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/1tZk0UbCKTnr.jpg',
      largeUrl: '',
      cardValue: 14,
      cardValueName: 'king',
      suit: { suitName: 'swords', suitEmoji: '🗡️' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/Z7qn786YdbLv.jpg',
      largeUrl: '',
      cardValue: 15,
      cardValueName: 'ace',
      suit: { suitName: 'swords', suitEmoji: '🗡️' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/2Ad3EeuNpfk8.jpg',
      largeUrl: '',
      cardValue: 2,
      cardValueName: 'two',
      suit: { suitName: 'pentacles', suitEmoji: '⛥' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/wRqDD21fULaH.jpg',
      largeUrl: '',
      cardValue: 3,
      cardValueName: 'three',
      suit: { suitName: 'pentacles', suitEmoji: '⛥' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/Vz869fBVLKKw.jpg',
      largeUrl: '',
      cardValue: 4,
      cardValueName: 'four',
      suit: { suitName: 'pentacles', suitEmoji: '⛥' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/b5X4EHtUco1x.jpg',
      largeUrl: '',
      cardValue: 5,
      cardValueName: 'five',
      suit: { suitName: 'pentacles', suitEmoji: '⛥' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/8vHG6GvOOCT8.jpg',
      largeUrl: '',
      cardValue: 6,
      cardValueName: 'six',
      suit: { suitName: 'pentacles', suitEmoji: '⛥' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/S9s10WtrwjPF.jpg',
      largeUrl: '',
      cardValue: 7,
      cardValueName: 'seven',
      suit: { suitName: 'pentacles', suitEmoji: '⛥' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/3r1FROk85gqF.jpg',
      largeUrl: '',
      cardValue: 8,
      cardValueName: 'eight',
      suit: { suitName: 'pentacles', suitEmoji: '⛥' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/i0HR7w68vUfv.jpg',
      largeUrl: '',
      cardValue: 9,
      cardValueName: 'nine',
      suit: { suitName: 'pentacles', suitEmoji: '⛥' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/Cy98FT9iwliD.jpg',
      largeUrl: '',
      cardValue: 10,
      cardValueName: 'ten',
      suit: { suitName: 'pentacles', suitEmoji: '⛥' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/9vZt48lHXYcc.jpg',
      largeUrl: '',
      cardValue: 11,
      cardValueName: 'page',
      suit: { suitName: 'pentacles', suitEmoji: '⛥' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/4rH0GVSjhRWX.jpg',
      largeUrl: '',
      cardValue: 12,
      cardValueName: 'knight',
      suit: { suitName: 'pentacles', suitEmoji: '⛥' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/2MEm2NZITvD6.jpg',
      largeUrl: '',
      cardValue: 13,
      cardValueName: 'queen',
      suit: { suitName: 'pentacles', suitEmoji: '⛥' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/4Je7IkyePJ04.jpg',
      largeUrl: '',
      cardValue: 14,
      cardValueName: 'king',
      suit: { suitName: 'pentacles', suitEmoji: '⛥' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/l2mHC7j44Ng7.jpg',
      largeUrl: '',
      cardValue: 15,
      cardValueName: 'ace',
      suit: { suitName: 'pentacles', suitEmoji: '⛥' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/Hy244dlKVQ1r.jpg',
      largeUrl: '',
      cardValue: 2,
      cardValueName: 'two',
      suit: { suitName: 'wands', suitEmoji: '🪄' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/4gk6FMESnCwI.jpg',
      largeUrl: '',
      cardValue: 3,
      cardValueName: 'three',
      suit: { suitName: 'wands', suitEmoji: '🪄' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/o1Wa0hutmduk.jpg',
      largeUrl: '',
      cardValue: 4,
      cardValueName: 'four',
      suit: { suitName: 'wands', suitEmoji: '🪄' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/8C37MYxiSQ4R.jpg',
      largeUrl: '',
      cardValue: 5,
      cardValueName: 'five',
      suit: { suitName: 'wands', suitEmoji: '🪄' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/rf7wXs28O2tB.jpg',
      largeUrl: '',
      cardValue: 6,
      cardValueName: 'six',
      suit: { suitName: 'wands', suitEmoji: '🪄' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/Mu641ei6VaZJ.jpg',
      largeUrl: '',
      cardValue: 7,
      cardValueName: 'seven',
      suit: { suitName: 'wands', suitEmoji: '🪄' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/z1HY22q3QViO.jpg',
      largeUrl: '',
      cardValue: 8,
      cardValueName: 'eight',
      suit: { suitName: 'wands', suitEmoji: '🪄' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/Fd76MUbNGcMS.jpg',
      largeUrl: '',
      cardValue: 9,
      cardValueName: 'nine',
      suit: { suitName: 'wands', suitEmoji: '🪄' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/yA9046MZ3WIh.jpg',
      largeUrl: '',
      cardValue: 10,
      cardValueName: 'ten',
      suit: { suitName: 'wands', suitEmoji: '🪄' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/S0cAj0RRH1d5.jpg',
      largeUrl: '',
      cardValue: 11,
      cardValueName: 'page',
      suit: { suitName: 'wands', suitEmoji: '🪄' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/7yHQ03zB0ClN.jpg',
      largeUrl: '',
      cardValue: 12,
      cardValueName: 'knight',
      suit: { suitName: 'wands', suitEmoji: '🪄' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/sMX71FTI3KR7.jpg',
      largeUrl: '',
      cardValue: 13,
      cardValueName: 'queen',
      suit: { suitName: 'wands', suitEmoji: '🪄' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/uNT2t2618YPU.jpg',
      largeUrl: '',
      cardValue: 14,
      cardValueName: 'king',
      suit: { suitName: 'wands', suitEmoji: '🪄' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/Hs9lBj1GhLpR.jpg',
      largeUrl: '',
      cardValue: 15,
      cardValueName: 'ace',
      suit: { suitName: 'wands', suitEmoji: '🪄' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/7Zlkx8w6XQB3.jpg',
      largeUrl: '',
      cardValue: 2,
      cardValueName: 'two',
      suit: { suitName: 'cups', suitEmoji: '🍷' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/61bWTi1YhCfk.jpg',
      largeUrl: '',
      cardValue: 3,
      cardValueName: 'three',
      suit: { suitName: 'cups', suitEmoji: '🍷' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/K76iuBhLBSjt.jpg',
      largeUrl: '',
      cardValue: 4,
      cardValueName: 'four',
      suit: { suitName: 'cups', suitEmoji: '🍷' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/Db7TV56MHYVz.jpg',
      largeUrl: '',
      cardValue: 5,
      cardValueName: 'five',
      suit: { suitName: 'cups', suitEmoji: '🍷' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/6Y6Hg7BSafKC.jpg',
      largeUrl: '',
      cardValue: 6,
      cardValueName: 'six',
      suit: { suitName: 'cups', suitEmoji: '🍷' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/G5E5o5wyGQll.jpg',
      largeUrl: '',
      cardValue: 7,
      cardValueName: 'seven',
      suit: { suitName: 'cups', suitEmoji: '🍷' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/2iO6LECaUqOu.jpg',
      largeUrl: '',
      cardValue: 8,
      cardValueName: 'eight',
      suit: { suitName: 'cups', suitEmoji: '🍷' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/At9WZvqn9c4K.jpg',
      largeUrl: '',
      cardValue: 9,
      cardValueName: 'nine',
      suit: { suitName: 'cups', suitEmoji: '🍷' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/9XyOxk32mv5O.jpg',
      largeUrl: '',
      cardValue: 10,
      cardValueName: 'ten',
      suit: { suitName: 'cups', suitEmoji: '🍷' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/2l2PIf6YU6aV.jpg',
      largeUrl: '',
      cardValue: 11,
      cardValueName: 'page',
      suit: { suitName: 'cups', suitEmoji: '🍷' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/BiJ07LVV6cFC.jpg',
      largeUrl: '',
      cardValue: 12,
      cardValueName: 'knight',
      suit: { suitName: 'cups', suitEmoji: '🍷' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/37bUWqZo1Lyi.jpg',
      largeUrl: '',
      cardValue: 13,
      cardValueName: 'queen',
      suit: { suitName: 'cups', suitEmoji: '🍷' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/7a8Y4zIK6v5v.jpg',
      largeUrl: '',
      cardValue: 14,
      cardValueName: 'king',
      suit: { suitName: 'cups', suitEmoji: '🍷' },
      cardEmoji: undefined,
    },
    {
      smallUrl: '/sw/sm/V72yPJZVP6dN.jpg',
      largeUrl: '',
      cardValue: 15,
      cardValueName: 'ace',
      suit: { suitName: 'cups', suitEmoji: '🍷' },
      cardEmoji: undefined,
    },
  ],
}
