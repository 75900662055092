import { GameRuleSets } from '../shared'
import { Game } from './Game'

export type GameSummary = {
  gameId: string
  description: string
  hostName: string
  hostUserId: string
  createDate: Date
  status: string
  playerCount: number
  maxPlayers: number
  gameRuleSetName: GameRuleSets
}

export const buildGameSummary = (game: Game): GameSummary => ({
  gameId: game.gameId,
  description: game.gameRuleSetName,
  hostName: game.hostHandle,
  hostUserId: game.hostUserId,
  createDate: game.createDate,
  status: game.status,
  playerCount: game.players.length,
  maxPlayers: game.maxPlayers,
  gameRuleSetName: game.gameRuleSetName,
})
