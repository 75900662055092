import React, {
  FC,
  MouseEventHandler,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useLocation } from 'react-router-dom'
import { AppContext } from '../AppContext'
import { Game, GameRuleSets, GameStatus, WinThisGameState } from '@ohell/games'
import { buildStartCmd } from '../services/GameCmdFactory/BaseGameCmdFactory'
import { buildWinCmd } from '../services/GameCmdFactory/WinThisGameCmdFactory'
import {
  ConnectionContext,
  jwtSelector,
  userSelector,
} from '../services/Connections/connectionState'
import { GameSvc } from '../services/GameSvc'
import {
  InboundMessage,
  MessageBusClient,
  buildMessageBusClientId,
  defaultChannels,
} from '../services/Connections/socket'
import { Logger } from '../shared/Logger'
import { NoProps } from '../shared/types'

type stateType = {
  winnerUserId: string | undefined
  winnerUserHandle: string | undefined
  hostUserId: string | undefined
  hostHandle: string | undefined
  gameStatus: GameStatus
  gameId: string | undefined
}

const gameRuleSetName = GameRuleSets.WinThisGame

const Win: FC<NoProps> = () => {
  const context = useContext(AppContext)
  const connection = ConnectionContext.useActorRef()
  const user = ConnectionContext.useSelector(userSelector)
  const jwt = ConnectionContext.useSelector(jwtSelector)
  const location = useLocation()

  const [state, setState] = useState<stateType>(location.state as stateType)
  const gameId = state.gameId

  const gameMessageClientId = gameId
    ? buildMessageBusClientId(defaultChannels.game, gameId)
    : ''

  const setStatusFromGame = useCallback((game: Game) => {
    const gameState: WinThisGameState = game?.gameState as WinThisGameState
    type ScoreType = { playerId: string; score: number }
    const score = gameState?.score || []
    const newState: stateType = {
      winnerUserHandle: undefined,
      winnerUserId: undefined,
      gameStatus: game.status,
      hostUserId: game.hostUserId,
      hostHandle: game.hostHandle,
      gameId: game.gameId,
    }
    if (GameStatus.Complete && score.length) {
      const highScoringPlayerScore = score.reduce(
        (winner: ScoreType, next: ScoreType) => {
          return !winner || winner.score > next.score ? winner : next
        },
        score[0],
      )
      const highScoringPlayer = game.players.find(
        (player) => player.userId === highScoringPlayerScore.playerId,
      )
      if (game.status === GameStatus.Complete) {
        Logger.debug(`highscoring player score is ${JSON.stringify(highScoringPlayerScore)}
                    with player ${JSON.stringify(highScoringPlayer)}`)
        Logger.debug(`all players were ${JSON.stringify(game.players)}`)
      }
      newState.winnerUserHandle = highScoringPlayer?.handle || undefined
      newState.winnerUserId = highScoringPlayer?.userId || undefined
    }

    setState(newState)
  }, [])

  const onGameMessage = useCallback(
    (message: InboundMessage) => {
      const gameMessage =
        message.channel === defaultChannels.game
          ? (message.data as Game)
          : undefined
      if (!gameMessage) {
        // TODO this check should happen in subscription
        return
      }
      Logger.debug(`got game message ${JSON.stringify(gameMessage)}`)
      setStatusFromGame(gameMessage)
    },
    [setStatusFromGame],
  )

  useEffect(() => {
    if (gameId && !state.gameStatus) {
      GameSvc.getGame(gameId, { jwt }).then((game) => {
        setStatusFromGame(game)
      })
    }
  }, [jwt])

  useEffect(() => {
    if (!gameId) {
      return () => {}
    }
    Logger.debug('subscribing to game')
    const client: MessageBusClient = {
      clientId: gameMessageClientId,
      callback: onGameMessage,
      channels: [{ channel: defaultChannels.game, topic: gameId }],
    }
    connection.send({ type: 'SOCKET_SUBSCRIBE', value: client })
    return () => {
      Logger.debug('unsubscribing to game')
      connection.send({
        type: 'SOCKET_UNSUBSCRIBE',
        value: gameMessageClientId,
      })
    }
  }, [context, onGameMessage, gameMessageClientId])

  if (!gameId) {
    return (
      <div>
        <h1>oh weird</h1>
        <p>
          not sure what happened, but it's not clear which game you're trying to
          play
        </p>
        <p>maybe hit up the lobby, i guess</p>
      </div>
    )
  }

  const handleStartGame: MouseEventHandler<HTMLButtonElement> = async (
    e: React.MouseEvent<HTMLElement>,
  ) => {
    e.preventDefault()
    const cmd = buildStartCmd({ gameId, gameRuleSetName })
    await GameSvc.sendCmd(cmd, { jwt })
    // const game = await GameSvc.getGame(gameId, { jwt })
    // setStatusFromGame(game)
  }

  const handleWinGame: MouseEventHandler<HTMLButtonElement> = async (
    e: React.MouseEvent<HTMLElement>,
  ) => {
    e.preventDefault()
    const cmd = buildWinCmd({ gameId })

    await GameSvc.sendCmd(cmd, { jwt })
    // const game = await GameSvc.getGame(gameId, { jwt })
    // setStatusFromGame(game)
  }
  return (
    <div>
      <h1>WIN GAME {gameId}</h1>
      {state.gameStatus === GameStatus.Ready &&
        user.userId !== state.hostUserId && (
          <div key="wait for host" className="text-center text-xl m-5">
            <span>waiting for {state.hostHandle} to start the game</span>
          </div>
        )}
      {state.gameStatus === GameStatus.Ready &&
        user.userId === state.hostUserId && (
          <div key="start button" className="text-center text-xl m-5">
            <button
              className="p-3 m-3 text-larger border-black border-2 text-center"
              type="submit"
              onClick={handleStartGame}
            >
              start the game
            </button>
          </div>
        )}
      {state.gameStatus === GameStatus.InProgress && (
        <div className="text-center text-xl m-5">
          <div key="instructions">
            <p>to push is to win</p>
          </div>
          <div key="win button">
            <button
              className="p-3 m-3 text-larger border-black border-2 text-center"
              type="submit"
              onClick={handleWinGame}
            >
              PUSH BUTTON
            </button>
          </div>
        </div>
      )}
      {state.gameStatus === GameStatus.Complete && (
        <div key="congrats" className="text-center text-xl m-5">
          <span role="img" aria-label="PARTY PARTY PARTY">
            🎉🎉🎉🎉🎉🎉🎉🎉
          </span>
          <h1>winner is {state.winnerUserHandle}</h1>
          <span role="img" aria-label="PARTY PARTY PARTY">
            🎉🎉🎉🎉🎉🎉🎉🎉
          </span>
        </div>
      )}
      <div></div>
    </div>
  )
}
export default Win
// gameplay component for win-this-game
