import { GamePlayer } from '../../base/GamePlayer'
import { Deck, DeckCode, DeckOrder, shuffle, SmithWaite } from '../../shared'
import { initRound } from '../state/round'
import { OhHellInProgress } from '../state/state'

export type OhHellOptions = {
  rounds: number
  deckCode: DeckCode
}

export const defaultOptions: OhHellOptions = {
  rounds: 12,
  deckCode: DeckCode.TarotSW,
}

export function init(
  players: GamePlayer[],
  hostId: string,
  options: OhHellOptions = defaultOptions,
): OhHellInProgress {
  const deckCode = options.deckCode
  return {
    deckCode: deckCode,
    players,
    roundsToPlay: options.rounds,
    completedRounds: [],
    currentRound: initRound(deckCode, players, 1, hostId),
  }
}
