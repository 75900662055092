import { BaseGameCmdTypes, GameRuleSets } from '@ohell/games'
import { GameCmd } from '../../domain/GameCmd'
import { v4 } from 'uuid'

export const buildJoinCmd = (options: {
  gameId: string
  gameRuleSetName: GameRuleSets
}): GameCmd => {
  return {
    cmdId: v4(),
    gameId: options.gameId,
    gameRuleSetName: options.gameRuleSetName,
    cmdType: BaseGameCmdTypes.Join,
    cmdData: {},
  }
}

export const buildStartCmd = (options: {
  gameId: string
  gameRuleSetName: GameRuleSets
}): GameCmd => {
  return {
    cmdId: v4(),
    gameId: options.gameId,
    gameRuleSetName: options.gameRuleSetName,
    cmdType: BaseGameCmdTypes.Start,
    cmdData: {},
  }
}

export const buildExitCmd = (options: {
  gameId: string
  gameRuleSetName: GameRuleSets
}): GameCmd => {
  return {
    cmdId: v4(),
    gameId: options.gameId,
    gameRuleSetName: options.gameRuleSetName,
    cmdType: BaseGameCmdTypes.Exit,
    cmdData: {},
  }
}
