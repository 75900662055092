import {
  BaseGameCmdTypes,
  baseHandlers,
  Game,
  GameCmd,
  GameCmdHandler,
  GameCmdResult,
  GameEvent,
} from './base/Game'
import { GamePlayer } from './base/GamePlayer'
import { OhHellCmdType, OhHellHandlers } from './ohHell/OhHell'
import { GameRuleSets } from './shared'
import { WinGameCmdTypes, winGameHandlers } from './winThisGame/WinThisGame'

const gameCmdHandlers: Record<string, Record<string, GameCmdHandler>> = {
  [GameRuleSets.Base]: {
    [BaseGameCmdTypes.Create]: (prev, cmdData) => ({ game: prev, events: [] }),
    [BaseGameCmdTypes.Start]: baseHandlers.startGame,
    [BaseGameCmdTypes.Join]: baseHandlers.joinGame,
    [BaseGameCmdTypes.GetPlayerState]: baseHandlers.getPlayerState,
    [BaseGameCmdTypes.Exit]: baseHandlers.exitGame,
    [BaseGameCmdTypes.Error]: (prevGame, cmdData) => {
      throw new Error(
        `Error command is not implemented. Command data was ${cmdData}`,
      )
    },
  } as Record<BaseGameCmdTypes, GameCmdHandler>,
  [GameRuleSets.WinThisGame]: {
    [WinGameCmdTypes.Win]: winGameHandlers.winGame,
  } as Record<WinGameCmdTypes, GameCmdHandler>,
  [GameRuleSets.OhHell]: {
    [OhHellCmdType.Start]: OhHellHandlers.start,
    [OhHellCmdType.GetPlayerState]: OhHellHandlers.getPlayerState,
    [OhHellCmdType.DealCards]: OhHellHandlers.dealCards,
    [OhHellCmdType.PlaceBid]: OhHellHandlers.placeBid,
    [OhHellCmdType.PlayCard]: OhHellHandlers.playCard,
  } as Record<OhHellCmdType, GameCmdHandler>,
}

const getBaseGameCmdResult = (
  prevGame: Game,
  gameCmd: GameCmd,
): GameCmdResult => {
  if (!gameCmdHandlers[GameRuleSets.Base][gameCmd.cmdType]) {
    return { game: prevGame, events: [] }
  }
  return gameCmdHandlers[GameRuleSets.Base][gameCmd.cmdType](prevGame, gameCmd)
}

export const buildPlayerStateCmd = (
  game: Game,
  player: GamePlayer,
): GameCmd => ({
  player,
  cmdId: '',
  gameId: game.gameId,
  gameRuleSetName: game.gameRuleSetName,
  cmdType: BaseGameCmdTypes.GetPlayerState,
  cmdData: {},
})

export const getPlayerState = (game: Game, player: GamePlayer): Game => {
  const cmd: GameCmd = buildPlayerStateCmd(game, player)
  const result = processGameCommand(game, cmd)
  if (!result.game) {
    throw new Error(
      'Expected to get a game on the response for get player state command',
    )
  }
  return result.game
}

export const processGameCommand = (
  prevGame: Game,
  gameCmd: GameCmd,
): GameCmdResult => {
  const gameRuleSetName: string = gameCmd.gameRuleSetName || ''
  const gameCmdHandler = gameCmdHandlers[gameRuleSetName]
  if (!gameCmdHandler) {
    throw new Error(`processGameCommand - Configuration error.  Game ruleset not supported.
          Supported rulesets are ${JSON.stringify(Object.keys(gameCmdHandlers))}`)
  }
  if (gameRuleSetName !== prevGame.gameRuleSetName) {
    throw new Error(
      `attempted to use cmd for ${gameRuleSetName} when game was ${prevGame.gameRuleSetName}`,
    )
  }

  const baseHandler = gameCmdHandlers.Base[gameCmd.cmdType]
  const handler = gameCmdHandler[gameCmd.cmdType]
  if (!handler && !baseHandler) {
    throw new Error(
      'getGameProcessor - Configuration error.  Game command not supported for ruleset.',
    )
  }

  const baseResult = getBaseGameCmdResult(prevGame, gameCmd)
  if (gameRuleSetName === GameRuleSets.Base || !baseResult.game || !handler) {
    return baseResult
  }
  return handler(baseResult.game, gameCmd)
}
