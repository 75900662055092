import React, {
  FC,
  useContext,
  useReducer,
  useRef,
  FormEvent,
  useCallback,
  useEffect,
} from 'react'
import { AppContext } from '../AppContext'
import { ChatMessage, ChatFunctions } from '../services/Chat'
import {
  ConnectionContext,
  jwtSelector,
  userSelector,
} from '../services/Connections/connectionState'
import {
  InboundMessage,
  MessageBusClient,
  OutboundMessage,
  buildMessageBusClientId,
  defaultChannels,
} from '../services/Connections/socket'
import { Logger } from '../shared/Logger'

enum chatLogReducerOptions {
  add = 'add',
  replace = 'replace',
}

type Action = {
  type: string
  messages: ChatMessage[]
}

type State = {
  messages: ChatMessage[]
}
const initialState: State = {
  messages: [],
}

type Props = {
  chatRoomId: string
  chatroomName: string
}

const chatLogReducer = (state: State, action: Action): State => {
  Logger.debug(`chatbox reducer executing`)
  switch (action.type) {
    case chatLogReducerOptions.add: {
      const lastTenChats = state.messages.slice(-99)
      const nextChat = action.messages[0]
      const nextState = { ...state, messages: [...lastTenChats, nextChat] }
      Logger.debug(`chatbox newState ${JSON.stringify(nextState)}`)
      return nextState
    }
    case chatLogReducerOptions.replace: {
      const lastTenChats = action.messages.slice(-99)
      const nextState = { ...state, messages: [...lastTenChats] }
      return nextState
    }
    default: {
      Logger.debug('invalid reducer action in chatLogReducer')
      return state
    }
  }
}

const ChatBox: FC<Props> = ({ chatRoomId, chatroomName }) => {
  const chatBoxClientId = buildMessageBusClientId(
    defaultChannels.chat,
    chatRoomId,
  )

  Logger.debug(
    `chatbot entry with props ${JSON.stringify({ chatRoomId, chatroomName })}`,
  )
  const [chatLogState, chatLogDispatch] = useReducer(
    chatLogReducer,
    initialState,
  )
  const context = useContext(AppContext)
  const user = ConnectionContext.useSelector(userSelector)
  const jwt = ConnectionContext.useSelector(jwtSelector)
  const connection = ConnectionContext.useActorRef()
  const onMessage = useCallback(
    (message: InboundMessage) => {
      const chatMessage =
        ChatFunctions.convertInboundMessageToChatMessage(message)
      if (!chatMessage) {
        return
      }
      Logger.debug(`chatbox calling dispatch with ${JSON.stringify(message)}`)
      const action: Action = {
        type: chatLogReducerOptions.add,
        messages: [chatMessage],
      }
      chatLogDispatch(action)
    },
    [chatLogDispatch],
  )

  const lastItemRef = useRef<HTMLLIElement>(null)
  useEffect(() => {
    lastItemRef?.current?.scrollIntoView({ behavior: 'smooth' })
  })

  useEffect(() => {
    const client: MessageBusClient = {
      clientId: chatBoxClientId,
      callback: onMessage,
      channels: [{ channel: defaultChannels.chat, topic: chatRoomId }],
    }
    connection.send({ type: 'SOCKET_SUBSCRIBE', value: client })
    return () => {
      connection.send({ type: 'SOCKET_UNSUBSCRIBE', value: chatBoxClientId })
    }
  }, [context, onMessage])

  useEffect(() => {
    ChatFunctions.getChatRoomMessages({ jwt }, chatRoomId).then(
      (chatMessages) => {
        const action = {
          type: chatLogReducerOptions.replace,
          messages: chatMessages,
        }
        chatLogDispatch(action)
      },
    )
  }, [context, onMessage])

  const textEntryField = useRef<HTMLInputElement>(null)

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const text = textEntryField?.current?.value || ''
    const chatMessage: OutboundMessage = ChatFunctions.buildOutboundChatMessage(
      text,
      chatRoomId,
      user.userId,
      user.handle,
      jwt,
    )
    Logger.debug(`sending message ${text} from chatbox`)
    connection.send({ type: 'SEND', value: chatMessage })
    textEntryField.current && (textEntryField.current.value = '')
  }

  return (
    <div className="bg-classicgray-dark m-3 p-2 pb-1 md:p-6 w-full md:w-6/12 max-w-md rounded-md flex-col content-between">
      <h1>chat</h1>
      <div
        className={`h-90v md:h-60v text-left overflow-y-auto md:overflow-y-scroll scrollbar justify-start items-center flex-col`}
      >
        {chatLogState.messages.map(getChatline)}
        <span ref={lastItemRef}></span>
      </div>
      <form className="p-2 pb-0" onSubmit={handleSubmit}>
        <input
          className="mt-3 w-full"
          type="text"
          placeholder="what's on your mind, friend?"
          id="customTextField"
          ref={textEntryField}
        />
      </form>
    </div>
  )
}

function getChatline(message: ChatMessage, index: number) {
  return (
    <div className="w-full" key={'chatline-' + index}>
      <span className="bg-white text-xs font-bold">{message.displayName}</span>
      <span className="text-xs font-light">
        {' '}
        {message.timeReceived.toLocaleTimeString(undefined, {
          hour: '2-digit',
          minute: '2-digit',
        })}
      </span>
      <p>{message.text}</p>
    </div>
  )
}

export default ChatBox
