import { DeckCode, GameRuleSets } from '@ohell/games'
import { GameCmd } from '../../domain/GameCmd'
import { buildStartCmd } from './BaseGameCmdFactory'

export const buildPlayCardCmd = () => {}

export const buildOhHellStartCmd = (options: {
  gameId: string
  gameRuleSetName: GameRuleSets
  deckCode: DeckCode
  roundsToPlay: number | null
}): GameCmd => {
  const cmd = buildStartCmd({
    gameId: options.gameId,
    gameRuleSetName: options.gameRuleSetName,
  })
  const cmdData: { deckCode?: DeckCode; rounds?: number } = {}
  if (options.deckCode) {
    cmdData.deckCode = options.deckCode
  }
  if (options.roundsToPlay) {
    cmdData.rounds = options.roundsToPlay
  }
  return {
    ...cmd,
    cmdData,
  }
}
