import { ConnectionContext } from '../services/Connections/connectionState'
import { Logger, getLogLevel } from './Logger'

declare global {
  interface Window {
    help: () => void
    logLevel: (level: string) => void
    logState: () => void
    Logger: Logger
  }
}

const helpMessage = `
help(): display this message
logLevel('DEBUG' | 'INFO' | 'WARN' | 'ERROR')
logstate(): output current statemachine state
`

export const setWindowFunctions = (state: {
  context: ConnectionContext
  value: unknown
}) => {
  window.help = () => console.info(helpMessage)
  window.logLevel = (level: string) => (Logger.level = getLogLevel(level))
  window.logState = () =>
    console.info({
      context: state.context,
      value: state.value,
    })
  window.Logger = Logger
}
