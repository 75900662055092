import React, { FC, useState } from 'react'
import { Route, NavLink, Routes, Navigate } from 'react-router-dom'
import Lobby from './pages/Lobby'
import Profile from './pages/Profile'
import Nothing from './pages/Nothing'
import Win from './pages/Win'
import { AppContext, initialAppContextValue } from './AppContext'
import RequireAuth from './components/routes/RequireAuth'
import Login from './pages/Login'
import Logout from './pages/Logout'
import HostGame from './pages/HostGame'
import OhHell from './pages/OhHell/OhHell'
import { ConnectionContext } from './services/Connections/connectionState'
import AuthSpinner from './widgets/AuthSpinner'
import { setWindowFunctions } from './shared/windowCommands'
import { Logger } from './shared/Logger'
import { NoProps } from './shared/types'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const globalNavWait = 30

type TopState = {
  authSpinner: string | null
  globalSubscribeComplete: boolean
}

const initialState = {
  authSpinner: 'refreshing tokens...',
  globalSubscribeComplete: false,
}

function App() {
  Logger.debug('app render')
  const [state] = useState<TopState>(initialState)
  Logger.debug(`app render - state is ${state.authSpinner}`)

  return (
    <AppContext.Provider value={initialAppContextValue}>
      <ConnectionContext.Provider>
        <TopLevel></TopLevel>
      </ConnectionContext.Provider>
    </AppContext.Provider>
  )
}

const TopLevel: FC<NoProps> = () => {
  const offlineStates = [
    'auth.awaiting_jwt_refresh',
    'auth.validity_check_error',
    'auth.awaiting_validity_check',
    'auth.awaiting_device_code',
    'auth.disconnected',
  ]

  const cnState = ConnectionContext.useSelector((state) => state)
  setWindowFunctions(cnState)
  return (
    <div className="App bg-classicgray">
      <ToastContainer />
      <div className="mb-1 mt-px">
        <NavLink className="m-1 px-2 border border-black" to="/">
          lobby
        </NavLink>
        <NavLink className="m-1 px-2 border border-black" to="/profile">
          profile
        </NavLink>
        <NavLink className="m-1 px-2 border border-black" to="/nowhere">
          nowhere
        </NavLink>
        <NavLink
          className="m-1 px-2 border border-black float-right"
          to="/logout"
        >
          logout
        </NavLink>
      </div>
      {(offlineStates.some((item) => cnState.matches(item)) && (
        <AuthSpinner></AuthSpinner>
      )) || (
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Lobby />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            path="/host"
            element={
              <RequireAuth>
                <HostGame />
              </RequireAuth>
            }
          />
          <Route
            path="/win/:gameId"
            element={
              <RequireAuth>
                <Win />
              </RequireAuth>
            }
          />
          <Route
            path="/win"
            element={
              <RequireAuth>
                <Win />
              </RequireAuth>
            }
          />
          <Route
            path="/ohhell/:gameId"
            element={
              <RequireAuth>
                <OhHell />
              </RequireAuth>
            }
          />
          <Route
            path="/ohhell"
            element={
              <RequireAuth>
                <OhHell />
              </RequireAuth>
            }
          />
          <Route path="/default" element={<Navigate to={'/'} />} />
          <Route path="*" element={<Nothing />} />
        </Routes>
      )}
    </div>
  )
}

export default App
