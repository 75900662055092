import { GamePlayer } from '../../base/GamePlayer'
import { DeckCode } from '../../shared'
import {
  CompleteRound,
  PlacedBid,
  Round,
  RoundToPlay,
  TrumpSelection,
} from './round'
import {
  isInProgress,
  OhHellCmdType,
  OhHellComplete,
  OhHellState,
} from './state'
import { CompletedTrick, getPlayerHand, Hand, Trick } from './trick'

export interface OhHellPlayerState {
  players: GamePlayer[]
  roundsToPlay: number
  completedRounds: CompleteRound[]
  currentRound: PlayerRound
  playerId: string
}

export interface PlayerRound extends TrumpSelection, RoundToPlay {
  players: GamePlayer[]
  dealerPlayerId: string
  turnPlayerId: string
  bids: PlacedBid[]
  hand: Hand
  trickWinnerIds: string[]
  completedTricks: CompletedTrick[]
  currentTrick: Trick | undefined
  readyForCommands: OhHellCmdType[]
}

export function getOhHellPlayerState(
  state: OhHellState,
  playerId: string,
): OhHellPlayerState | OhHellComplete {
  if (!isInProgress(state)) {
    return state as OhHellComplete
  }
  const currentRound = getPlayerRound(state.currentRound, playerId)
  return {
    players: state.players,
    roundsToPlay: state.roundsToPlay,
    completedRounds: state.completedRounds,
    currentRound,
    playerId,
  }
}

export function isPlayerState(
  state: OhHellPlayerState | OhHellComplete,
): state is OhHellPlayerState {
  return !!(state as OhHellPlayerState).playerId
}

export function getPlayerRound(round: Round, playerId: string): PlayerRound {
  const hand = getPlayerHand(round.hands, playerId)
  const trickWinnerIds = round.completedTricks.map((trick) => trick.winnerId)
  return {
    deckCode: round.deckCode,
    players: round.players,
    dealerPlayerId: round.dealerPlayerId,
    turnPlayerId: round.turnPlayerId,
    bids: round.bids,
    hand,
    trickWinnerIds,
    completedTricks: round.completedTricks,
    currentTrick: round.currentTrick,
    readyForCommands: round.readyForCommands,
    trumpCard: round.trumpCard,
    trumpSuit: round.trumpSuit,
    roundNumber: round.roundNumber,
    roundName: round.roundName,
    numberOfCards: round.numberOfCards,
  }
}
