export const ErrorMessages = {
  ERROR_MUST_PROVIDE_EMAIL_AND_DEVICE_CODE_FOR_MAGIC_LINK:
    'must provide email and deviceCode to get magic link',
  ERROR_USER_FOR_EMAIL_ALREADY_EXISTS:
    'different user already exists with this email address',
  ERROR_USER_FOR_EMAIL_NOT_FOUND: 'user for email address not found',
  MAGIC_LINK_NOT_FOUND: 'could not find a magic link for that email address...',
  MAGIC_LINK_HASH_DID_NOT_MATCH: 'MagicLink hash did not match',
  ERROR_STORAGE_OPERATION: 'Unknown storage failure',
}
