import { FC } from 'react'
import { OhHellGame } from './shared'
import { OhHellCmdType } from '@ohell/games'
import PlayDeal from './PlayDeal'
import PlayBidButtons from './PlayBidButtons'
import PlayCard from './PlayCard'

const InProgress: FC<{ game: OhHellGame }> = (props) => {
  const game = props.game

  const readyForCommands: OhHellCmdType[] =
    game?.gameState?.currentRound?.readyForCommands || []

  return (
    <div className="bg-classicgray-dark p-2 pb-1 m-1 md:p-6 w-full md:w-10/12 rounded-md flex-col">
      {(readyForCommands.find((item) => item === OhHellCmdType.DealCards) && (
        <PlayDeal game={game}></PlayDeal>
      )) ||
        (readyForCommands.find((item) => item === OhHellCmdType.PlaceBid) && (
          <PlayBidButtons game={game}></PlayBidButtons>
        )) ||
        (readyForCommands.find((item) => item === OhHellCmdType.PlayCard) && (
          <PlayCard game={game}></PlayCard>
        ))}
    </div>
  )
}

export default InProgress
