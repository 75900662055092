import { GamePlayer } from '../../base/GamePlayer'
import { CardRef, DeckCode, getCard, shuffle } from '../../shared'
import { DeckOrder, Suit, suitEqual } from '../../shared/deck/Deck'
import { PlayerRound } from './playerState'
import { OhHellCmdType } from './state'
import { CompletedTrick, Hand, Trick } from './trick'

export interface TrumpSelection {
  trumpSuit: Suit | undefined
  trumpCard: CardRef | undefined
  deckCode: DeckCode
}

export interface Round extends TrumpSelection, RoundToPlay {
  players: GamePlayer[]
  dealerPlayerId: string
  turnPlayerId: string
  deck: DeckOrder
  bids: PlacedBid[]
  hands: Hand[]
  completedTricks: CompletedTrick[]
  currentTrick: Trick | undefined
  readyForCommands: OhHellCmdType[]
}

export interface RoundInProgress extends Round {
  currentTrick: Trick
}

export interface CompleteRound extends Round {
  currentTrick: undefined
  bids: PlayerScore[]
}

export interface RoundToPlay {
  roundNumber: number
  numberOfCards: number
  roundName: string | undefined
}

export interface Deal {
  playerId: string
}

export interface Bid {
  playerId: string
  bidTricks: number
}

export function getSumOfBids(bids: (Bid | PlacedBid)[]) {
  return bids.reduce((sum: number, bid: Bid | PlacedBid) => {
    return sum + bid.bidTricks
  }, 0)
}

export interface PlacedBid extends Bid {
  wonTricks: number
}

export interface PlayerScore extends PlacedBid {
  roundScore: number
  netScore: number
}

export function getPlayerScore(
  placedBid: PlacedBid,
  previousScore: number = 0,
): PlayerScore {
  const score = calculateScore(placedBid.bidTricks, placedBid.wonTricks)
  return {
    ...placedBid,
    roundScore: score,
    netScore: previousScore + score,
  }
}

export function calculateScore(bidTricks: number, wonTricks: number) {
  if (bidTricks !== wonTricks) {
    const offBy = Math.abs(bidTricks - wonTricks)
    return (-1 * offBy * (5 + 5 * offBy)) / 2
  }
  return 10 + bidTricks * bidTricks
}

export function isOhHellRound(round: any): round is Round {
  return (
    round &&
    round.deck &&
    Array.isArray(round.players) &&
    Array.isArray(round.hands) &&
    !round.currentRound
  )
}

export function initRound(
  deckCode: DeckCode,
  players: GamePlayer[],
  roundNumber: number,
  dealerPlayerId: string,
): Round {
  return {
    players,
    dealerPlayerId,
    turnPlayerId: dealerPlayerId,
    deckCode,
    deck: shuffle(deckCode),
    bids: [],
    hands: [],
    roundNumber,
    numberOfCards: roundNumber,
    completedTricks: [],
    roundName: undefined,
    currentTrick: undefined,
    trumpSuit: undefined,
    trumpCard: undefined,
    readyForCommands: [OhHellCmdType.DealCards],
  }
}

export function isReadyForCommand(round: Round, eventType: OhHellCmdType) {
  return round.readyForCommands && round.readyForCommands.includes(eventType)
}

export function isPlayersTurn(round: PlayerRound | Round, playerId: string) {
  return round.turnPlayerId === playerId
}

export function isTrumpBroken(round: Round | PlayerRound): boolean {
  for (let trick of [...round.completedTricks, round.currentTrick]) {
    for (let playedCard of trick?.cards || []) {
      if (suitEqual(round.trumpSuit, getCard(round, playedCard.card).suit)) {
        return true
      }
    }
  }
  return false
}
