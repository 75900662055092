import { User } from '../../domain/User'
import { AuthClient } from './IdentityTypes'

const localKeys = {
  user: 'identity#user',
  deviceCode: 'identity#deviceCode',
  auth: 'identity#auth',
}

export const readUser = (): User | null => {
  const storedUser = localStorage.getItem(localKeys.user)
  return storedUser ? JSON.parse(storedUser) : undefined
}
export const storeUser = (value: User | null): void => {
  localStorage.setItem(localKeys.user, JSON.stringify(value || null))
}

export const readAuthClient = (): AuthClient | null => {
  const storedAuth = localStorage.getItem(localKeys.auth)
  if (!storedAuth || storedAuth === 'null') {
    return null
  }
  const raw = JSON.parse(storedAuth || '') || {}
  return {
    ...raw,
    refreshTokenExpiryDate: new Date(raw.refreshTokenExpiryDate),
    jwtExpirationDate: new Date(raw.jwtExpirationDate),
  }
}
export const storeAuthClient = (value: AuthClient | null) => {
  if (!value) {
    localStorage.removeItem(localKeys.auth)
  }
  localStorage.setItem(localKeys.auth, JSON.stringify(value))
}

export const readDeviceCode = (): string | null => {
  const storedDeviceCode = localStorage.getItem(localKeys.deviceCode)
  return storedDeviceCode ? storedDeviceCode : null
}
export const storeDeviceCode = (value: string | null) => {
  localStorage.setItem(localKeys.deviceCode, value || '')
}
