import { getNextPlayerId, isActiveGamePlayer } from '../../base/GamePlayer'
import {
  Card,
  cardEqual,
  CardRef,
  CmdResultTypes,
  DeckCode,
  getCard,
  Suit,
  suitEqual,
} from '../../shared'
import { PlayerRound } from '../state/playerState'
import {
  isPlayersTurn,
  isReadyForCommand,
  isTrumpBroken,
  Round,
} from '../state/round'
import {
  cardPlayedEvent,
  closeRound,
  closeTrick,
  isInProgress,
  OhHellCmdType,
  OhHellComplete,
  OhHellFailure,
  OhHellInProgress,
  OhHellResult,
  OhHellRulesMessage,
  OhHellState,
  ohSucceed,
  roundCompleteEvent,
  trickCompleteEvent,
} from '../state/state'
import {
  PlayedCard,
  Hand,
  getPlayerHand,
  Trick,
  getLedSuit,
} from '../state/trick'

export function playCard(
  state: OhHellState,
  playedCard: PlayedCard,
): OhHellResult {
  if (!isInProgress(state)) {
    return {
      result: CmdResultTypes.Failure,
      message: OhHellRulesMessage.NotPlayPhase,
    }
  }
  const failure: false | OhHellFailure = checkForFailure(
    state.currentRound,
    playedCard,
  )
  if (failure) {
    return failure
  }

  const cardHasBeenPlayed: OhHellInProgress = getStateWithCardMovedToTrick(
    state,
    playedCard,
  )
  const cardEvent = cardPlayedEvent(cardHasBeenPlayed)
  if (!trickToBeCompleted(cardHasBeenPlayed)) {
    return ohSucceed(cardHasBeenPlayed, [cardEvent])
  }

  const trickHasBeenClosed: OhHellInProgress = closeTrick(cardHasBeenPlayed)
  const trickEvent = trickCompleteEvent(trickHasBeenClosed)
  if (!roundToBeCompleted(trickHasBeenClosed)) {
    return ohSucceed(trickHasBeenClosed, [cardEvent, trickEvent])
  }

  const roundHasBeenClosed: OhHellState = closeRound(trickHasBeenClosed)
  const roundEvent = roundCompleteEvent(roundHasBeenClosed)
  // add endGame event here if no current round
  return ohSucceed(roundHasBeenClosed, [cardEvent, trickEvent, roundEvent])
}

export function trickToBeCompleted(state: OhHellInProgress): boolean {
  // check that every active player has played a card
  const activePlayerIds = state.currentRound.players
    .filter((player) => isActiveGamePlayer(player))
    .map((player) => player.userId)
  if (
    !activePlayerIds ||
    !state.currentRound.currentTrick ||
    activePlayerIds.length > (state.currentRound.currentTrick.cards.length || 0)
  ) {
    return false
  }
  const havePlayed = new Set(
    state.currentRound.currentTrick.cards.map(
      (playedCard) => playedCard.playerId,
    ) || [],
  )

  return activePlayerIds.every((playerId) => havePlayed.has(playerId))
}

export function roundToBeCompleted(state: OhHellInProgress): boolean {
  const trickCount = state.currentRound.completedTricks.length
  const numCardsInRound = state.currentRound.numberOfCards
  return trickCount === numCardsInRound
}

function checkForFailure(
  round: Round,
  playedCard: PlayedCard,
): OhHellFailure | false {
  if (!isReadyForCommand(round, OhHellCmdType.PlayCard)) {
    return {
      result: CmdResultTypes.Failure,
      message: OhHellRulesMessage.NotPlayPhase,
    }
  }
  if (!isPlayersTurn(round, playedCard.playerId)) {
    return {
      result: CmdResultTypes.Failure,
      message: OhHellRulesMessage.NotPlayersTurn,
    }
  }
  const { isValid, message } = isValidPlay(round, playedCard)
  if (!isValid) {
    return {
      result: CmdResultTypes.Failure,
      message: message || OhHellRulesMessage.CardNotInHand,
    }
  }
  return false
}

function getStateWithCardMovedToTrick(
  state: OhHellInProgress,
  playedCard: PlayedCard,
): OhHellInProgress {
  const round: Round = Object.assign({}, state.currentRound)
  if (!round.currentTrick) {
    throw new Error('can not move card to non-existent trick')
  }
  const { handsPostPlay, trickPlusPlayedCard } = getPostPlayTrickAndHand(
    round.hands,
    round.currentTrick,
    playedCard,
  )
  const nextPlayerId = getNextPlayerId(playedCard.playerId, round.players)

  const currentRound: Round = {
    ...round,
    turnPlayerId: nextPlayerId,
    currentTrick: trickPlusPlayedCard,
    hands: handsPostPlay,
  }

  return {
    ...state,
    currentRound,
  }
}

export function choosePlayableCards(
  round: PlayerRound | Round,
  hand: Hand,
): CardRef[] {
  if (!isPlayersTurn(round, hand.playerId)) {
    throw new Error('can not choose playable cards if not player turn')
  }
  const trick = round.currentTrick
  if (!trick) {
    throw new Error('can not play cards if no trick')
  }
  if (!trick.cards.length) {
    return chooseLeadableCards(round, hand)
  }
  return choosePlayableCardsForTrickAndHand(round.deckCode, trick, hand)
}

export function choosePlayableCardsForTrickAndHand(
  deckCode: DeckCode,
  trick: Trick | undefined,
  hand: Hand,
): CardRef[] {
  if (!trick) {
    throw new Error('expected a trick here')
  }
  const ledCard: PlayedCard = trick.cards[0]
  const cardsMatchingSuit: CardRef[] = hand.cards.filter((card) =>
    suitEqual(
      getCard(deckCode, card).suit,
      getCard(deckCode, ledCard.card).suit,
    ),
  )
  return cardsMatchingSuit.length > 0 ? cardsMatchingSuit : hand.cards
}

function getPostPlayTrickAndHand(
  hands: Hand[],
  trick: Trick,
  playedCard: PlayedCard,
): { handsPostPlay: Hand[]; trickPlusPlayedCard: Trick } {
  const { playerId, card } = playedCard
  const playerHand: Hand = getPlayerHand(hands, playerId)
  const playerHandMinusPlayedCard = {
    ...playerHand,
    cards: playerHand.cards.filter((handCard) => card !== handCard),
  }
  if (playerHandMinusPlayedCard.cards.length !== playerHand.cards.length - 1) {
    // if someone has mutated cards, this will eventually happen.
    throw new Error('failure to move single card from hand to trick')
  }

  const handsPostPlay: Hand[] = hands.map((hand) =>
    hand.playerId === playerHand.playerId ? playerHandMinusPlayedCard : hand,
  )
  const trickPlusPlayedCard: Trick = {
    cards: [...(trick.cards || []), playedCard],
  }
  return { handsPostPlay, trickPlusPlayedCard }
}

export function chooseLeadableCards(
  round: Round | PlayerRound,
  hand: Hand,
): CardRef[] {
  const trumpCount = hand.cards.filter((card) =>
    suitEqual(getCard(round, card).suit, round.trumpSuit),
  ).length
  if (
    trumpCount === 0 ||
    trumpCount === hand.cards.length ||
    isTrumpBroken(round)
  ) {
    return hand.cards
  }
  return hand.cards.filter(
    (card) => !suitEqual(getCard(round, card).suit, round.trumpSuit),
  )
}

export type IsValidPlayResult = {
  isValid: boolean
  message?: OhHellRulesMessage
}

export function isValidPlay(
  round: Round,
  { card, playerId }: PlayedCard,
): IsValidPlayResult {
  const successResult = { isValid: true }
  if (!round.currentTrick) {
    return invalidPlay(OhHellRulesMessage.NotPlayPhase)
  }
  if (playerId != round.turnPlayerId) {
    return invalidPlay(OhHellRulesMessage.NotPlayersTurn)
  }
  const hand = getPlayerHand(round.hands, playerId)
  if (!hand || hand.cards.find((handCard) => handCard === card) === undefined) {
    return invalidPlay(OhHellRulesMessage.CardNotInHand)
  }
  const trump = round.trumpSuit
  if (round.currentTrick.cards.length === 0) {
    if (suitEqual(getCard(round, card).suit, trump)) {
      const anyNonTrumpInHand: boolean =
        hand.cards.filter(
          (card) => !suitEqual(getCard(round, card).suit, trump),
        ).length > 0
      if (!isTrumpBroken(round) && anyNonTrumpInHand) {
        return invalidPlay(OhHellRulesMessage.TrumpsNotBroken)
      }
    }
    return successResult
  }
  const ledSuit = getLedSuit(round.deckCode, round.currentTrick)
  const playedSuit = getCard(round, card).suit
  const playerHand = getPlayerHand(round.hands, playerId)
  if (
    ledSuit &&
    !suitEqual(playedSuit, ledSuit) &&
    !!playerHand.cards.find((card) =>
      suitEqual(getCard(round, card).suit, ledSuit),
    )
  ) {
    return invalidPlay(OhHellRulesMessage.MustFollowSuit)
  }
  return successResult
}

function invalidPlay(message: OhHellRulesMessage): IsValidPlayResult {
  return { isValid: false, message }
}
