import {
  choosePlayableCards,
  choosePlayableCardsForTrickAndHand,
} from '../commands/playCard'
import { OhHellPlayerState } from '../state/playerState'
import { Bid, getSumOfBids } from '../state/round'
import { PlayedCard } from '../state/trick'
import { ensureIsPlayersTurn, ExpertIds, ExpertPlayer } from './shared'

function getBid(state: OhHellPlayerState): Bid {
  ensureIsPlayersTurn(state)
  const playerId = state.playerId
  const cardsInRound = state.currentRound.numberOfCards
  const bidTricks = Math.floor(
    state.currentRound.numberOfCards / state.currentRound.players.length,
  )
  const plannedTotalBid = getSumOfBids(state.currentRound.bids) + bidTricks

  if (
    state.currentRound.dealerPlayerId === state.playerId &&
    plannedTotalBid === cardsInRound
  ) {
    return { playerId, bidTricks: bidTricks === 0 ? 1 : bidTricks - 1 }
  }
  return { playerId, bidTricks }
}

function getPlayerCard(state: OhHellPlayerState): PlayedCard {
  ensureIsPlayersTurn(state)
  const playerId = state.playerId
  const playerHand = state.currentRound.hand
  const chooseable = choosePlayableCards(state.currentRound, playerHand)
  if (chooseable.length === 0) {
    throw new Error('expected to find chooseable cards')
  }
  return { card: chooseable[0], playerId } // effectively random
}

export const bidAveragePlayRandom: ExpertPlayer = {
  getBid,
  getPlayerCard,
  computerPlayerId: ExpertIds.random,
}
