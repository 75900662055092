import { Card, CardRef, Deck, DeckOrder } from './Deck'
import { SmithWaite } from './SmithWaite'
import { AcesHigh } from './Standard'

export enum DeckCode {
  TarotSW = 'swtd',
  AcesHigh = 'stdah',
}

export const decks: Record<DeckCode, Deck> = {
  [DeckCode.TarotSW]: SmithWaite,
  [DeckCode.AcesHigh]: AcesHigh,
}

export type DeckCodeParam = DeckCode | { deckCode: DeckCode }

export const getDeckCode = (param: DeckCodeParam): DeckCode => {
  return (param as { deckCode: DeckCode })?.deckCode || (param as DeckCode)
}

export const getDeck = (param: DeckCodeParam): Deck => {
  const deckCode = getDeckCode(param)
  return decks[deckCode]
}

export const getCard = (param: DeckCodeParam, cardRef: CardRef): Card => {
  const deckCode = getDeckCode(param)
  const deck = getDeck(deckCode)
  if (!deck || cardRef < 0 || cardRef >= deck.cards.length) {
    throw new Error(`cardRef ${cardRef} not valid for deck ${deckCode}`)
  }
  return deck.cards[cardRef]
}

export const getCardRef = (
  deckParam: Deck | DeckCodeParam,
  cardValue: number,
  suitName: string,
): CardRef => {
  const deck: Deck = (deckParam as Deck).cards
    ? (deckParam as Deck)
    : getDeck(deckParam as DeckCodeParam)
  const cardRef = deck.cards.findIndex(
    (card) => card.cardValue === cardValue && card.suit.suitName === suitName,
  )
  if (cardRef === -1) {
    throw new Error(
      `getCardRef - could not find card suit ${suitName} value ${cardValue}`,
    )
  }
  return cardRef
}

export function shuffle(deckCode: DeckCode): DeckOrder {
  const deck = getDeck(deckCode)
  const indices = deck.cards.map((_card, idx) => idx)
  var randomNumbers = indices.map(Math.random)
  return indices.sort((a, b) => {
    return randomNumbers[a] - randomNumbers[b]
  })
}
