import { w3cwebsocket } from 'websocket'
import { Logger } from '../../shared/Logger'

export type OutboundMessage = {
  action: string
  message: object
  channel: string
  jwt: string
}
export type InboundMessage = {
  channel: string
  topic: string
  data: object
}

export const buildMessageBusClientId = (
  channel: string,
  target: string,
): string => channel + '$' + target

export type MessageBusChannel = {
  channel: string
  topic: string
}

export type MessageBusClient = {
  clientId: string
  callback?: (message: InboundMessage) => void
  channels: MessageBusChannel[]
}

export const messageBusChannelsInclude = (
  channels: MessageBusChannel[],
  channel: MessageBusChannel,
): boolean =>
  !!channels.find(
    (ch) => ch.channel === channel.channel && ch.topic === channel.topic,
  )

const getOutboundMessageString = (message: OutboundMessage) =>
  JSON.stringify({
    action: message.action,
    channel: message.channel,
    message: message.message,
    jwt: message.jwt,
    sent: Date.now(),
  })

export const socketUrl =
  'wss://17th2f1o5l.execute-api.us-east-1.amazonaws.com/dev'

const closingStatuses = [w3cwebsocket.CLOSED, w3cwebsocket.CLOSING]

export enum actions {
  sendChat = 'sendChat',
  sendAuthTicket = 'sendAuthTicket',
  sendHeartbeat = 'sendHeartbeat',
}
export enum defaultChannels {
  global = 'GLOBAL',
  heartbeat = 'HEARTBEAT',
  chat = 'CHAT',
  auth = 'AUTH',
  game = 'GAME',
}

export const sendMessage = (
  socket: w3cwebsocket,
  action: string,
  message: object,
  jwt: string,
  channel: string,
) => {
  if (!socket || closingStatuses.includes(socket?.readyState)) {
    throw new Error('Attempted to send message with closed socket')
  }
  const outString = getOutboundMessageString({
    action,
    channel,
    message,
    jwt,
  })
  Logger.debug(`mb sending string to socket ${outString}`)
  socket.send(outString)
}

export const sendAuthTicket = (socket: w3cwebsocket, jwt: string) => {
  const authTicket = {
    jwt,
  }
  Logger.debug(`sending authticket ${JSON.stringify(authTicket)}`)
  sendMessage(
    socket,
    actions.sendAuthTicket,
    authTicket,
    jwt,
    defaultChannels.auth,
  )
}

export const sendHeartbeat = (socket: w3cwebsocket, jwt: string) => {
  Logger.debug(`sending heartbeat`)
  const message = { heartbeatTimeStamp: Date.now() }
  sendMessage(
    socket,
    actions.sendHeartbeat,
    message,
    jwt,
    defaultChannels.heartbeat,
  )
}
